"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Bienvenue Bébé
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BirthregistryApi = exports.BirthregistryApiFactory = exports.BirthregistryApiFp = exports.BirthregistryApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * BirthregistryApi - axios parameter creator
 * @export
 */
exports.BirthregistryApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create new birth registry.
         * @summary Create Birth Registry
         * @param {BirthRegistryCreate} birthRegistryCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBirthRegistryApiV1BirthregistryPost: function (birthRegistryCreate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'birthRegistryCreate' is not null or undefined
                            common_1.assertParamExists('createBirthRegistryApiV1BirthregistryPost', 'birthRegistryCreate', birthRegistryCreate);
                            localVarPath = "/api/v1/birthregistry/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(birthRegistryCreate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Delete a birth registry.
         * @summary Delete Birth Registry
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete: function (birthRegistryId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'birthRegistryId' is not null or undefined
                            common_1.assertParamExists('deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete', 'birthRegistryId', birthRegistryId);
                            localVarPath = "/api/v1/birthregistry/{birth_registry_id}"
                                .replace("{" + "birth_registry_id" + "}", encodeURIComponent(String(birthRegistryId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve all birth registries only if user is superuser
         * @summary Get All Birth Registry
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBirthRegistryApiV1BirthregistryGet: function (skip, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/v1/birthregistry/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get small information about birth registry by ID.
         * @summary Get Birth Registry Address
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet: function (birthRegistryId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'birthRegistryId' is not null or undefined
                    common_1.assertParamExists('getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet', 'birthRegistryId', birthRegistryId);
                    localVarPath = "/api/v1/birthregistry/public/{birth_registry_id}"
                        .replace("{" + "birth_registry_id" + "}", encodeURIComponent(String(birthRegistryId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get birth registry by ID.
         * @summary Get Birth Registry
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryApiV1BirthregistryBirthRegistryIdGet: function (birthRegistryId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'birthRegistryId' is not null or undefined
                            common_1.assertParamExists('getBirthRegistryApiV1BirthregistryBirthRegistryIdGet', 'birthRegistryId', birthRegistryId);
                            localVarPath = "/api/v1/birthregistry/{birth_registry_id}"
                                .replace("{" + "birth_registry_id" + "}", encodeURIComponent(String(birthRegistryId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get a birth registry by shortlink.
         * @summary Get Birth Registry By Shortlink
         * @param {string} shortlink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet: function (shortlink, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'shortlink' is not null or undefined
                    common_1.assertParamExists('getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet', 'shortlink', shortlink);
                    localVarPath = "/api/v1/birthregistry/shortlink/{shortlink}"
                        .replace("{" + "shortlink" + "}", encodeURIComponent(String(shortlink)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Retrieve user\'s birth registries.
         * @summary Get User Birth Registries
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBirthRegistriesApiV1BirthregistryMineGet: function (skip, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/v1/birthregistry/mine";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Front page search
         * @summary Search Birth Registry Homepage
         * @param {string} query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet: function (query, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'query' is not null or undefined
                    common_1.assertParamExists('searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet', 'query', query);
                    localVarPath = "/api/v1/birthregistry/search/{query}"
                        .replace("{" + "query" + "}", encodeURIComponent(String(query)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Update a birth registry.
         * @summary Update Birth Registry
         * @param {number} birthRegistryId
         * @param {BirthRegistryUpdate} birthRegistryUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut: function (birthRegistryId, birthRegistryUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'birthRegistryId' is not null or undefined
                            common_1.assertParamExists('updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut', 'birthRegistryId', birthRegistryId);
                            // verify required parameter 'birthRegistryUpdate' is not null or undefined
                            common_1.assertParamExists('updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut', 'birthRegistryUpdate', birthRegistryUpdate);
                            localVarPath = "/api/v1/birthregistry/{birth_registry_id}"
                                .replace("{" + "birth_registry_id" + "}", encodeURIComponent(String(birthRegistryId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(birthRegistryUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * BirthregistryApi - functional programming interface
 * @export
 */
exports.BirthregistryApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.BirthregistryApiAxiosParamCreator(configuration);
    return {
        /**
         * Create new birth registry.
         * @summary Create Birth Registry
         * @param {BirthRegistryCreate} birthRegistryCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBirthRegistryApiV1BirthregistryPost: function (birthRegistryCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createBirthRegistryApiV1BirthregistryPost(birthRegistryCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete a birth registry.
         * @summary Delete Birth Registry
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete: function (birthRegistryId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete(birthRegistryId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Retrieve all birth registries only if user is superuser
         * @summary Get All Birth Registry
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBirthRegistryApiV1BirthregistryGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAllBirthRegistryApiV1BirthregistryGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get small information about birth registry by ID.
         * @summary Get Birth Registry Address
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet: function (birthRegistryId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet(birthRegistryId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get birth registry by ID.
         * @summary Get Birth Registry
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryApiV1BirthregistryBirthRegistryIdGet: function (birthRegistryId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBirthRegistryApiV1BirthregistryBirthRegistryIdGet(birthRegistryId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a birth registry by shortlink.
         * @summary Get Birth Registry By Shortlink
         * @param {string} shortlink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet: function (shortlink, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet(shortlink, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Retrieve user\'s birth registries.
         * @summary Get User Birth Registries
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBirthRegistriesApiV1BirthregistryMineGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserBirthRegistriesApiV1BirthregistryMineGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Front page search
         * @summary Search Birth Registry Homepage
         * @param {string} query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet: function (query, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet(query, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a birth registry.
         * @summary Update Birth Registry
         * @param {number} birthRegistryId
         * @param {BirthRegistryUpdate} birthRegistryUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut: function (birthRegistryId, birthRegistryUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut(birthRegistryId, birthRegistryUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * BirthregistryApi - factory interface
 * @export
 */
exports.BirthregistryApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.BirthregistryApiFp(configuration);
    return {
        /**
         * Create new birth registry.
         * @summary Create Birth Registry
         * @param {BirthRegistryCreate} birthRegistryCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBirthRegistryApiV1BirthregistryPost: function (birthRegistryCreate, options) {
            return localVarFp.createBirthRegistryApiV1BirthregistryPost(birthRegistryCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete a birth registry.
         * @summary Delete Birth Registry
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete: function (birthRegistryId, options) {
            return localVarFp.deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete(birthRegistryId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve all birth registries only if user is superuser
         * @summary Get All Birth Registry
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBirthRegistryApiV1BirthregistryGet: function (skip, limit, options) {
            return localVarFp.getAllBirthRegistryApiV1BirthregistryGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get small information about birth registry by ID.
         * @summary Get Birth Registry Address
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet: function (birthRegistryId, options) {
            return localVarFp.getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet(birthRegistryId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get birth registry by ID.
         * @summary Get Birth Registry
         * @param {number} birthRegistryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryApiV1BirthregistryBirthRegistryIdGet: function (birthRegistryId, options) {
            return localVarFp.getBirthRegistryApiV1BirthregistryBirthRegistryIdGet(birthRegistryId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a birth registry by shortlink.
         * @summary Get Birth Registry By Shortlink
         * @param {string} shortlink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet: function (shortlink, options) {
            return localVarFp.getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet(shortlink, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve user\'s birth registries.
         * @summary Get User Birth Registries
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBirthRegistriesApiV1BirthregistryMineGet: function (skip, limit, options) {
            return localVarFp.getUserBirthRegistriesApiV1BirthregistryMineGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Front page search
         * @summary Search Birth Registry Homepage
         * @param {string} query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet: function (query, options) {
            return localVarFp.searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet(query, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a birth registry.
         * @summary Update Birth Registry
         * @param {number} birthRegistryId
         * @param {BirthRegistryUpdate} birthRegistryUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut: function (birthRegistryId, birthRegistryUpdate, options) {
            return localVarFp.updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut(birthRegistryId, birthRegistryUpdate, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * BirthregistryApi - object-oriented interface
 * @export
 * @class BirthregistryApi
 * @extends {BaseAPI}
 */
var BirthregistryApi = /** @class */ (function (_super) {
    __extends(BirthregistryApi, _super);
    function BirthregistryApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new birth registry.
     * @summary Create Birth Registry
     * @param {BirthRegistryCreate} birthRegistryCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.createBirthRegistryApiV1BirthregistryPost = function (birthRegistryCreate, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).createBirthRegistryApiV1BirthregistryPost(birthRegistryCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete a birth registry.
     * @summary Delete Birth Registry
     * @param {number} birthRegistryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete = function (birthRegistryId, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete(birthRegistryId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve all birth registries only if user is superuser
     * @summary Get All Birth Registry
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.getAllBirthRegistryApiV1BirthregistryGet = function (skip, limit, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).getAllBirthRegistryApiV1BirthregistryGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get small information about birth registry by ID.
     * @summary Get Birth Registry Address
     * @param {number} birthRegistryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet = function (birthRegistryId, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet(birthRegistryId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get birth registry by ID.
     * @summary Get Birth Registry
     * @param {number} birthRegistryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.getBirthRegistryApiV1BirthregistryBirthRegistryIdGet = function (birthRegistryId, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).getBirthRegistryApiV1BirthregistryBirthRegistryIdGet(birthRegistryId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a birth registry by shortlink.
     * @summary Get Birth Registry By Shortlink
     * @param {string} shortlink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet = function (shortlink, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet(shortlink, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve user\'s birth registries.
     * @summary Get User Birth Registries
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.getUserBirthRegistriesApiV1BirthregistryMineGet = function (skip, limit, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).getUserBirthRegistriesApiV1BirthregistryMineGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Front page search
     * @summary Search Birth Registry Homepage
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet = function (query, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet(query, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a birth registry.
     * @summary Update Birth Registry
     * @param {number} birthRegistryId
     * @param {BirthRegistryUpdate} birthRegistryUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BirthregistryApi
     */
    BirthregistryApi.prototype.updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut = function (birthRegistryId, birthRegistryUpdate, options) {
        var _this = this;
        return exports.BirthregistryApiFp(this.configuration).updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut(birthRegistryId, birthRegistryUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return BirthregistryApi;
}(base_1.BaseAPI));
exports.BirthregistryApi = BirthregistryApi;
