/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout } from "components/shared";
import WishFormContainer from "./WishFormContainer";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = { subpage: "MAIN" };

class NewWish extends React.Component<Props, State> {
  state: State = { subpage: "MAIN" };

  /* LifeCycle Methods */

  /* Handlers methods */
  changePage = (subpage: State["subpage"]) => {
    this.setState((state: State) => ({ subpage: subpage }));
  };
  /* Render methods */
  render() {
    return (
      <BBBLayout
        mode="FULL"
        onlyBottom={true}
        titleSeo={`newWish.title.${this.state.subpage}`}
        loading={false}
      >
        <WishFormContainer changePage={this.changePage}></WishFormContainer>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NewWish);
