/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Spin } from "antd";
/****************** STYLING ******************/
import "./Loading.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps;
type MapStateToProps = { spinner: boolean; progress: number };
type MapDispatchToProps = {};
type State = {};

class Loading extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */

  /* Render methods */
  render() {
    if (this.props.spinner) {
      return (
        <Spin
          size="default"
          delay={50}
          className="progress"
          spinning={true}
          tip={`${_.round(this.props.progress, 1)}%`}
        ></Spin>
      );
    } else {
      return null;
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    spinner: state.config.loading.spinner,
    progress: state.config.loading.progress,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Loading);
