/****************** DEPENDENCIES (import) ******************/
import React, { ReactElement } from "react";
import * as log from "loglevel";
import { Redirect, Switch, Route, RouteProps } from "react-router-dom";

/****************** DEPENDENCIES : COMPONENTS ******************/

import { WhoModal } from "components/header";
import { LegalModal, ContactModal } from "components/footer";
import {
  Home,
  Register,
  Login,
  Forget,
  Activate,
  ResetPassword,
  Help,
  BirthRegistryDetail,
  NewWish,
  EditWish,
  WishDetail,
  BasketRecap,
  BasketConfirm,
} from "pages";
import { EditBirthRegistry, MyBirthRegistries, NewBirthRegistry } from "pages/user";
import { BirthRegistriesAdmin, BabiesAdmin } from "pages/admin";

/****************** TYPES ******************/
import { BBBRoutes } from "./routes";

export type GuardedRouteProps = {
  secured: boolean;
  isAuthenticated?: boolean;
} & RouteProps;

export function GuardedRoute({ isAuthenticated, secured, ...routeProps }: GuardedRouteProps) {
  log.trace(`Navigate to : ${routeProps.path}`, { isAuthenticated, secured });
  if (isAuthenticated || !secured) {
    return <Route {...routeProps} />;
  } else {
    log.info(`User not authenticated, redirect to ${BBBRoutes.HOME.path}`);
    return <Redirect to={BBBRoutes.HOME.path} />;
  }
}

export default function Router(authenticated: boolean): ReactElement {
  return (
    <Switch>
      <GuardedRoute {...BBBRoutes.HOME} exact={true}>
        <Home />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.WHO}>
        <WhoModal visible={true} />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.LEGAL}>
        <LegalModal visible={true} />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.CONTACT}>
        <ContactModal visible={true} />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.LOGIN}>
        <Login />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.FORGET}>
        <Forget />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.REGISTER}>
        <Register />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.ACTIVATE}>
        <Activate />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.RESET_PASSWORD}>
        <ResetPassword />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.HELP}>
        <Help />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.BASKET}>
        <BasketRecap />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.SUMMARY}>
        <BasketConfirm />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.ADMIN_BIRTHREGISTRIES} isAuthenticated={authenticated}>
        <BirthRegistriesAdmin />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.ADMIN_BABIES} isAuthenticated={authenticated}>
        <BabiesAdmin />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.BIRTHREGISTRY_NEW} isAuthenticated={authenticated}>
        <NewBirthRegistry />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.BIRTHREGISTRY_EDIT} isAuthenticated={authenticated}>
        <EditBirthRegistry />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.WISH_NEW} isAuthenticated={authenticated}>
        <NewWish />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.WISH_EDIT} isAuthenticated={authenticated}>
        <EditWish />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.WISH_DETAIL_BY_NANO_ID} isAuthenticated={authenticated}>
        <WishDetail />
      </GuardedRoute>
      <GuardedRoute {...BBBRoutes.WISH_DETAIL} isAuthenticated={authenticated}>
        <WishDetail />
      </GuardedRoute>

      <GuardedRoute
        {...BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID_FR}
        render={({ match }) => {
          return (
            <Redirect
              to={BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID.computePath({
                nano: match.params.id,
              })}
            />
          );
        }}
      />
      <GuardedRoute
        {...BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID_SHORT}
        render={({ match }) => {
          return (
            <Redirect to={BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID.computePath({
              nano: match.params.id,
            })} />
          );
        }}
      />
      <GuardedRoute {...BBBRoutes.BIRTHREGISTRY_DETAIL_BY_ID} isAuthenticated={authenticated}>
        <BirthRegistryDetail />
      </GuardedRoute>
      <GuardedRoute {...BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID} isAuthenticated={authenticated}>
        <BirthRegistryDetail />
      </GuardedRoute>

      <GuardedRoute {...BBBRoutes.USER_BIRTHREGISTRIES} isAuthenticated={authenticated}>
        <MyBirthRegistries />
      </GuardedRoute>

      <Route
        path="*"
        render={() => {
          return <Redirect to={BBBRoutes.HOME.path} />;
        }}
      />
    </Switch>
  );
}
