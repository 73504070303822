import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ConfigurationAction } from "store/actions";
import {
  CHANGE_MODE,
  CHANGE_THEME,
  ADD_THREAD,
  REMOVE_THREAD,
  UPDATE_THREAD,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CHANGE_LANG,
} from "store/constants";
import { ReactNode } from "react";

export type RunningMode = "DEV" | "PROD";
export type Theme = "dark" | "light";
export type BBBLang = "en" | "fr" | "de";
export type Thread = {
  key: string;
  progress: number;
};
export type Notification = {
  key?: string;
  type: "success" | "info" | "warning" | "error";
  duration?: number | null;
  title: string | ReactNode;
  description: string | ReactNode;
};
export interface StateConfiguration {
  mode: RunningMode;
  lang: BBBLang;
  theme: Theme;
  loading: {
    spinner: boolean;
    progress: number;
    threads: Thread[];
  };
  notifications: Notification[];
  features: {
    langSelectorFeature: boolean;
    cashPoolFeature: boolean;
    uploadImageFeature: boolean;
    imageCatalogFeature: boolean;
    secondOwnerFeature: boolean;
    multiBabyFeature: boolean;
    scanWishUrlFeature: boolean;
    thanksFeature: boolean;
  };
}

export const initialStateConfiguration: StateConfiguration = {
  mode: "DEV",
  lang: "fr",
  theme: "light",
  loading: {
    spinner: false,
    progress: 100,
    threads: [],
  },
  notifications: [],
  features: {
    // Working in progress
    langSelectorFeature: false,
    cashPoolFeature: false,
    secondOwnerFeature: false,
    multiBabyFeature: false,
    // Current feature
    imageCatalogFeature: true, // needs uploadImageFeature = true
    // Released
    uploadImageFeature: true,
    scanWishUrlFeature: true,
    thanksFeature: true,
  },
};

export function configurationReducer(
  state: StateConfiguration = initialStateConfiguration,
  action: ConfigurationAction
): StateConfiguration {
  switch (action.type) {
    case CHANGE_MODE:
      return { ...state, mode: action.payload };
    case CHANGE_LANG:
      return { ...state, lang: action.payload };
    case CHANGE_THEME:
      return { ...state, theme: action.payload };
    case ADD_THREAD: {
      let activeThreads = [...state.loading.threads, { key: action.payload, progress: 0 }];
      return {
        ...state,
        loading: {
          spinner: true,
          progress: _.meanBy(activeThreads, "progress"),
          threads: activeThreads,
        },
      };
    }
    case REMOVE_THREAD:
    case UPDATE_THREAD: {
      const threadKey: string =
        typeof action.payload === "string" ? action.payload : action.payload.threadKey;
      const progress: number = typeof action.payload === "string" ? 100 : action.payload.progress;
      let activeThreads = _.map(state.loading.threads, (thread) => {
        if (thread.key === threadKey) {
          return {
            ...thread,
            progress: progress,
          };
        } else {
          return thread;
        }
      });
      let finishedThreads = _.filter(
        activeThreads,
        (activeThread) => activeThread.progress !== 100
      );
      if (_.isEmpty(finishedThreads)) {
        return { ...state, loading: initialStateConfiguration.loading };
      } else {
        return {
          ...state,
          loading: {
            spinner: true,
            progress:
              _.meanBy(activeThreads, "progress") || initialStateConfiguration.loading.progress,
            threads: activeThreads,
          },
        };
      }
    }
    case ADD_NOTIFICATION:
      const notif: Notification = {
        key: action.payload.key ? action.payload.key : uuidv4(),
        type: action.payload.type ? action.payload.type : "info",
        duration: action.payload.duration,
        title: action.payload.title,
        description: action.payload.description,
      };
      return { ...state, notifications: [...state.notifications, notif] };
    case REMOVE_NOTIFICATION:
      const filteredNotifications = _.filter(
        state.notifications,
        (notif) => notif.key !== action.payload
      );
      return { ...state, notifications: filteredNotifications };
    default:
      return state;
  }
}
