import * as constants from "store/constants";
import { Wish, Category } from "bbb-api/dist/models";
import { SearchFields } from "store/reducers/categories";
import { LoginType } from "./user";

export type CategoryAction =
  | LoginType
  | AddWishType
  | UpdateWishType
  | DeleteWishType
  | SetCategoryListType
  | AddCategoryType
  | RemoveCategoryType
  | SetSearchFieldsType;

export interface AddWishType {
  type: constants.WISH_ADD;
  payload: Wish;
}

export function addWishElement(wish: Wish): AddWishType {
  return {
    type: constants.WISH_ADD,
    payload: wish,
  };
}

export interface UpdateWishType {
  type: constants.WISH_UPDATE;
  payload: Wish;
}

export function updateWishElement(wish: Wish): UpdateWishType {
  return {
    type: constants.WISH_UPDATE,
    payload: wish,
  };
}

export interface DeleteWishType {
  type: constants.WISH_DELETE;
  payload: Wish;
}

export function deleteWishElement(wish: Wish): DeleteWishType {
  return {
    type: constants.WISH_DELETE,
    payload: wish,
  };
}

export interface SetCategoryListType {
  type: constants.CATEGORY_SET;
  payload: Category[];
}

export function setCategoryList(categories: Category[]): SetCategoryListType {
  return {
    type: constants.CATEGORY_SET,
    payload: categories,
  };
}

export interface AddCategoryType {
  type: constants.CATEGORY_ADD;
  payload: Category;
}

export function addCategory(category: Category): AddCategoryType {
  return {
    type: constants.CATEGORY_ADD,
    payload: category,
  };
}

export interface RemoveCategoryType {
  type: constants.CATEGORY_DELETE;
  payload: Category;
}

export function removeCategory(category: Category): RemoveCategoryType {
  return {
    type: constants.CATEGORY_DELETE,
    payload: category,
  };
}

export interface SetSearchFieldsType {
  type: constants.SEARCHFIELDS_SET;
  payload: SearchFields;
}

export function setSearchFields(searchField: SearchFields): SetSearchFieldsType {
  return {
    type: constants.SEARCHFIELDS_SET,
    payload: searchField,
  };
}
