// Example code, all types here !
export enum DebugLevel {
  DEBUG,
  INFO,
}

export type BbbError = {
  code?: string;
  detail: string;
};
