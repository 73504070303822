/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import pin from "../../../../assets/pin.svg";

/****************** STYLING ******************/
import "./BirthRegistryDetailHeaderQuote.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";
import { Card, Row, Typography } from "antd";

/****************** RENDERING (export) ******************/
type InputProps = {
  loading: boolean;
  birthRegistry?: BirthRegistry;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class BirthRegistryDetailHeaderQuote extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */
  renderContentOrInvitation() {
    log.trace(this.props.birthRegistry?.description);
    if (this.props.birthRegistry?.description) {
      return (
        <Row justify="center">
          <Typography.Paragraph>"{this.props.birthRegistry?.description}"</Typography.Paragraph>
        </Row>
      );
    } else {
      return (
        <Row justify="center">
          <FormattedMessage id="birthRegistryDetailHeaderQuote.advice"></FormattedMessage>
        </Row>
      );
    }
  }
  render() {
    return (
      <Card className="quote" loading={this.props.loading}>
        <Row justify="center" align="top">
          <img src={pin} className="pin" alt="pin" />
        </Row>
        <Row justify="center">{this.renderContentOrInvitation()}</Row>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryDetailHeaderQuote);
