/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

import { REACT_APP_TEST_LOGIN, REACT_APP_TEST_PASS } from "../../config/constants";
import { loginApiFactory } from "config/bbbApiFactory";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Form, Input, Row, Col } from "antd";
import { BbbButton, BBBLayout } from "components/shared";

/****************** TYPES ******************/
import { Store, RunningMode, Notification } from "store/reducers";
import { addNotification, addThread, authenticate, removeThread } from "store/actions";
/****************** STYLING ******************/
import "./Login.less";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = { mode: RunningMode };
type MapDispatchToProps = {
  authenticate: (auth: boolean) => void;
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = { loading: boolean; mail: string; password: string; jwt: string; valid: boolean };

class Login extends React.Component<Props, State> {
  state: State = { loading: false, mail: "", password: "", jwt: "", valid: false };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.mode === "DEV") {
      this.setState((state: State) => ({
        ...state,
        mail: REACT_APP_TEST_LOGIN,
        password: REACT_APP_TEST_PASS,
        valid: REACT_APP_TEST_LOGIN !== "",
      }));
    }
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `login.${id}` }, param);
  };

  handleChange = (field: string, event: any) => {
    log.info("handleChange", { field, event });
    const value = event.target.value;
    this.setState((state: State) => {
      const newState = { ...state, [field]: value };
      return { ...newState, valid: !_.isEmpty(newState.mail) && !_.isEmpty(newState.password) };
    });
  };

  login = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    log.info("Start login");
    event.preventDefault();
    this.setState((state: State) => ({ ...state, loading: true }));
    const loginApi = loginApiFactory();
    this.props.addThread("loginAccessTokenApiV1LoginAccessTokenPost");
    loginApi
      .loginAccessTokenApiV1LoginAccessTokenPost(this.state.mail, this.state.password)
      .then((response) => {
        log.info(`Successfully logged in with user ${this.state.mail}`);
        const jwt = response.data.access_token;
        log.info(jwt);
        this.setState((state: State) => ({
          ...state,
          jwt: jwt,
        }));
        localStorage.setItem("bbb", jwt);
        this.props.authenticate(true);
        this.props.history.push(BBBRoutes.USER_BIRTHREGISTRIES.path);
      })
      .catch((error) => {
        log.error(`Error logging with user ${this.state.mail}`, error);
        this.props.addNotification({
          type: "error",
          description: "Email inconnu ou mot de passe incorrect",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("loginAccessTokenApiV1LoginAccessTokenPost");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  };

  /* Render methods */
  render() {
    return (
      <BBBLayout mode="CENTER" titleSeo="login.title" loading={false}>
        <Form layout="vertical" labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} name="basic">
          <div className="page">
            <Form.Item label={this.getTraduction("mail")} required={true}>
              <Input
                disabled={this.state.loading}
                value={this.state.mail}
                onChange={(event) => this.handleChange("mail", event)}
              />
            </Form.Item>
            <Form.Item label={this.getTraduction("password")} required={true}>
              <Input.Password
                disabled={this.state.loading}
                value={this.state.password}
                onChange={(e) => this.handleChange("password", e)}
              />
            </Form.Item>
            <Row justify="space-around">
              <Col>
                <BbbButton
                  size="large"
                  type="primary"
                  disabled={!this.state.valid}
                  htmlType="submit"
                  loading={this.state.loading}
                  onClick={(e) => this.login(e)}
                >
                  <FormattedMessage id="login.submit" />
                </BbbButton>
              </Col>
              <Col>
                <BbbButton
                  size="large"
                  type="text"
                  disabled={this.state.loading}
                  onClick={() => this.props.history.push(BBBRoutes.REGISTER.path)}
                >
                  <FormattedMessage id="login.register" />
                </BbbButton>
              </Col>
              <Col>
                <BbbButton
                  size="large"
                  type="text"
                  disabled={this.state.loading}
                  onClick={() => this.props.history.push(BBBRoutes.FORGET.path)}
                >
                  <FormattedMessage id="login.forget" />
                </BbbButton>
              </Col>
            </Row>
          </div>
        </Form>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    mode: state.config.mode,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    authenticate: (auth: boolean) => dispatch(authenticate(auth)),
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
