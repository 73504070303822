import { Ability, AbilityClass } from "@casl/ability";
import { BirthRegistry, User, Wish, Category } from "bbb-api/dist/models";
import { createContext } from "react";

type Actions =
  | "manage" // keyWord action for everything
  | "create"
  | "update"
  | "delete"
  | "publish"
  | "read"
  | "add"
  | "remove";

type Subjects =
  | "User"
  | User
  | "BirthRegistry"
  | BirthRegistry
  | "Category"
  | Category
  | "Wish"
  | Wish
  | "all"; // keyWord subject for everything

export type AppAbility = Ability<[Actions, Subjects]>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function createAbility() {
  const ability = new AppAbility();
  ability.can = ability.can.bind(ability);
  ability.cannot = ability.cannot.bind(ability);
  return ability;
}

export const AbilityContext = createContext<AppAbility>(undefined!);
