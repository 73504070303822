/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { BbbButton, BBBLayout, HelpArticleStep } from "components/shared";
import { Card, Col, Row, Typography } from "antd";
import HelpUnknown from "./HelpUnknown";
/****************** TYPES ******************/
import { Store } from "store/reducers";
import * as _ from "lodash";
import { healthcheckApiFactory } from "config";
import { addThread, removeThread } from "store/actions";
/****************** STYLING ******************/
import "./Help.less";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
};
export type VisitorType = "PARENT" | "VISITOR";
type State = {
  loading: boolean;
  visitorType?: VisitorType;
  currentArticle?: number;
  s3BaseUrl?: string;
};

type HelpArticle = {
  title: string;
  stepCount: number;
  steps: { stepNumber: number; textContent: string; imgPath: string }[];
};

class Help extends React.Component<Props, State> {
  state: State = {
    loading: true,
    visitorType: undefined,
    currentArticle: undefined,
    s3BaseUrl: undefined,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    const healthcheckApi = healthcheckApiFactory();
    this.props.addThread("getS3BaseUrlS3UrlGet");
    healthcheckApi
      .getS3BaseUrlS3UrlGet()
      .then((response) => {
        let s3BaseUrl = response.data;
        this.setState((state: State) => ({
          ...state,
          s3BaseUrl: s3BaseUrl,
        }));
        log.info(`Successfully fetch /s3BaseUrl `, s3BaseUrl);
      })
      .catch((error) => {
        log.error(`Error fetching /s3BaseUrl `, error);
      })
      .finally(() => {
        this.props.removeThread("getS3BaseUrlS3UrlGet");
        this.setState((state) => ({ ...state, loading: false }));
      });
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `help.${id}` }, param);
  };

  onChangeVisitorType = (visitorType: VisitorType): void => {
    this.setState((state: State) => ({ ...state, visitorType: visitorType }));
  };

  resetChoices = (): void => {
    this.setState((state: State) => ({
      ...state,
      currentArticle: undefined,
      visitorType: undefined,
    }));
  };

  setCurrentArticle = (index: number): void => {
    this.setState((state: State) => ({ ...state, currentArticle: index }));
  };

  getArticlePart = (
    visitorType: VisitorType,
    articleNumber: number,
    part: "question" | "nbSteps" | "steps",
    stepNumber?: number,
    stepContent?: "text" | "img"
  ): string => {
    const cleanedList = _.filter(
      [
        "faq",
        visitorType.toLowerCase(),
        articleNumber.toString(),
        part ? part : undefined,
        stepNumber !== undefined ? stepNumber.toString() : undefined,
        stepContent ? stepContent : undefined,
      ],
      (elem) => elem !== undefined
    );
    const cleanedKey = _.join(cleanedList, ".");
    return this.getTraduction(cleanedKey);
  };

  getTitleArticle = (visitorType: VisitorType, articleNumber: number): string => {
    return this.getArticlePart(visitorType, articleNumber, "question");
  };

  getHelpArticle(visitorType: VisitorType, articleNumber: number): HelpArticle {
    let articleTitle = this.getTitleArticle(visitorType, articleNumber);
    let articleCountStep = parseInt(this.getArticlePart(visitorType, articleNumber, "nbSteps"));

    let steps = [];
    for (let i = 1; i <= articleCountStep; i++) {
      let textContent = this.getArticlePart(visitorType, articleNumber, "steps", i, "text");
      let img = this.getArticlePart(visitorType, articleNumber, "steps", i, "img");

      let step = {
        stepNumber: i,
        textContent: textContent,
        imgPath: img,
      };
      steps.push(step);
    }

    const helpArticle: HelpArticle = {
      title: articleTitle,
      stepCount: articleCountStep,
      steps: steps,
    };
    return helpArticle;
  }

  /* Render methods */
  renderParentOrVisitor(visitorType: VisitorType) {
    if (this.state.currentArticle) {
      return this.renderArticleContent(visitorType, this.state.currentArticle);
    } else {
      return this.renderArticleList(visitorType);
    }
  }

  renderArticleList(visitorType: VisitorType) {
    let nbQuestionVisitor = this.getTraduction("faq.nbQuestionVisitor");
    let nbQuestionParent = this.getTraduction("faq.nbQuestionParent");

    let totalQuestions = {
      VISITOR: parseInt(nbQuestionVisitor),
      PARENT: parseInt(nbQuestionParent),
    };
    let emptyArray = Array(totalQuestions[visitorType]);
    let sizeArray = _.size(emptyArray);
    return (
      <>
        <Row gutter={[50, 50]} justify="center" className="mb10">
          <BbbButton type="default" size="large" onClick={() => this.resetChoices()}>
            <FormattedMessage id="help.back"></FormattedMessage>
          </BbbButton>
        </Row>
        <Row gutter={[50, 50]} wrap={true} style={{ marginBottom: "40px" }}>
          {_.map(emptyArray, (emptyElt, index) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} key={index}>
              <Card
                className="helpArticleCard"
                title={this.getTitleArticle(visitorType, index + 1)}
              >
                <div
                  className="body"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setCurrentArticle(index + 1)}
                >
                  <Typography.Paragraph ellipsis={{ rows: 4 }}>
                    {this.getArticlePart(visitorType, index + 1, "steps", 1, "text")}
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        {sizeArray > 5 && (
          <Row justify="center">
            <BbbButton type="default" size="large" onClick={() => this.resetChoices()}>
              <FormattedMessage id="help.back"></FormattedMessage>
            </BbbButton>
          </Row>
        )}
      </>
    );
  }

  renderArticleContent(visitorType: VisitorType, currentArticle: number) {
    const helpArticle: HelpArticle = this.getHelpArticle(visitorType, currentArticle);
    const stepCount = _.size(helpArticle.steps);
    return (
      <>
        <Row>
         <Typography.Title level={3}>{this.getTitleArticle(visitorType, currentArticle)}</Typography.Title>
        </Row>
        <Row >
          <BbbButton type="default" size="large" onClick={(ee) => this.resetChoices()}>
            <FormattedMessage id="help.back"></FormattedMessage>
          </BbbButton>
        </Row>
        {_.map(helpArticle.steps, (step, index) => (
          <HelpArticleStep
            stepNumber={step.stepNumber}
            textContent={step.textContent}
            s3BaseUrl={this.state.s3BaseUrl}
            imgPath={step.imgPath}
          ></HelpArticleStep>
        ))}
        {stepCount > 5 && (<Row justify="center">
          <BbbButton type="default" size="large" onClick={(ee) => this.resetChoices()}>
            <FormattedMessage id="help.back"></FormattedMessage>
          </BbbButton>
        </Row>)} 
      </>
    );
  }

  render() {
    return (
      <BBBLayout mode="FULL" titleSeo="help.title" loading={this.state.loading}>
        {this.state.visitorType ? (
          this.renderParentOrVisitor(this.state.visitorType)
        ) : (
          <HelpUnknown setVisitorType={this.onChangeVisitorType}></HelpUnknown>
        )}
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Help);
