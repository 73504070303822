/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Spin, Typography } from "antd";
/****************** TYPES ******************/
import { Store } from "store/reducers";
/****************** STYLING ******************/
import "./PageTitle.less";

/****************** RENDERING (export) ******************/
type InputProps = { title: string | JSX.Element; loading?: boolean; className?: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class PageTitle extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods *

  /* Render methods */
  renderTitle() {
    if (typeof this.props.title === "string") {
      return (
        <span className={"title"}>
          <FormattedMessage id={this.props.title} />
        </span>
      );
    } else {
      return <span className={"title"}>{this.props.title}</span>;
    }
  }
  renderSpinnerWithMessage() {
    if (this.props.loading) {
      return (
        <Spin size="small" delay={300} className="progress" spinning={true}>
          {this.renderTitle()}
        </Spin>
      );
    } else {
      return this.renderTitle();
    }
  }

  render() {
    return (
      <Typography.Title className={`pageTitle ${this.props.className}`}>
        {this.renderSpinnerWithMessage()}
      </Typography.Title>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(PageTitle);
