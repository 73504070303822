/****************** DEPENDENCIES (import) ******************/

import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { compose } from "recompose";
import React from "react";
import { connect } from "react-redux";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Row, Typography } from "antd";
import { BbbButton, BBBLayout } from "components/shared";
import { SearchList, NewsList, PunchLines } from ".";

/****************** STYLING ******************/
import "./Home.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps &
  RouteComponentProps & {
    intl: IntlShape;
  };
type MapStateToProps = {
  authenticated: boolean;
};
type MapDispatchToProps = {};
type State = {};

class Home extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */

  /* Render methods */
  renderHomeButton() {
    if (this.props.authenticated) {
      return (
        <BbbButton
          type="primary"
          size="large"
          onClick={() => this.props.history.push(BBBRoutes.USER_BIRTHREGISTRIES.path)}
        >
          <FormattedMessage id="home.main.myBirthRegistries" />
        </BbbButton>
      );
    } else {
      return (
        <BbbButton
          type="primary"
          size="large"
          onClick={() => this.props.history.push(BBBRoutes.REGISTER.path)}
        >
          <FormattedMessage id="home.main.create"></FormattedMessage>
        </BbbButton>
      );
    }
  }

  render() {
    return (
      <BBBLayout mode="2COL" titleSeo="home.main.htmlTitle" loading={false}>
        <Card className="cardContent">
          <Typography.Title className="title" level={2}>
            <FormattedMessage id="home.main.title"></FormattedMessage>
          </Typography.Title>
          <div className="subTitle">
            <Typography.Paragraph>
              <Typography.Text type="secondary">
                <FormattedMessage id="home.main.content"></FormattedMessage>
                <Typography.Text type="secondary" strong>
                  <FormattedMessage id="home.main.branding"></FormattedMessage>
                </Typography.Text>
                {"."}
              </Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text type="secondary">
                <FormattedMessage id="home.main.content2"></FormattedMessage>
              </Typography.Text>
              <Typography.Text type="secondary" strong>
                <FormattedMessage id="home.main.branding"></FormattedMessage>
                {" !"}
              </Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text type="secondary">
                <FormattedMessage id="home.main.content3"></FormattedMessage>
              </Typography.Text>
            </Typography.Paragraph>
          </div>

          <Row className="actionsCard" justify="center">
            {this.renderHomeButton()}
          </Row>
        </Card>
        <SearchList></SearchList>
        <React.Fragment>
          <Row justify="center">
            <PunchLines></PunchLines>
          </Row>
          <Row justify="center" className="mt10">
            <NewsList></NewsList>
          </Row>
        </React.Fragment>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    authenticated: state.user.authenticated,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
