/****************** DEPENDENCIES (import) ******************/

import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import { compose } from "recompose";
import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Avatar, Card, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
/****************** STYLING ******************/

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { RssArticle } from "./News";

/****************** RENDERING (export) ******************/
type InputProps = { article: RssArticle };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps &
  RouteComponentProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = { loading: boolean };

class NewsItem extends React.Component<Props, State> {
  state: State = { loading: false };

  /* LifeCycle Methods */
  componentDidMount() {}
  /* Handlers methods */
  goToNews(article: RssArticle) {
    window.open(article.link, "_blank");
  }

  stringToColor = (value: string) => {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 40%, 80%)`;
  };

  /* Render methods */
  render() {
    const { article } = this.props;
    const { loading } = this.state;
    return (
      <Card
        loading={loading}
        className="news"
        cover={<img src={article.featured_image} className="logo" alt="logo" />}
        onClick={() => {
          this.goToNews(article);
        }}
      >
        <Space size="large" direction="vertical" className="full-width">
          <Row align="middle" gutter={10}>
            <Col>
              <Tooltip title={article.creator}>
                <Avatar size="large" style={{ color: "black", backgroundColor: "#82c1ca" }}>
                  {_.upperCase(_.first(article.creator))}
                </Avatar>
              </Tooltip>
            </Col>
            <Col span={18}>
              <Tooltip title={article.title}>
                <div className="ant-card-meta-title">{article.title}</div>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {article.contentSnippet}
              </Typography.Paragraph>
            </Col>
          </Row>
          <Row gutter={[3, 10]}>
            {article.categories.map((cat, index) => (
              <Col key={index}>
                <Tag color={this.stringToColor(cat)}>{_.capitalize(cat)}</Tag>
              </Col>
            ))}
          </Row>
        </Space>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(NewsItem);
