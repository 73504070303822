/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import classnames from "classnames";

/****************** DEPENDENCIES : COMPONENTS ******************/

/****************** STYLING ******************/
import "./BbbButton.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { Button, ButtonProps } from "antd";

/****************** RENDERING (export) ******************/
type InputProps = ButtonProps & {
  classNames?: string;
};

type Props = InputProps & MapStateToProps & MapDispatchToProps;

type MapStateToProps = {};
type MapDispatchToProps = {};

type State = {};

class BbbButton extends React.Component<Props, State> {
  state: State = {};
  /* LifeCycle Methods */

  /* Handlers methods */

  /* Render methods */
  render() {
    const { classNames, children, ...rest } = this.props;
    const computedClass = classnames(classNames);
    return (
      <Button className={computedClass} {...rest}>
        {children}
      </Button>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(connect(mapStateToProps, mapDispatchToProps))(BbbButton);
