/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Row, Col, Typography, Spin } from "antd";
/****************** TYPES ******************/
import { Store, Notification } from "store/reducers";
import { BbbError, userApiFactory } from "config";
import { User } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
/****************** STYLING ******************/
import "./Activate.less";
import { Link } from "react-router-dom";
import { BbbButton, BBBLayout } from "components/shared";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/

type InputProps = {};
type RouteParams = { userid: string; token: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type State = {
  loading: boolean;
  userid?: string;
  token?: string;
  success?: boolean;
};

class Activate extends React.Component<Props, State> {
  state: State = {
    loading: true,
    userid: undefined,
    token: undefined,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    const { match } = this.props;
    if (match.params.userid && match.params.token && _.isInteger(Number(match.params.userid))) {
      const userid = Number(match.params.userid);
      const token = match.params.token;
      const userApi = userApiFactory();
      this.props.addThread("activateNewUserApiV1UserUserIdActivateTokenGet");
      userApi
        .activateNewUserApiV1UserUserIdActivateTokenGet(userid, token)
        .then((response) => {
          const user: User = response.data;
          log.info(`Successfully fetch /activate/${userid}`, user);
          this.setState((state: State) => ({
            ...state,
            success: true,
          }));
        })
        .catch((error) => {
          log.error(`Error fetching /activate/${userid}`, error);
          const formatedError = error.response.data as BbbError;
          const descriptionError = formatedError.code
            ? this.getTraduction(`error.${formatedError.code}`)
            : formatedError.detail;
          this.props.addNotification({
            type: "error",
            description: descriptionError,
            title: "Erreur",
          });
          this.setState((state: State) => ({
            ...state,
            success: false,
          }));
        })
        .finally(() => {
          this.props.removeThread("activateNewUserApiV1UserUserIdActivateTokenGet");
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  }
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `activate.${id}` }, param);
  };

  /* Render methods */
  spinnerOrMessage() {
    if (this.state.loading) {
      return <Spin size="large" delay={300} className="progress" spinning={true}></Spin>;
    } else if (this.state.success) {
      return (
        <React.Fragment>
          <Row justify="center" gutter={[30, 30]} className="activate">
            <Col span={23}>
              <Typography.Text>
                <FormattedMessage id="activate.success" />
              </Typography.Text>
            </Col>
            <Col span={23}>
              <BbbButton type="primary">
                <Link to={BBBRoutes.LOGIN.path}>
                  <FormattedMessage id="activate.login" />
                </Link>
              </BbbButton>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <Row justify="center" className="activate">
          <Typography.Text>
            <FormattedMessage id="activate.error.title" />
          </Typography.Text>
        </Row>
      );
    }
  }
  render() {
    return (
      <BBBLayout mode="CENTER" titleSeo="activate.title" loading={this.state.loading}>
        {this.spinnerOrMessage()}
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Activate);
