/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Form, Input, FormInstance, Typography } from "antd";
import { BBBLayout, FormCarousel } from "components/shared";
/****************** TYPES ******************/
import { Store, Notification } from "store/reducers";
import { Store as FormStore, ValidateErrorEntity, FieldData } from "rc-field-form/lib/interface";
import { BbbError, userApiFactory } from "config";
import { UserCreate } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
/****************** STYLING ******************/
import "./Register.less";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type RegisterFields = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

type State = {
  formValues: RegisterFields;
  formIsValid: boolean;
  formRef: React.RefObject<FormInstance>;
};

class Register extends React.Component<Props, State> {
  state: State = {
    formValues: { first_name: "", last_name: "", email: "", password: "", confirmPassword: "" },
    formIsValid: false,
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `register.${id}` }, param);
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as RegisterFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange", changedFields);
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  onFinish = (values: FormStore) => {
    log.info("Submit Register", values);
    if (this.state.formValues) {
      log.info(this.state.formValues);
      const formValues: RegisterFields = this.state.formValues;
      const userCreate: UserCreate = {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
        password: formValues.password,
      };
      const userApi = userApiFactory();
      this.props.addThread("createUserOpenApiV1UserOpenPost");
      userApi
        .createUserOpenApiV1UserOpenPost(userCreate)
        .then((response) => {
          const user = response.data;
          log.info(`Successfully call register`, user);
          const descriptionSuccess = this.getTraduction(`success`);
          this.props.addNotification({
            type: "success",
            description: descriptionSuccess,
            duration: null,
            title: "Succès",
          });
          this.props.history.push(BBBRoutes.HOME.path);
        })
        .catch((error) => {
          log.error(`Error calling register`, error);
          const formatedError = error.response.data as BbbError;
          const descriptionError = formatedError.code
            ? this.getTraduction(`error.${formatedError.code}`)
            : formatedError.detail;
          this.props.addNotification({
            type: "error",
            description: descriptionError,
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("createUserOpenApiV1UserOpenPost");
        });
    }
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  /* Render methods */
  render() {
    return (
      <BBBLayout mode="CENTER" titleSeo="register.title" loading={false}>
        <Form
          layout="vertical"
          labelCol={{ md: 15, lg: 15 }}
          wrapperCol={{ span: 24 }}
          ref={this.state.formRef}
          onValuesChange={this.valuesChange}
          onFieldsChange={this.onFieldsChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <FormCarousel
            validLabel={<FormattedMessage id="register.register" />}
            valid={() => this.submit()}
            cancelLabel={<FormattedMessage id="register.login" />}
            cancel={() => this.props.history.push(BBBRoutes.LOGIN.path)}
            formIsValid={this.state.formIsValid}
          >
            <React.Fragment>
              <Typography.Text>{this.getTraduction("description")}</Typography.Text>
              <Form.Item
                name="first_name"
                label={this.getTraduction("first_name")}
                rules={[{ required: true, message: this.getTraduction("mandatory.first_name") }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="last_name"
                label={this.getTraduction("last_name")}
                rules={[{ required: true, message: this.getTraduction("mandatory.last_name") }]}
              >
                <Input />
              </Form.Item>
            </React.Fragment>
            <React.Fragment>
              <Form.Item
                name="email"
                label={this.getTraduction("email")}
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: this.getTraduction("mandatory.email") },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: this.getTraduction("mailNotValid"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label={this.getTraduction("password")}
                rules={[
                  {
                    required: true,
                    min: 8,
                    max: 64,
                    message: this.getTraduction("error.PASSWORD_LENGTH"),
                  }, // 8 et 64 caractères
                  {
                    pattern: /(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])/,
                    message: this.getTraduction("error.PASSWORD_SYMBOL_MISSING"),
                  }, // 1 symbol
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: this.getTraduction("error.PASSWORD_CAPITAL_MISSING"),
                  }, // 1 maj
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label={this.getTraduction("confirmPassword")}
                dependencies={["password"]}
                rules={[
                  { required: true, message: this.getTraduction("mandatory.confirmPassword") },
                  {
                    type: "enum",
                    enum: [this.state.formValues.password],
                    message: this.getTraduction("passwordNoMatch"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </React.Fragment>
          </FormCarousel>
        </Form>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Register);
