/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout, WishDetailContent, WishDetailHeader } from "components/shared";
import { Col, Row } from "antd";
/****************** STYLING ******************/
import "./WishDetail.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { addNotification, addThread, removeThread } from "store/actions";
import { birthRegistryApiFactory, AbilityContext, wishApiFactory } from "config";
import { WishPublic, BirthRegistry, WishWithBookings } from "bbb-api";
import { subject } from "@casl/ability";
/****************** RENDERING (export) ******************/
type InputProps = {};
type RouteParams = { id: string; wishId: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = {
  authenticated: boolean;
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = {
  loading: boolean;
  birthRegistry?: BirthRegistry;
  manageThisList: boolean;
  wishWithBookings?: WishWithBookings;
  wishPublic?: WishPublic;
};

class WishDetail extends React.Component<Props, State> {
  state: State = {
    loading: true,
    birthRegistry: undefined,
    manageThisList: false,
    wishWithBookings: undefined,
    wishPublic: undefined,
  };
  static contextType = AbilityContext;
  context!: React.ContextType<typeof AbilityContext>;

  /* LifeCycle Methods */
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadBirthRegistry();
  }

  componentDidUpdate(prevProps: Props) {
    const { match } = this.props;
    const { wishPublic, wishWithBookings, birthRegistry, loading } = this.state;
    const wishId = Number(match.params.wishId);
    if (
      birthRegistry &&
      match.params.wishId &&
      _.isInteger(wishId) &&
      !loading &&
      !wishPublic &&
      !wishWithBookings
    ) {
      const manageThisList = this.context.can("read", subject("BirthRegistry", birthRegistry));
      log.trace(this.state.birthRegistry, { manageThisList });
      if (manageThisList) {
        this.loadWish(wishId);
      } else {
        this.loadPublicWish(wishId);
      }
    }
  }

  /* Handlers methods */
  loadWish(wishId: number) {
    log.trace("loadWish", wishId);
    const wishApi = wishApiFactory();
    this.setState((state: State) => ({ ...state, loading: true }));
    this.props.addThread("getWishApiV1WishWishIdGet");
    wishApi
      .getWishApiV1WishWishIdGet(wishId)
      .then((response) => {
        const wishWithBookings: WishWithBookings = response.data;
        log.info(`Successfully fetch /wish/${wishId} `, wishWithBookings);
        this.setState((state: State) => ({
          ...state,
          wishWithBookings: wishWithBookings,
          manageThisList: true,
        }));
      })
      .catch((error) => {
        log.error(`Error loading /wish/${wishId}`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de charger le voeu",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("getWishApiV1WishWishIdGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }

  loadPublicWish(wishId: number) {
    log.trace("loadPublicWish", wishId);
    const wishApi = wishApiFactory();
    this.setState((state: State) => ({ ...state, loading: true }));
    this.props.addThread("getWishWithoutAuthApiV1WishPublicWishIdGet");
    wishApi
      .getWishWithoutAuthApiV1WishPublicWishIdGet(wishId)
      .then((response) => {
        const wishPublic: WishPublic = response.data;
        log.info(`Successfully fetch public /wish/${wishId} `, wishPublic);
        this.setState((state: State) => ({
          ...state,
          wishPublic: wishPublic,
          manageThisList: false,
        }));
      })
      .catch((error) => {
        log.error(`Error loading /wish/${wishId}`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de charger le voeu",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("getWishWithoutAuthApiV1WishPublicWishIdGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }

  loadBirthRegistry() {
    const { match } = this.props;
    if (match.params.id) {
      log.info("Load details from birthRegistryId : ", match.params.id);
      const wishApi = birthRegistryApiFactory();
      if (_.isInteger(Number(match.params.id))) {
        const birthRegistryId = Number(match.params.id);
        this.props.addThread("getBirthRegistryApiV1BirthregistryBirthRegistryIdGet");
        wishApi
          .getBirthRegistryApiV1BirthregistryBirthRegistryIdGet(birthRegistryId)
          .then((response) => {
            const birthRegistry: BirthRegistry = response.data;
            log.info(`Successfully fetch /birthRegistries/${birthRegistryId} `, birthRegistry);
            this.setState((state: State) => ({
              ...state,
              birthRegistry: birthRegistry,
            }));
          })
          .catch((error) => {
            log.error(`Error fetching /birthRegistries/${birthRegistryId}`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de récupérer la liste de naissance",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("getBirthRegistryApiV1BirthregistryBirthRegistryIdGet");
            this.setState((state: State) => ({ ...state, loading: false }));
          });
      } else {
        const nanoId = match.params.id;
        this.props.addThread("getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet");
        wishApi
          .getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet(nanoId)
          .then((response) => {
            const birthRegistry: BirthRegistry = response.data;
            log.info(`Successfully fetch /birthRegistries/${nanoId} `, birthRegistry);
            this.setState((state: State) => ({
              ...state,
              birthRegistry: birthRegistry,
            }));
          })
          .catch((error) => {
            log.error(`Error fetching /birthRegistries/${nanoId}`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de récupérer la liste de naissance",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread(
              "getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet"
            );
            this.setState((state: State) => ({ ...state, loading: false }));
          });
      }
    }
  }

  /* Render methods */
  renderTitle() {
    const { wishWithBookings, wishPublic } = this.state;
    const wish = wishWithBookings ? wishWithBookings : wishPublic ? wishPublic : undefined;
    if (wish !== undefined) {
      return (
        <Row className="wishDetailTitle" align="middle" justify="space-between">
          <Col xs={24} sm={24} md={18} lg={21} xl={21} xxl={21}>
            {wish.title}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={4}
            lg={3}
            xl={3}
            xxl={3}
            className="price"
          >{`${wish.price} €`}</Col>
          <Col span={1}></Col>
        </Row>
      );
    }
  }

  render() {
    const { wishWithBookings, wishPublic, birthRegistry, loading, manageThisList } = this.state;
    const wish = wishWithBookings ? wishWithBookings : wishPublic ? wishPublic : undefined;
    return (
      <BBBLayout
        mode="FULL"
        title={wish ? this.renderTitle() : undefined}
        titleSeo={wish ? wish.title ?? "wishDetail.title" : "wishDetail.title"}
        loading={loading}
      >
        {wish ? (
          <>
            <WishDetailHeader
              wish={wish}
              birthRegistry={birthRegistry}
              manageThisList={manageThisList}
            />
            <WishDetailContent
              wishPublic={wishPublic}
              wishWithBookings={wishWithBookings}
              birthRegistry={birthRegistry}
            />
          </>
        ) : undefined}
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    authenticated: state.user.authenticated,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WishDetail);
