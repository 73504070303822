/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { ManOutlined, WomanOutlined } from "@ant-design/icons";

/****************** STYLING ******************/
import "./GenderIcon.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { Gender } from "bbb-api/dist/models";

/****************** RENDERING (export) ******************/
type InputProps = {
  gender?: Gender;
  genderIsPrivate?: boolean;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class GenderIcon extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */
  renderIcon() {
    const { gender, genderIsPrivate } = this.props;
    if (!genderIsPrivate && gender) {
      if (gender === Gender.M) {
        return <ManOutlined />;
      } else if (gender === Gender.F) {
        return <WomanOutlined />;
      }
    }
  }

  render() {
    return <span className="genderIcon">{this.renderIcon()}</span>;
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GenderIcon);
