/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Modal, Typography } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";

/****************** RENDERING (export) ******************/
type InputProps = {
  visible: boolean;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};

type State = { itemCount: number };

class LegalModal extends React.Component<Props, State> {
  state: State = { itemCount: 9 };

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `legalModal.${id}` }, param);
  };

  close = () => {
    this.props.history.goBack();
  };

  /* Render methods */
  renderBlock(index: number) {
    return (
      <>
        <Typography.Title>{this.getTraduction(`content.t${index + 1}`)}</Typography.Title>
        <Typography.Paragraph>{this.getTraduction(`content.p${index + 1}`)}</Typography.Paragraph>
      </>
    );
  }
  render() {
    return (
      <Modal
        title={
          <Typography.Text strong={true}>
            {this.getTraduction(`title`)} - {this.getTraduction(`date`)}
          </Typography.Text>
        }
        visible={this.props.visible}
        onCancel={this.close}
        width={800}
        destroyOnClose={true}
        footer={[
          <BbbButton key="primary" type="default" onClick={this.close}>
            <FormattedMessage id="legalModal.close" />
          </BbbButton>,
        ]}
      >
        <Typography.Paragraph>{this.getTraduction(`content.p0`)}</Typography.Paragraph>
        {Array.from({ length: this.state.itemCount }, (_, index) => this.renderBlock(index))}
      </Modal>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(LegalModal);
