import { combineReducers } from "redux";
import {
  configurationReducer,
  StateConfiguration,
  initialStateConfiguration,
} from "./configuration";
import { initialStateUser, StateUser, userReducer } from "./user";
import {
  initialStateBirthRegistries,
  StateBirthRegistries,
  birthRegistriesReducer,
} from "./birthRegistries";
import { initialStateCategories, StateCategories, categoriesReducer } from "./categories";
import { initialStateBasket, StateBasket, basketReducer } from "./basket";

export * from "./configuration";

export const initialState: Store = {
  config: initialStateConfiguration,
  user: initialStateUser,
  birthRegistries: initialStateBirthRegistries,
  categories: initialStateCategories,
  basket: initialStateBasket,
};

export type Store = {
  config: StateConfiguration;
  user: StateUser;
  birthRegistries: StateBirthRegistries;
  categories: StateCategories;
  basket: StateBasket;
};
export default combineReducers<Store>({
  config: configurationReducer,
  user: userReducer,
  birthRegistries: birthRegistriesReducer,
  categories: categoriesReducer,
  basket: basketReducer,
});
