/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout } from "components/shared";
import WishFormContainer from "./WishFormContainer";

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Wish } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
import { wishApiFactory } from "config";

/****************** RENDERING (export) ******************/
type InputProps = {};
type RouteParams = { id: string; wishId: string };
type Props = InputProps & MapStateToProps & MapDispatchToProps & RouteComponentProps<RouteParams>;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = {
  loading: boolean;
  selectedWish?: Wish;
  subpage: "MAIN";
};

class EditWish extends React.Component<Props, State> {
  state: State = {
    loading: true,
    subpage: "MAIN",
  };

  /* LifeCycle Methods */
  componentDidMount() {
    const { match } = this.props;
    if (match.params.wishId && _.isInteger(Number(match.params.wishId))) {
      const wishId = Number(match.params.wishId);
      const wishApi = wishApiFactory();
      this.props.addThread("getWishApiV1WishWishIdGet");
      wishApi
        .getWishApiV1WishWishIdGet(wishId)
        .then((response) => {
          const wish: Wish = response.data;
          log.info(`Successfully fetch /wish/${wishId} `, wish);
          this.setState((state: State) => ({ ...state, selectedWish: wish }));
        })
        .catch((error) => {
          log.error(`Error loading /wish/${wishId}`, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de charger le voeu",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("getWishApiV1WishWishIdGet");
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  }

  /* Handlers methods */
  changePage = (subpage: State["subpage"]) => {
    this.setState((state: State) => ({ subpage: subpage }));
  };

  /* Render methods */
  render() {
    return (
      <BBBLayout
        mode="FULL"
        onlyBottom={true}
        titleSeo={`editWish.title.${this.state.subpage}`}
        loading={this.state.loading}
      >
        <WishFormContainer
          selectedWish={this.state.selectedWish}
          changePage={this.changePage}
        ></WishFormContainer>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditWish);
