/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Form, Input, Modal } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Store as FormStore, ValidateErrorEntity, FieldData } from "rc-field-form/lib/interface";
import { emailApiFactory } from "config";
import { EmailApiCallResult } from "bbb-api/dist/models";
import { addThread, removeThread, addNotification } from "store/actions";
import { FormInstance } from "antd/lib/form";
import { EmailInShareBirthRegistry } from "bbb-api";

/****************** RENDERING (export) ******************/

export type ShareChannel = "MAIL" | "FACEBOOK" | "TWITTER";
type InputProps = {
  visible: boolean;
  birthRegistryId: number;
  shortLinkFullUrl: string;
  shareChannel: ShareChannel;
  onCloseShare(): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type ShortLinkShareFields = {
  toMail: string;
  toName: string;
};

type State = {
  formValues?: ShortLinkShareFields;
  formIsValid: boolean;
  formRef: React.RefObject<FormInstance>;
};

class ShortLinkShareModal extends React.Component<Props, State> {
  state: State = {
    formValues: undefined,
    formIsValid: false,
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `${id}` }, param);
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  onFinish = (values: FormStore) => {
    log.info("Submit shortLinkShare", values);
    if (this.state.formValues) {
      log.info(this.state.formValues);
      const formValues: ShortLinkShareFields = this.state.formValues;
      const restData: EmailInShareBirthRegistry = {
        to_mail: formValues.toMail,
        to_name: formValues.toName,
        birth_registry_id: this.props.birthRegistryId,
        shortlink_name: this.props.shortLinkFullUrl,
      };
      const emailApi = emailApiFactory();
      this.props.addThread("sendEmailShareBirthRegistryApiV1EmailShareListPost");
      emailApi
        .sendEmailShareBirthRegistryApiV1EmailShareListPost(restData)
        .then((response) => {
          const result: EmailApiCallResult = response.data;
          log.info(`Successfully post /email shortlink `, result);
          this.props.addNotification({
            type: "success",
            description: "La liste a bien été partagée par email",
            title: "Partage réussi",
          });
          this.props.onCloseShare();
        })
        .catch((error) => {
          log.error(`Error post /email shortlink`, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de partager la liste",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("sendEmailShareBirthRegistryApiV1EmailShareListPost");
        });
    }
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as ShortLinkShareFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange");
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  cancel = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    this.state.formRef.current?.resetFields();
    this.props.onCloseShare();
  };

  /* Render methods */
  render() {
    return (
      <Modal
        title={this.getTraduction(`shortLink.shareByEmail`)}
        visible={this.props.visible}
        onCancel={this.cancel}
        width={800}
        destroyOnClose={true}
        footer={[
          <BbbButton
            className="bbb-btn-light-primary"
            key="primary"
            type="primary"
            onClick={this.submit}
            disabled={!this.state.formIsValid}
          >
            <FormattedMessage id="shortLink.shareButton" />
          </BbbButton>,
          <BbbButton key="cancel" type="dashed" onClick={this.cancel}>
            <FormattedMessage id="shortLink.cancel" />
          </BbbButton>,
        ]}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          ref={this.state.formRef}
          onValuesChange={this.valuesChange}
          onFieldsChange={this.onFieldsChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          scrollToFirstError={true}
        >
          <span>{this.getTraduction(`shortLink.description`)}</span>
          <Form.Item
            name="toName"
            label={this.getTraduction("shortLink.label.toName")}
            rules={[
              {
                required: true,
                message: this.getTraduction("shortLink.rules.mandatory"),
              },
              { max: 255, message: this.getTraduction("shortLink.rules.length") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="toMail"
            label={this.getTraduction("shortLink.label.toMail")}
            rules={[
              {
                required: true,
                message: this.getTraduction("shortLink.rules.mandatory"),
              },
              { max: 255, message: this.getTraduction("shortLink.rules.length") },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ShortLinkShareModal);
