/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout, BirthRegistriesList } from "components/shared";

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { birthRegistryApiFactory } from "config";
import { BirthRegistry } from "bbb-api/dist/models";
import {
  addThread,
  removeThread,
  addNotification,
  deleteElement,
  setBirthRegistryList,
} from "store/actions";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps;
type MapStateToProps = { birthRegistries: BirthRegistry[] };
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  setList(birthRegistries: BirthRegistry[]): void;
  deleteElement(birthRegistry: BirthRegistry): void;
};
type State = { loading: boolean };

class MyBirthRegistries extends React.Component<Props, State> {
  state: State = { loading: true };

  /* LifeCycle Methods */
  componentDidMount() {
    const birthRegistryApi = birthRegistryApiFactory();
    this.props.addThread("getUserBirthRegistryApiV1BirthregistryMineGet");
    birthRegistryApi
      .getUserBirthRegistriesApiV1BirthregistryMineGet()
      .then((response) => {
        const birthRegistries: BirthRegistry[] = response.data;
        log.info(`Successfully fetch /birthRegistries/mine `, birthRegistries);
        this.props.setList(birthRegistries);
      })
      .catch((error) => {
        log.error(`Error fetching /birthRegistries/mine`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de récupérer les listes de naissance",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("getUserBirthRegistryApiV1BirthregistryMineGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }
  /* Handlers methods */
  deleteElement = (birthRegistryDelete: BirthRegistry): void => {
    this.props.deleteElement(birthRegistryDelete);
  };

  /* Render methods */
  render() {
    return (
      <BBBLayout mode="FULL" titleSeo="myBirthRegistries.title" loading={this.state.loading}>
        <BirthRegistriesList
          mode="MY"
          birthRegistries={this.props.birthRegistries}
          deleteElement={this.deleteElement}
        ></BirthRegistriesList>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    birthRegistries: state.birthRegistries.list,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    setList: (birthRegistries: BirthRegistry[]) => dispatch(setBirthRegistryList(birthRegistries)),
    deleteElement: (birthRegistry: BirthRegistry) => dispatch(deleteElement(birthRegistry)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MyBirthRegistries);
