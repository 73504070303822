/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Category, Wish } from "bbb-api/dist/models";
import {
  addWishElement,
  updateWishElement,
  addThread,
  removeThread,
  addNotification,
  setCategoryList,
} from "store/actions";
import { WishForm } from "components/shared";
import { categoryApiFactory } from "config";

/****************** RENDERING (export) ******************/
type InputProps = {
  selectedWish?: Wish;
  changePage(subpage: "MAIN"): void;
};
type RouteParams = { id: string };
type Props = InputProps & MapStateToProps & MapDispatchToProps & RouteComponentProps<RouteParams>;
type MapStateToProps = { categories: Category[] };
type MapDispatchToProps = {
  addElement(wish: Wish): void;
  updateElement(wish: Wish): void;
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  setList(categories: Category[]): void;
};

type State = { loading: boolean; selectedBirthRegistryId?: number };

class WishFormContainer extends React.Component<Props, State> {
  state: State = { loading: true };

  /* LifeCycle Methods */
  componentDidMount() {
    const { match } = this.props;
    if (match.params.id && _.isInteger(Number(match.params.id))) {
      const birthRegistryId = Number(match.params.id);
      this.setState((state: State) => ({ ...state, selectedBirthRegistryId: birthRegistryId }));
      this.refreshCategories(birthRegistryId);
    }
  }

  /* Handlers methods */
  refreshCategories = (birthRegistryId: number) => {
    if (_.isEmpty(this.props.categories)) {
      this.setState({ loading: true });
      const categoryApi = categoryApiFactory();
      this.props.addThread(
        "getCategoryFromBirthRegistryApiV1CategoryBirthRegistryBirthRegistryIdGet"
      );
      categoryApi
        .getCategoryFromBirthRegistryApiV1CategoryBirthRegistryBirthRegistryIdGet(birthRegistryId)
        .then((response) => {
          const categories: Category[] = response.data;
          log.info(`Successfully fetch /categories/birth_registry/${birthRegistryId} `, categories);
          this.props.setList(categories);
        })
        .catch((error) => {
          log.error(`Error fetching /categories/birth_registry/${birthRegistryId} `, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de récupérer la liste des catégories",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread(
            "getCategoryFromBirthRegistryApiV1CategoryBirthRegistryBirthRegistryIdGet"
          );
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  };

  addElement = (wish: Wish): void => {
    this.props.addElement(wish);
    this.backToList();
  };

  updateElement = (wish: Wish): void => {
    this.props.updateElement(wish);
    this.backToList();
  };

  backToList = (): void => {
    this.props.history.goBack();
  };

  /* Render methods */
  render() {
    if (this.state.selectedBirthRegistryId) {
      return (
        <WishForm
          addElement={this.addElement}
          updateElement={this.updateElement}
          cancel={this.backToList}
          selectedWish={this.props.selectedWish}
          changePage={this.props.changePage}
          birthRegistryId={this.state.selectedBirthRegistryId}
          categories={this.props.categories}
        ></WishForm>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}
export function mapStateToProps(state: Store): MapStateToProps {
  return { categories: state.categories.list };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addElement: (wish: Wish) => dispatch(addWishElement(wish)),
    updateElement: (wish: Wish) => dispatch(updateWishElement(wish)),
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    setList: (categories: Category[]) => dispatch(setCategoryList(categories)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WishFormContainer);
