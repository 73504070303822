import { CategoryAction } from "store/actions";
import {
  LOGIN,
  WISH_ADD,
  WISH_UPDATE,
  WISH_DELETE,
  CATEGORY_SET,
  CATEGORY_ADD,
  CATEGORY_DELETE,
  SEARCHFIELDS_SET,
} from "store/constants";
import { Category } from "bbb-api/dist/models";
import * as _ from "lodash";

export const DEFAULT_FILTER_PRICE_MIN: number = 0;
export const DEFAULT_FILTER_PRICE_MAX: number = 150;
export const DEFAULT_PRICE_STEP: number = 10;

export type SearchFields = {
  fullText?: string;
  onlyAvailable?: boolean;
  onlyBooked?: boolean;
  priceMin?: number;
  priceMax?: number;
  category?: number;
};

export interface StateCategories {
  list: Category[];
  filters?: SearchFields;
}

export const initialStateCategories: StateCategories = {
  list: [],
  filters: {
    fullText: undefined,
    onlyAvailable: undefined,
    onlyBooked: undefined,
    priceMin: DEFAULT_FILTER_PRICE_MIN,
    priceMax: DEFAULT_FILTER_PRICE_MAX,
    category: 0,
  },
};

export function categoriesReducer(
  state: StateCategories = initialStateCategories,
  action: CategoryAction
): StateCategories {
  switch (action.type) {
    case LOGIN: {
      if (action.payload === false) {
        return initialStateCategories;
      } else {
        return state;
      }
    }
    case WISH_ADD:
      const categoriesUpdated = _.map(state.list, (category) => {
        if (category.id === action.payload.category_id) {
          return {
            ...category,
            wishes: category.wishes ? [...category.wishes, action.payload] : [action.payload],
          };
        } else {
          return category;
        }
      });
      return { ...state, list: categoriesUpdated };
    case WISH_DELETE: {
      const categoriesUpdated = _.map(state.list, (category) => {
        if (category.id === action.payload.category_id) {
          return {
            ...category,
            wishes: _.filter(category.wishes, (wish) => wish.id !== action.payload.id),
          };
        } else {
          return category;
        }
      });
      return { ...state, list: categoriesUpdated };
    }
    case WISH_UPDATE: {
      const categoriesUpdated = _.map(state.list, (category) => {
        if (category.id === action.payload.category_id) {
          return {
            ...category,
            wishes: _.map(category.wishes, (wish) => {
              if (wish.id === action.payload.id) {
                return action.payload;
              } else {
                return wish;
              }
            }),
          };
        } else {
          return category;
        }
      });
      return { ...state, list: categoriesUpdated };
    }
    case CATEGORY_SET: {
      return { ...state, list: action.payload };
    }
    case CATEGORY_ADD: {
      return { ...state, list: [...state.list, action.payload] };
    }
    case CATEGORY_DELETE: {
      return { ...state, list: _.filter(state.list, (categ) => categ.id !== action.payload.id) };
    }
    case SEARCHFIELDS_SET: {
      return { ...state, filters: action.payload };
    }
    default:
      return state;
  }
}
