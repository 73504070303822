/****************** DEPENDENCIES (import) ******************/

import { withRouter, RouteComponentProps } from "react-router-dom";
import { FormattedMessage, IntlShape, injectIntl } from "react-intl";
import { compose } from "recompose";
import React from "react";
import { connect } from "react-redux";
import Parser from "rss-parser";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Row, Col, Spin } from "antd";
/****************** STYLING ******************/
import "./NewsList.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { RssContent } from "./News";
import NewsItem from "./NewsItem";
import { BbbButton } from "components/shared";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps &
  RouteComponentProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = { loading: boolean; currentStep: number; rssContent?: RssContent };

const loadMoreStep = 9;
class NewsList extends React.Component<Props, State> {
  state: State = { loading: true, currentStep: 1 };

  /* LifeCycle Methods */
  componentDidMount() {
    const parser = new Parser({
      customFields: {
        item: ["featured_image"],
      },
    });
    const CORS_PROXY = process.env.REACT_APP_CORS_PROXY;
    parser
      .parseURL(
        CORS_PROXY
          ? CORS_PROXY + process.env.REACT_APP_RSS_URL
          : process.env.REACT_APP_RSS_URL || "https://blog.bienvenuebebe.com/feed/"
      )
      .then((response) => {
        const rssResponse = {
          info: {
            feedUrl: response.feedUrl,
            title: response.title,
            description: response.description,
            link: response.link,
          },
          allItems: response.items,
          displayedItems: _.take(response.items, loadMoreStep),
        } as RssContent;
        log.info("RssResponse", rssResponse);
        this.setState((state: State) => ({ ...state, rssContent: rssResponse, loading: false }));
      })
      .catch((error) => {
        log.error("RssError", error);
      })
      .finally(() => {
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }
  /* Handlers methods */
  loadMore = () => {
    this.setState((state: State) => {
      const newStep = state.currentStep + 1;
      const newCount = newStep * loadMoreStep;
      const newDisplayedItems = _.take(state.rssContent?.allItems, newCount);
      log.trace("loadMore - newCount", newCount);
      log.trace("loadMore - newDisplayedItems", newDisplayedItems);
      return {
        ...state,
        rssContent: state.rssContent && {
          ...state.rssContent,
          displayedItems: newDisplayedItems,
        },
        currentStep: newStep,
      };
    });
  };

  /* Render methods */
  render() {
    const { loading, rssContent } = this.state;
    return (
      <Card
        className="full-width"
        style={{ marginLeft: "12px", marginRight: "12px", boxShadow: "none" }}
      >
        {loading && _.isUndefined(rssContent) ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <div className="grid">
            {rssContent?.displayedItems.map((item) => (
              <Col key={item.guid}>
                <NewsItem article={item} />
              </Col>
            ))}
            {_.size(rssContent?.allItems) > 0 &&
              _.size(rssContent?.displayedItems) !== _.size(rssContent?.allItems) && (
                <Col>
                  <Row justify="center" align="middle">
                    <BbbButton
                      className="loadMore"
                      type="primary"
                      size="large"
                      onClick={this.loadMore}
                      loading={loading}
                    >
                      <FormattedMessage id="home.load.more" />
                    </BbbButton>
                  </Row>
                </Col>
              )}
          </div>
        )}
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(NewsList);
