import { BirthRegistryAction } from "store/actions";
import {
  BIRTH_REGISTRY_ADD,
  BIRTH_REGISTRY_DELETE,
  BIRTH_REGISTRY_UPDATE,
  BIRTH_REGISTRY_SET,
  BIRTH_REGISTRY_ADMIN_SET,
  BIRTH_REGISTRY_ADMIN_DELETE,
  LOGIN,
} from "store/constants";
import { BirthRegistry } from "bbb-api/dist/models";
import * as _ from "lodash";

export interface StateBirthRegistries {
  list: BirthRegistry[];
  adminList: BirthRegistry[];
}

export const initialStateBirthRegistries: StateBirthRegistries = {
  list: [],
  adminList: [],
};

export function birthRegistriesReducer(
  state: StateBirthRegistries = initialStateBirthRegistries,
  action: BirthRegistryAction
): StateBirthRegistries {
  switch (action.type) {
    case LOGIN: {
      if (action.payload === false) {
        return initialStateBirthRegistries;
      } else {
        return state;
      }
    }
    case BIRTH_REGISTRY_SET: {
      return { ...state, list: action.payload };
    }
    case BIRTH_REGISTRY_ADMIN_SET: {
      return { ...state, adminList: action.payload };
    }
    case BIRTH_REGISTRY_ADD:
      return { ...state, list: [...state.list, action.payload] };
    case BIRTH_REGISTRY_DELETE: {
      const registriesUpdated: BirthRegistry[] = _.filter(
        state.list,
        (birthRegistry) => action.payload.id !== birthRegistry.id
      );
      return { ...state, list: registriesUpdated };
    }
    case BIRTH_REGISTRY_ADMIN_DELETE: {
      const registriesUpdated: BirthRegistry[] = _.filter(
        state.adminList,
        (birthRegistry) => action.payload.id !== birthRegistry.id
      );
      return { ...state, adminList: registriesUpdated };
    }
    case BIRTH_REGISTRY_UPDATE: {
      const registriesUpdated = _.map(state.list, (birthRegistry) => {
        if (action.payload.id === birthRegistry.id) {
          return action.payload;
        } else {
          return birthRegistry;
        }
      });
      return { ...state, list: registriesUpdated };
    }
    default:
      return state;
  }
}
