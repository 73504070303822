import * as constants from "store/constants";
import { BirthRegistry } from "bbb-api/dist/models";
import { LoginType } from "./user";

export type BirthRegistryAction =
  | LoginType
  | AddBirthRegistryType
  | UpdateBirthRegistryType
  | DeleteBirthRegistryType
  | DeleteAdminBirthRegistryType
  | SetBirthRegistryListType
  | SetAdminBirthRegistryListType;

export interface AddBirthRegistryType {
  type: constants.BIRTH_REGISTRY_ADD;
  payload: BirthRegistry;
}

export function addElement(birthRegistry: BirthRegistry): AddBirthRegistryType {
  return {
    type: constants.BIRTH_REGISTRY_ADD,
    payload: birthRegistry,
  };
}

export interface UpdateBirthRegistryType {
  type: constants.BIRTH_REGISTRY_UPDATE;
  payload: BirthRegistry;
}

export function updateElement(birthRegistry: BirthRegistry): UpdateBirthRegistryType {
  return {
    type: constants.BIRTH_REGISTRY_UPDATE,
    payload: birthRegistry,
  };
}

export interface DeleteBirthRegistryType {
  type: constants.BIRTH_REGISTRY_DELETE;
  payload: BirthRegistry;
}

export function deleteElement(birthRegistry: BirthRegistry): DeleteBirthRegistryType {
  return {
    type: constants.BIRTH_REGISTRY_DELETE,
    payload: birthRegistry,
  };
}

export interface DeleteAdminBirthRegistryType {
  type: constants.BIRTH_REGISTRY_ADMIN_DELETE;
  payload: BirthRegistry;
}

export function deleteAdminElement(
  adminBirthRegistry: BirthRegistry
): DeleteAdminBirthRegistryType {
  return {
    type: constants.BIRTH_REGISTRY_ADMIN_DELETE,
    payload: adminBirthRegistry,
  };
}

export interface SetBirthRegistryListType {
  type: constants.BIRTH_REGISTRY_SET;
  payload: BirthRegistry[];
}

export function setBirthRegistryList(birthRegistries: BirthRegistry[]): SetBirthRegistryListType {
  return {
    type: constants.BIRTH_REGISTRY_SET,
    payload: birthRegistries,
  };
}

export interface SetAdminBirthRegistryListType {
  type: constants.BIRTH_REGISTRY_ADMIN_SET;
  payload: BirthRegistry[];
}

export function setAdminList(adminBirthRegistries: BirthRegistry[]): SetAdminBirthRegistryListType {
  return {
    type: constants.BIRTH_REGISTRY_ADMIN_SET,
    payload: adminBirthRegistries,
  };
}
