/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Divider, Row, Spin } from "antd";
import {
  BirthRegistryCashCard,
  BirthRegistryDetailSearchBar,
  CategoriesList,
  Can,
  ShortLinkBar,
} from "components/shared";
/****************** STYLING ******************/
import "./BirthRegistryContent.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { BirthRegistry, Category } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread, setCategoryList } from "store/actions";
import { categoryApiFactory } from "config";
import { subject } from "@casl/ability";

/****************** RENDERING (export) ******************/
type InputProps = { loading: boolean; birthRegistry?: BirthRegistry };
type RouteParams = { id: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = { categories: Category[]; cashPoolFeature: boolean };
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  setList(categories: Category[]): void;
};
type State = {
  loading: boolean;
  displayShortLinkForm: boolean;
};

class BirthRegistryContent extends React.Component<Props, State> {
  state: State = {
    loading: false,
    displayShortLinkForm: false,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.birthRegistry) {
      this.refreshCategories(this.props.birthRegistry.id);
    }
  }

  /* Handlers methods */
  refreshCategories = (birthRegistryId?: number): void => {
    if (birthRegistryId) {
      this.setState({ loading: true });
      const categoryApi = categoryApiFactory();
      this.props.addThread(
        "getCategoryFromBirthRegistryApiV1CategoryBirthRegistryBirthRegistryIdGet"
      );
      categoryApi
        .getCategoryFromBirthRegistryApiV1CategoryBirthRegistryBirthRegistryIdGet(birthRegistryId)
        .then((response) => {
          const categories: Category[] = response.data;
          log.info(`Successfully fetch /categories/birth_registry/${birthRegistryId} `, categories);
          this.props.setList(categories);
        })
        .catch((error) => {
          log.error(`Error fetching /categories/birth_registry/${birthRegistryId} `, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de récupérer la liste des catégories",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread(
            "getCategoryFromBirthRegistryApiV1CategoryBirthRegistryBirthRegistryIdGet"
          );
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  };

  /* Render methods */
  renderCashPool() {
    if (this.props.cashPoolFeature) {
      return (
        <BirthRegistryCashCard
          loading={this.props.loading}
          birthRegistry={this.props.birthRegistry}
        ></BirthRegistryCashCard>
      );
    } else {
      return undefined;
    }
  }

  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <React.Fragment>
          <Divider></Divider>
          <Card>
            <Row justify="center">
              <Spin size="large" delay={300} className="progress" spinning={true}></Spin>
            </Row>
          </Card>
        </React.Fragment>
      );
    } else if (this.props.birthRegistry) {
      return (
        <React.Fragment>
          {this.renderCashPool()}
          <Can I="manage" this={subject("BirthRegistry", this.props.birthRegistry)}>
            <ShortLinkBar
              birthRegistryId={this.props.birthRegistry.id}
              title={this.props.birthRegistry.title}
              existingShortLink={_.nth(this.props.birthRegistry.shortlinks, 0)}
            ></ShortLinkBar>
          </Can>
          <BirthRegistryDetailSearchBar
            birthRegistry={this.props.birthRegistry}
          ></BirthRegistryDetailSearchBar>
          <Card className="wishList">
            <CategoriesList
              birthRegistry={this.props.birthRegistry}
              categories={this.props.categories}
            ></CategoriesList>
          </Card>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    cashPoolFeature: state.config.features.cashPoolFeature,
    categories: state.categories.list,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    setList: (categories: Category[]) => dispatch(setCategoryList(categories)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryContent);
