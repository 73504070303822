/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage, FormattedDate } from "react-intl";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import logo from "../../../../assets/logo.svg";
import { Card, Col, Row, Tooltip, Typography, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Can } from "components/shared";
/****************** STYLING ******************/
import "./BirthRegistryDetailHeaderMain.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { Baby, BirthRegistry } from "bbb-api/dist/models";
import { prepareUrl } from "config/constants";
import { subject } from "@casl/ability";
import GenderIcon from "components/shared/gender/GenderIcon";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {
  loading: boolean;
  birthRegistry?: BirthRegistry;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class BirthRegistryDetailHeaderMain extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */

  renderNames(babies: Baby[] | undefined) {
    return (
      <Typography.Paragraph className="name" ellipsis={{ rows: 1 }}>
        {!_.isUndefined(babies) &&
          babies.map((baby, index) => (
            <Typography.Text key={baby.id}>
              {!baby.firstname_is_private && baby.firstname}
              <GenderIcon
                gender={baby.gender}
                genderIsPrivate={baby.gender_is_private}
              ></GenderIcon>
            </Typography.Text>
          ))}
      </Typography.Paragraph>
    );
  }

  renderKeepSecret() {
    if (this.props.birthRegistry) {
      return (
        <Can I="manage" this={subject("BirthRegistry", this.props.birthRegistry)}>
          <Typography.Paragraph>
            <FormattedMessage id="birthRegistryDetailHeader.keepSecret"></FormattedMessage>
            <Typography.Text>&nbsp;:&nbsp;</Typography.Text>
            {this.renderSecret(this.props.birthRegistry?.babies)}
          </Typography.Paragraph>
        </Can>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  renderSecret(babies: Baby[] | undefined) {
    if (babies) {
      const firstSecret = !_.isEmpty(babies.filter((baby) => baby.firstname_is_private));
      const genderSecret = !_.isEmpty(babies.filter((baby) => baby.gender_is_private));
      return (
        <React.Fragment>
          {firstSecret ? (
            <Typography.Text>
              <FormattedMessage id="birthRegistryDetailHeader.firstname"></FormattedMessage>
            </Typography.Text>
          ) : undefined}
          {firstSecret && genderSecret ? <Typography.Text>, </Typography.Text> : undefined}
          {genderSecret ? (
            <Typography.Text>
              <FormattedMessage id="birthRegistryDetailHeader.gender"></FormattedMessage>
            </Typography.Text>
          ) : undefined}
          {!firstSecret && !genderSecret ? (
            <Typography.Text>
              <FormattedMessage id="birthRegistryDetailHeader.none"></FormattedMessage>
            </Typography.Text>
          ) : undefined}
        </React.Fragment>
      );
    }
  }

  render() {
    const { birthRegistry, loading } = this.props;
    return (
      <Card className="main-desc" loading={loading}>
        <Row gutter={[16, 16]} justify="space-around">
          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12}>
            <div className="cover">
              <Image
                src={
                  birthRegistry?.thumbnail_path ? prepareUrl(birthRegistry.thumbnail_path) : logo
                }
                preview={{
                  src: birthRegistry?.image_path ? prepareUrl(birthRegistry.image_path) : logo,
                }}
                className="logo"
                alt="logo"
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={12}>
            <Row justify="space-between" align="middle" wrap={false}>
              {this.renderNames(birthRegistry?.babies)}
              {birthRegistry && (
                <Can I="manage" this={subject("BirthRegistry", birthRegistry)}>
                  <Tooltip title={<FormattedMessage id="birthRegistryCard.edit" />}>
                    <Link to={BBBRoutes.BIRTHREGISTRY_EDIT.computePath(birthRegistry.id)}>
                      <EditOutlined />
                    </Link>
                  </Tooltip>
                </Can>
              )}
            </Row>
            {this.renderKeepSecret()}
            <Typography.Paragraph>
              <FormattedMessage id="birthRegistryDetailHeader.date"></FormattedMessage>
              <Typography.Text>&nbsp;:&nbsp;</Typography.Text>
              <FormattedDate value={birthRegistry?.event_date}></FormattedDate>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Row>
                <Col>
                  <FormattedMessage id="birthRegistryDetailHeader.shipping"></FormattedMessage>
                  <Typography.Text>&nbsp;:&nbsp;</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{birthRegistry?.address_owner}</Typography.Text>
                  <br />
                  <Typography.Text>{birthRegistry?.address_first_line}</Typography.Text>
                  <br />
                  <Typography.Text>{birthRegistry?.address_zip_code}</Typography.Text>
                  <Typography.Text> </Typography.Text>
                  <Typography.Text>{birthRegistry?.address_city}</Typography.Text>
                  <Typography.Text> </Typography.Text>
                  <Typography.Text>({birthRegistry?.address_country})</Typography.Text>
                </Col>
              </Row>
            </Typography.Paragraph>
          </Col>
        </Row>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryDetailHeaderMain);
