import * as _ from "lodash";

declare global {
  interface Window {
    [key: string]: string;
  }
}

let _REACT_APP_BBB_API_URL: string;

let _REACT_APP_TEST_LOGIN: string;
let _REACT_APP_TEST_PASS: string;

if (typeof window !== "undefined") {
  _REACT_APP_BBB_API_URL = loadFromWindow("REACT_APP_BBB_API_URL");
  _REACT_APP_TEST_LOGIN = loadFromWindow("REACT_APP_TEST_LOGIN");
  _REACT_APP_TEST_PASS = loadFromWindow("REACT_APP_TEST_PASS");
} else {
  _REACT_APP_BBB_API_URL = loadFromEnv("REACT_APP_BBB_API_URL");
  _REACT_APP_TEST_LOGIN = loadFromEnv("REACT_APP_TEST_LOGIN");
  _REACT_APP_TEST_PASS = loadFromEnv("REACT_APP_TEST_PASS");
}

function loadFromWindow(code: string): string {
  let result: string;
  if (window[code]) {
    result = window[code];
    console.info("Load variable from window", {
      [code]: result,
    });
  } else {
    result = loadFromEnv(code);
  }
  return result;
}

function loadFromEnv(code: string): string {
  const result = process.env[code] || "";
  console.info("Loaded variables from process.env", {
    [code]: result,
  });
  return result;
}

export function prepareUrl(url?: string): string {
  if (!_.isNil(url)) {
    if (_.startsWith(url, "http")) {
      return url;
    } else if (process.env.NODE_ENV === "production") {
      return `https://${url}`;
    } else {
      return `http://${url}`;
    }
  } else {
    return "";
  }
}

export { _REACT_APP_BBB_API_URL as REACT_APP_BBB_API_URL };
export { _REACT_APP_TEST_LOGIN as REACT_APP_TEST_LOGIN };
export { _REACT_APP_TEST_PASS as REACT_APP_TEST_PASS };
