/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl } from "react-intl";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import WishItem from "./WishItem";
/****************** STYLING ******************/
import "./WishesList.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { WishWithBooked, BirthRegistry } from "bbb-api/dist/models";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry: BirthRegistry;
  wishes: WishWithBooked[];
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class WishesList extends React.Component<Props, State> {
  state: State = {};
  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `wishesList.${id}` }, param);
  };

  /* Render methods */
  render() {
    if (_.isEmpty(this.props.wishes)) {
      return <React.Fragment>{this.getTraduction("nodata")}</React.Fragment>;
    } else {
      return (
        <div className="grid">
          {_.map(this.props.wishes, (wishWithBooked) => (
            <WishItem
              key={wishWithBooked.id}
              birthRegistry={this.props.birthRegistry}
              wish={wishWithBooked}
            ></WishItem>
          ))}
        </div>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(WishesList);
