/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";
import classNames from "classnames";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Col, Row, Typography } from "antd";
import { PageTitle } from "components/shared";
/****************** STYLING ******************/
import "./BBBLayout.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";

/****************** RENDERING (export) ******************/
type InputProps = {
  mode: "FULL" | "CENTER" | "2COL";
  title?: JSX.Element;
  titleSeo: string;
  onlyBottom?: boolean;
  loading: boolean;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;

type MapStateToProps = {};
type MapDispatchToProps = {};

type State = {};

class BBBLayout extends React.Component<Props, State> {
  state: State = {};
  /* LifeCycle Methods */

  /* Handlers methods */
  getChildByIndex(index: number): React.ReactNode {
    const fragments: React.ReactFragment[] = React.Children.toArray(this.props.children);
    if (fragments[index]) {
      return fragments[index];
    } else {
      return fragments;
    }
  }

  /* Render methods */
  renderPageTitle(className: string) {
    if (this.props.title) {
      return <PageTitle className={className} title={this.props.title}></PageTitle>;
    } else {
      return <PageTitle className={className} title={this.props.titleSeo}></PageTitle>;
    }
  }

  renderCardTitle() {
    if (typeof this.props.title === "string") {
      return (
        <Typography.Title className={"title"}>
          <FormattedMessage id={this.props.title} />
        </Typography.Title>
      );
    } else {
      return <Typography.Title className={"title"}>{this.props.title}</Typography.Title>;
    }
  }

  renderChildrenWhenLoaded(index?: number) {
    if (!this.props.loading) {
      if (index !== undefined) {
        return this.getChildByIndex(index);
      } else {
        return this.props.children;
      }
    }
  }

  titleSeo() {
    const { titleSeo, title } = this.props;
    if (title !== undefined) {
      return <title>&#128118;&#127996; {titleSeo}</title>;
    } else {
      return <title>&#128118;&#127996; {this.props.intl.formatMessage({ id: titleSeo })}</title>;
    }
  }

  render() {
    const { mode, onlyBottom, loading, children } = this.props;
    const layoutClass: string = classNames({
      layout: true,
      "layout-full": mode === "FULL",
      "layout-center": mode === "CENTER",
      "layout-2-col": mode === "2COL",
      withMargin: onlyBottom,
    });

    switch (mode) {
      case "FULL": {
        return (
          <>
            <Helmet>{this.titleSeo()}</Helmet>
            <div className={layoutClass}>
              {this.renderPageTitle(layoutClass)}
              {this.renderChildrenWhenLoaded()}
            </div>
          </>
        );
      }
      case "CENTER": {
        return (
          <>
            <Helmet>{this.titleSeo()}</Helmet>
            <div className={layoutClass}>
              <Row align="middle" justify="center">
                <Col xs={22} sm={20} lg={16} xl={12}>
                  <Card loading={loading}>
                    {this.renderCardTitle()}
                    {children}
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
      }
      case "2COL":
        return (
          <>
            <Helmet>{this.titleSeo()}</Helmet>
            <div className={layoutClass}>
              <Row gutter={[16, 16]} justify="space-around">
                <Col md={23} lg={15}>
                  {this.renderChildrenWhenLoaded(0)}
                </Col>
                <Col xs={23} lg={8}>
                  {this.renderChildrenWhenLoaded(1)}
                </Col>
              </Row>
              <Row justify="space-around" className="mt10">
                <Col span={24}>{this.renderChildrenWhenLoaded(2)}</Col>
              </Row>
            </div>
          </>
        );
      default: {
        //statements;
        break;
      }
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BBBLayout);
