/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as _ from "lodash";
import log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Popover, Card, Col, Row, Typography, Space, Popconfirm } from "antd";
import { BbbButton, Can } from "components/shared";
import logo from "../../../../assets/logo.svg";
import { DeleteOutlined, MoreOutlined, StarOutlined } from "@ant-design/icons";
/****************** STYLING ******************/
import "./WishItem.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { ObjectDeleted, WishWithBooked, Wish, BirthRegistry } from "bbb-api/dist/models";
import { prepareUrl } from "config/constants";
import { BBBRoutes } from "routes";
import { AbilityContext, wishApiFactory } from "config";
import { addNotification, addThread, deleteWishElement, removeThread } from "store/actions";
import { DEFAULT_FILTER_PRICE_MAX, SearchFields } from "store/reducers/categories";
import { subject } from "@casl/ability";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry: BirthRegistry;
  wish: WishWithBooked;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {
  filters?: SearchFields;
};

type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  deleteWish: (wish: Wish) => void;
};
type State = { loading: boolean; hidden: boolean };

class WishItem extends React.Component<Props, State> {
  state: State = { loading: false, hidden: false };
  static contextType = AbilityContext;
  context!: React.ContextType<typeof AbilityContext>;

  /* LifeCycle Methods */
  componentDidMount() {
    this.applyFilters();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.filters !== prevProps.filters) {
      this.applyFilters();
    }
  }
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `wishItem.${id}` }, param);
  };

  deleteElement = (): void => {
    this.setState({ loading: true });
    const wishApi = wishApiFactory();
    this.props.addThread("deleteWishApiV1WishWishIdDelete");
    wishApi
      .deleteWishApiV1WishWishIdDelete(this.props.wish.id)
      .then((response) => {
        const wishDeleted: ObjectDeleted = response.data;
        log.info(`Successfully delete /wish `, wishDeleted);
        this.props.addNotification({
          type: "success",
          description: "Voeu supprimé",
          title: "Succès",
        });
        this.props.deleteWish(this.props.wish);
      })
      .catch((error) => {
        log.error(`Error delete /wish`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de supprimer le voeu",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("deleteWishApiV1WishWishIdDelete");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  };

  applyFilters = () => {
    this.setState({ loading: true });
    const { filters, wish } = this.props;
    if (filters) {
      const { fullText, onlyBooked, onlyAvailable, priceMin, priceMax } = filters;
      const testText =
        _.isEmpty(fullText) ||
        (!_.isUndefined(fullText) && _.includes(wish.title?.toLowerCase(), fullText.toLowerCase()));
      const testBooked = !onlyBooked || (onlyBooked && wish.booked) || false;
      const testAvailable = !onlyAvailable || (onlyAvailable && wish.available) || false;
      const minPrice = !priceMin || !wish.price || wish.price >= priceMin;
      const maxPrice =
        !priceMax || !wish.price || priceMax === DEFAULT_FILTER_PRICE_MAX || wish.price <= priceMax;
      const hidden = !testBooked || !testText || !testAvailable || !minPrice || !maxPrice;
      log.trace("applyFilters", {
        hidden,
        testText,
        testBooked,
        testAvailable,
        minPrice,
        maxPrice,
      });
      this.setState((state: State) => ({
        ...state,
        loading: false,
        hidden: hidden,
      }));
    } else {
      this.setState((state: State) => ({
        ...state,
        loading: false,
        hidden: false,
      }));
    }
  };

  goToWishDetail(wish: WishWithBooked) {
    const manageThisList = this.context.can("manage", this.props.birthRegistry);
    log.debug("useAdminlink", manageThisList);
    if (manageThisList) {
      this.props.history.push(
        BBBRoutes.WISH_DETAIL.computePath({
          id: this.props.birthRegistry.id,
          wishId: wish.id,
        })
      );
    } else {
      this.props.history.push(
        BBBRoutes.WISH_DETAIL_BY_NANO_ID.computePath({
          id: _.first(this.props.birthRegistry.shortlinks)?.name,
          wishId: wish.id,
        })
      );
    }
  }

  /* Render methods */
  renderFavorite() {
    const { wish } = this.props;
    return wish.is_favorite ? (
      <Row className="favorite-banner" justify="center">
        <Col>
          <StarOutlined />
        </Col>
        <Col>
          <Typography.Text strong>
            <FormattedMessage id="wishItem.favoriteItem" />
          </Typography.Text>
        </Col>
      </Row>
    ) : (
      <Col>
        <StarOutlined className="hidden" />
      </Col>
    );
  }

  render() {
    const { wish } = this.props;
    return (
      <Col hidden={this.state.hidden}>
        <Card
          id={`wish${wish.id}`}
          className="wishItem"
          title={wish.title}
          loading={this.state.loading}
          extra={
            <Can I="manage" this={subject("BirthRegistry", this.props.birthRegistry)}>
              <Popover
                placement="bottom"
                content={
                  <div className="wishItemPop">
                    <BbbButton type="text" className="ml10">
                      <Link
                        to={BBBRoutes.WISH_EDIT.computePath({
                          id: this.props.birthRegistry.id,
                          wishId: wish.id,
                        })}
                      >
                        <FormattedMessage id="wishItem.edit" />
                      </Link>
                    </BbbButton>
                    <Popconfirm
                      icon={<DeleteOutlined className="red" />}
                      title={<FormattedMessage id="wishItem.delete.confirm" />}
                      okText={<FormattedMessage id="wishItem.delete.ok" />}
                      okType="danger"
                      cancelText={<FormattedMessage id="wishItem.delete.cancel" />}
                      onConfirm={this.deleteElement}
                    >
                      <BbbButton type="text" danger className="ml10">
                        <FormattedMessage id="wishItem.delete.action" />
                      </BbbButton>
                    </Popconfirm>
                  </div>
                }
                trigger="click"
              >
                <MoreOutlined />
              </Popover>
            </Can>
          }
        >
          <div
            className="body"
            onClick={() => {
              this.goToWishDetail(wish);
            }}
          >
            {this.renderFavorite()}
            <Space direction="vertical" className="description full-width" size={[0, 20]}>
              <figure className="figure">
                <picture className="picture">
                  <img
                    src={wish.img_path ? prepareUrl(wish.img_path) : logo}
                    className="logo"
                    alt="logo"
                  />
                </picture>
              </figure>
              <Row justify="end" className="price">
                <Typography.Paragraph strong>
                  {wish.price
                    ? `${this.getTraduction("price")}: ${wish.price} €`
                    : this.getTraduction("noprice")}
                </Typography.Paragraph>
              </Row>
            </Space>

            <div className="booked-banner">
              {wish.booked && (
                <>
                  {wish.available ? (
                    <FormattedMessage id="wishItem.remaining" />
                  ) : (
                    <FormattedMessage id="wishItem.booked" />
                  )}
                </>
              )}
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    filters: state.categories.filters,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    deleteWish: (wish: Wish) => dispatch(deleteWishElement(wish)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(WishItem);
