/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl } from "react-intl";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
/****************** STYLING ******************/

/****************** DEFINITIONS ******************/
import { Store, Theme, BBBLang } from "store/reducers";
import { changeLang } from "store/actions";
import { MenuInfo } from "rc-menu/lib/interface";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = { theme: Theme; lang: BBBLang };
type MapDispatchToProps = { changeLang: (lang: BBBLang) => void };
type State = {};

class LangSelector extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */
  handleClickMenu = (e: MenuInfo) => {
    log.debug("handleClickMenu ", e.key);
    const key = e.key;
    this.props.changeLang(key as BBBLang);
  };

  /* Render methods */
  render() {
    return (
      <Menu
        selectedKeys={[this.props.lang.toString()]}
        theme={this.props.theme}
        onClick={this.handleClickMenu}
        mode="horizontal"
      >
        <SubMenu title={this.props.lang}>
          <Menu.Item key="fr">fr</Menu.Item>
          <Menu.Item key="en">en</Menu.Item>
          <Menu.Item key="de">de</Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    theme: state.config.theme,
    lang: state.config.lang,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    changeLang: (lang: BBBLang) => dispatch(changeLang(lang)),
  };
}

export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(LangSelector);
