/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Collapse, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Can, WishesList } from "components/shared";
import { BbbButton } from "../buttons";
/****************** STYLING ******************/
import "./CategoriesList.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { BirthRegistry, Category, ObjectDeleted } from "bbb-api/dist/models";
import { categoryApiFactory } from "config";
import { addNotification, addThread, removeCategory, removeThread } from "store/actions";
import { subject } from "@casl/ability";

const { Panel } = Collapse;
/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry: BirthRegistry;
  categories: Category[];
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = { filterCategory?: number };
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  removeCategory: (category: Category) => void;
};
type State = { loading: boolean };

class CategoriesList extends React.Component<Props, State> {
  state: State = { loading: false };
  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `wishesList.${id}` }, param);
  };

  delegroup = (category: Category) => {
    this.setState({ loading: true });
    const categoryApi = categoryApiFactory();
    this.props.addThread("deleteCategoryApiV1CategoryCategoryIdDelete");
    categoryApi
      .deleteCategoryApiV1CategoryCategoryIdDelete(category.id)
      .then((response) => {
        const deleted: ObjectDeleted = response.data;
        log.info(`Successfully delete /categories/ `, deleted);
        this.props.removeCategory(category);
      })
      .catch((error) => {
        log.error(`Error delete /categories/ `, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de supprimer la catégorie",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("deleteCategoryApiV1CategoryCategoryIdDelete");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  };

  /* Render methods */
  renderDeleteButton(category: Category) {
    if (_.isEmpty(category.wishes)) {
      return (
        <Can I="manage" this={subject("BirthRegistry", this.props.birthRegistry)}>
          <Popconfirm
            icon={<DeleteOutlined className="red" />}
            title={<FormattedMessage id="wishesList.delete.confirm" />}
            okText={<FormattedMessage id="wishesList.delete.ok" />}
            okType="danger"
            cancelText={<FormattedMessage id="wishesList.delete.cancel" />}
            onConfirm={() => this.delegroup(category)}
            onCancel={(e) => e?.stopPropagation()}
          >
            <BbbButton key="primary" danger type="dashed" onClick={(e) => e.stopPropagation()}>
              <FormattedMessage id="wishesList.delete.action"></FormattedMessage>
            </BbbButton>
          </Popconfirm>
        </Can>
      );
    }
  }
  renderPanels(): JSX.Element[] {
    return this.props.categories.map((category) => {
      if (
        !this.props.filterCategory ||
        this.props.filterCategory === 0 || // All case
        this.props.filterCategory === category.id
      ) {
        return (
          <Panel
            header={category.label}
            key={category.id}
            className={`category-panel-${category.label}`}
            extra={this.renderDeleteButton(category)}
          >
            <WishesList
              birthRegistry={this.props.birthRegistry}
              wishes={category.wishes ?? []}
            ></WishesList>
          </Panel>
        );
      } else {
        return <React.Fragment key={0}></React.Fragment>;
      }
    });
  }

  render() {
    if (_.isEmpty(this.props.categories)) {
      return <React.Fragment>{this.getTraduction("nodata")}</React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <Collapse
            ghost={true}
            bordered={false}
            className={`category-collapse`}
            defaultActiveKey={this.props.categories.map((categ) => categ.id)}
          >
            {this.renderPanels()}
          </Collapse>
        </React.Fragment>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    filterCategory: state.categories.filters?.category,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    removeCategory: (category: Category) => dispatch(removeCategory(category)),
  };
}

export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(CategoriesList);
