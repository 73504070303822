/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

import { passwordApiFactory } from "config/bbbApiFactory";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Col, Form, Input, Row, Spin } from "antd";
/****************** TYPES ******************/
import { Store, Notification } from "store/reducers";
import { addNotification, addThread, removeThread } from "store/actions";
import { BbbButton, BBBLayout } from "components/shared";
/****************** STYLING ******************/
import "./Forget.less";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/

type InputProps = {};
type RouteParams = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type State = {
  loading: boolean;
  mail?: string;
  valid: boolean;
};

class Forget extends React.Component<Props, State> {
  state: State = {
    loading: false,
    mail: undefined,
    valid: false,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    // const { match } = this.props;
  }
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `forget.${id}` }, param);
  };

  handleChange = (field: "mail", event: any) => {
    log.info("handleChange", { field, event });
    const value = event.target.value;
    this.setState((state: State) => {
      const newState: State = { ...state, [field]: value };
      return { ...newState, valid: !_.isEmpty(newState.mail) };
    });
  };

  sendForgetRequest = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    log.info("Start sendForgetRequest");
    event.preventDefault();
    if (this.state.mail) {
      this.setState((state: State) => ({ ...state, loading: true }));
      const passwordApi = passwordApiFactory();
      this.props.addThread("requestPasswordResetApiV1PasswordResetRequestResetPost");
      passwordApi
        .requestPasswordResetApiV1PasswordResetRequestResetPost(this.state.mail)
        .then((response) => {
          log.info(`Successfully ask reset for mail ${this.state.mail}`);
          this.props.addNotification({
            type: "success",
            description: "Email envoyé",
            title: "Succès",
          });
          this.props.history.push(BBBRoutes.LOGIN.path);
        })
        .catch((error) => {
          log.error(`Error asking reset passowrd for mail ${this.state.mail}`, error);
          this.props.addNotification({
            type: "error",
            description: "Erreur lors de l'opération",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("requestPasswordResetApiV1PasswordResetRequestResetPost");
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  };

  /* Render methods */
  spinnerOrMessage() {
    if (this.state.loading) {
      return <Spin size="large" delay={300} className="progress" spinning={true}></Spin>;
    } else {
      return (
        <React.Fragment>
          <Form layout="vertical" labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} name="basic">
            <div className="page">
              <Form.Item label={this.getTraduction("mail")} required={true}>
                <Input
                  disabled={this.state.loading}
                  value={this.state.mail}
                  onChange={(event) => this.handleChange("mail", event)}
                />
              </Form.Item>
              <Row justify="space-around">
                <Col>
                  <BbbButton
                    type="text"
                    disabled={this.state.loading || !this.state.valid}
                    onClick={(e) => this.sendForgetRequest(e)}
                  >
                    <FormattedMessage id="login.forget" />
                  </BbbButton>
                </Col>
              </Row>
            </div>
          </Form>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <BBBLayout mode="CENTER" titleSeo="forget.title" loading={this.state.loading}>
        {this.spinnerOrMessage()}
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Forget);
