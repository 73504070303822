import { Wish } from "bbb-api";
import { BasketAction } from "store/actions";
import { BASKET_ADD, BASKET_REMOVE, BASKET_CLEAN } from "store/constants";

import * as _ from "lodash";

export type BasketItem = {
  birthRegistryId: number;
  birthRegistryTitle?: string;
  owner?: string;
  wish: Wish;
};

export type SubBasketList = {
  birthRegistryId: number;
  birthRegistryTitle?: string;
  wishes: Wish[];
  total: number;
};
export interface StateBasket {
  subList: SubBasketList[];
  basketSize: number;
  superTotal: number;
}

export const initialStateBasket: StateBasket = {
  subList: [],
  basketSize: 0,
  superTotal: 0,
};

export function basketReducer(
  state: StateBasket = initialStateBasket,
  action: BasketAction
): StateBasket {
  switch (action.type) {
    case BASKET_ADD: {
      const goodSubList = _.find(
        state.subList,
        (sub) => sub.birthRegistryId === action.payload.birthRegistryId
      );
      const subListUpdated = goodSubList
        ? _.map(state.subList, (sub) => {
            if (sub.birthRegistryId === goodSubList.birthRegistryId) {
              return {
                ...sub,
                total: sub.total + (action.payload.wish.price ? action.payload.wish.price : 0),
                wishes: [...sub.wishes, action.payload.wish],
              };
            } else {
              return sub;
            }
          })
        : [
            ...state.subList,
            {
              birthRegistryId: action.payload.birthRegistryId,
              birthRegistryTitle: action.payload.birthRegistryTitle,
              wishes: [action.payload.wish],
              total: action.payload.wish.price ? action.payload.wish.price : 0,
            },
          ];
      return {
        ...state,
        subList: subListUpdated,
        basketSize: state.basketSize + 1,
        superTotal: state.superTotal + (action.payload.wish.price ? action.payload.wish.price : 0),
      };
    }
    case BASKET_REMOVE: {
      const subListUpdated = _.map(state.subList, (sub) => {
        if (sub.birthRegistryId === action.payload.birthRegistryId) {
          const remainings = _.filter(sub.wishes, (wish) => wish.id !== action.payload.wish.id);
          if (_.size(remainings) > 0) {
            return {
              ...sub,
              total: sub.total - (action.payload.wish.price ? action.payload.wish.price : 0),
              wishes: remainings,
            };
          }
        } else {
          return sub;
        }
      });
      const cleanedSubLit: SubBasketList[] = subListUpdated.filter(
        (item): item is SubBasketList => !!item
      );
      return {
        ...state,
        subList: cleanedSubLit,
        basketSize: state.basketSize - 1,
        superTotal: state.superTotal - (action.payload.wish.price ? action.payload.wish.price : 0),
      };
    }
    case BASKET_CLEAN: {
      return initialStateBasket;
    }
    default:
      return state;
  }
}
