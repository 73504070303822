/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Row, Col, List } from "antd";
import { ExclamationCircleOutlined, MailOutlined, SmileOutlined } from "@ant-design/icons";
import stars from "../../assets/image-footer.svg";

/****************** STYLING ******************/
import "./Footer.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class Footer extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */

  /* Render methods */

  render() {
    return (
      <React.Fragment>
        <div className="App-footer">
          <Row align="top" justify="space-around" wrap={false}>
            <Col xs={8} md={6} lg={5} xl={4} xxl={4} className="links">
              <List split={false}>
                <List.Item>
                  <Link to={BBBRoutes.CONTACT.path}>
                    <MailOutlined />
                    <FormattedMessage id="footer.contact" />
                  </Link>
                </List.Item>
                <List.Item>
                  <Link to={BBBRoutes.LEGAL.path}>
                    <ExclamationCircleOutlined />
                    <FormattedMessage id="footer.legal" />
                  </Link>
                </List.Item>
                <Col md={0}>
                  <List.Item>
                    <Link to={BBBRoutes.WHO.path}>
                      <SmileOutlined />
                      <FormattedMessage id="footer.who" />
                    </Link>
                  </List.Item>
                </Col>
              </List>
            </Col>
            <Col xs={0} md={5} lg={4} xl={4} xxl={4} className="links">
              <List split={false}>
                <List.Item>
                  <Link to={BBBRoutes.WHO.path}>
                    <SmileOutlined />
                    <FormattedMessage id="footer.who" />
                  </Link>
                </List.Item>
              </List>
            </Col>
            <Col xs={14} md={8} lg={7} xl={6} xxl={8} pull={2} className="welcome">
              <Link to={BBBRoutes.HOME.path}>
                <FormattedMessage id="footer.welcome" />
              </Link>
            </Col>
            <img src={stars} className="app-image" alt="logo" />
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Footer);
