/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Table, Row, Col, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import BabyFormModal from "./BabyFormModal";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
import "./BabiesList.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Baby, ObjectDeleted } from "bbb-api/dist/models";
import { TableRowSelection, ColumnsType } from "antd/lib/table/interface";
import { babyApiFactory } from "config";
import { addNotification, addThread, removeThread } from "store/actions";

/****************** RENDERING (export) ******************/
type InputProps = {
  mode?: "MY" | "ADMIN";
  secondaryActions?: JSX.Element;
  birthRegistryId?: number;
  babies: Baby[];
  addElement(baby: Baby): void;
  updateElement(baby: Baby): void;
  deleteElement(baby: ObjectDeleted): void;
};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = { loading: boolean; displayForm: boolean; selectedElement?: Baby };

class BabiesList extends React.Component<Props, State> {
  static defaultProps: Pick<Props, "mode"> = {
    mode: "MY",
  };
  state: State = { loading: false, displayForm: false, selectedElement: undefined };

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `babiesList.${id}` }, param);
  };

  displayFormBaby = (): void => {
    this.setState((state: State) => ({ ...state, displayForm: true }));
  };

  edit = (record: Baby): void => {
    this.setState((state: State) => ({ ...state, displayForm: true, selectedElement: record }));
  };

  delete = (record: Baby): void => {
    const babyApi = babyApiFactory();
    this.props.addThread("deleteBabyApiV1BabyBabyIdDelete");
    babyApi
      .deleteBabyApiV1BabyBabyIdDelete(record.id)
      .then((response) => {
        const baby: ObjectDeleted = response.data;
        log.info(`Successfully delete /baby/${record.id} `, baby);
        this.props.deleteElement(baby);
      })
      .catch((error) => {
        log.error(`Error deleting /baby/${record.id}`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de supprimer le bébé de la liste de naissance",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("deleteBabyApiV1BabyBabyIdDelete");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
    this.setState((state: State) => ({ ...state, displayForm: false, selectedElement: undefined }));
  };

  addElement = (baby: Baby): void => {
    this.props.addElement(baby);
    this.setState((state: State) => ({ ...state, displayForm: false }));
  };

  updateElement = (baby: Baby): void => {
    this.props.updateElement(baby);
    this.setState((state: State) => ({ ...state, displayForm: false }));
  };

  cancel = (): void => {
    this.setState((state: State) => ({ ...state, displayForm: false, selectedElement: undefined }));
  };

  /* Render methods */
  renderList() {
    if (_.isEmpty(this.props.babies)) {
      return (
        <React.Fragment>
          <span>{this.getTraduction("nodata")}</span>
        </React.Fragment>
      );
    } else {
      const columns: ColumnsType<Baby> = [
        {
          title: this.getTraduction("columns.firstname"),
          dataIndex: "firstname",
        },
        {
          title: this.getTraduction("columns.firstname_is_private"),
          dataIndex: "firstname_is_private",
          render: (value: any, record: Baby, index: number) => {
            return <React.Fragment>{record.firstname_is_private?.toString()}</React.Fragment>;
          },
        },
        {
          title: this.getTraduction("columns.gender"),
          dataIndex: "gender",
        },
        {
          title: this.getTraduction("columns.gender_is_private"),
          dataIndex: "gender_is_private",
          render: (value: any, record: Baby, index: number) => {
            return <React.Fragment>{record.gender_is_private?.toString()}</React.Fragment>;
          },
        },
        {
          title: this.getTraduction("columns.actions"),
          dataIndex: "actions",
          render: (value: any, record: Baby, index: number) => {
            return (
              <Row>
                <BbbButton onClick={(e) => this.edit(record)}>
                  <FormattedMessage id="babiesList.edit" />
                </BbbButton>
                <Popconfirm
                  icon={<DeleteOutlined className="red" />}
                  title={<FormattedMessage id="babiesList.delete.confirm" />}
                  okText={<FormattedMessage id="babiesList.delete.ok" />}
                  okType="danger"
                  cancelText={<FormattedMessage id="babiesList.delete.cancel" />}
                  onConfirm={(e) => this.delete(record)}
                >
                  <BbbButton type="dashed" className="ml10" danger>
                    <FormattedMessage id="babiesList.delete.action" />
                  </BbbButton>
                </Popconfirm>
              </Row>
            );
          },
        },
      ];
      const rowSelection: TableRowSelection<Baby> = {
        selectedRowKeys: this.state.selectedElement ? [this.state.selectedElement.id] : undefined,
        hideSelectAll: true,
        renderCell: () => {
          return null;
        },
      };
      return (
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          dataSource={this.props.babies}
          columns={columns}
        />
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card
          title={
            <Row align="middle" justify="space-between">
              <Col span={4}>
                <FormattedMessage id={`babiesList.title.${this.props.mode}`} />
              </Col>
              <Col span={15}>
                <Row align="middle" justify="end">
                  <Col span={4}>
                    {this.props.birthRegistryId ? (
                      <BbbButton key="primary" type="primary" onClick={this.displayFormBaby}>
                        <FormattedMessage id="babiesList.add" />
                      </BbbButton>
                    ) : null}
                  </Col>
                  {this.props.secondaryActions ? this.props.secondaryActions : undefined}
                </Row>
              </Col>
            </Row>
          }
          loading={this.state.loading}
        >
          {this.renderList()}
        </Card>
        {this.props.birthRegistryId ? (
          <BabyFormModal
            birthRegistryId={this.props.birthRegistryId}
            visible={this.state.displayForm}
            addElement={this.addElement}
            updateElement={this.updateElement}
            cancel={this.cancel}
            selectedElement={this.state.selectedElement}
          ></BabyFormModal>
        ) : null}
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BabiesList);
