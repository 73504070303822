import * as constants from "store/constants";
import { RunningMode, Theme, Notification, BBBLang } from "store/reducers";

export type ConfigurationAction =
  | ChangeModeType
  | ChangeLangType
  | ChangeThemeType
  | AddThreadType
  | RemoveThreadType
  | UpdateThreadType
  | AddNotificationType
  | RemoveNotificationType;

export interface ChangeModeType {
  type: constants.CHANGE_MODE;
  payload: RunningMode;
}

export function changeMode(mode: RunningMode): ChangeModeType {
  return {
    type: constants.CHANGE_MODE,
    payload: mode,
  };
}

export interface ChangeLangType {
  type: constants.CHANGE_LANG;
  payload: BBBLang;
}

export function changeLang(lang: BBBLang): ChangeLangType {
  return {
    type: constants.CHANGE_LANG,
    payload: lang,
  };
}

export interface ChangeThemeType {
  type: constants.CHANGE_THEME;
  payload: Theme;
}

export function changeTheme(theme: Theme): ChangeThemeType {
  return {
    type: constants.CHANGE_THEME,
    payload: theme,
  };
}

export interface AddThreadType {
  type: constants.ADD_THREAD;
  payload: string;
}

export function addThread(threadKey: string): AddThreadType {
  return {
    type: constants.ADD_THREAD,
    payload: threadKey,
  };
}

export interface RemoveThreadType {
  type: constants.REMOVE_THREAD;
  payload: string;
}

export function removeThread(threadKey: string): RemoveThreadType {
  return {
    type: constants.REMOVE_THREAD,
    payload: threadKey,
  };
}

export interface UpdateThreadType {
  type: constants.UPDATE_THREAD;
  payload: { threadKey: string; progress: number };
}

export function updateThread(threadKey: string, progress: number): UpdateThreadType {
  return {
    type: constants.UPDATE_THREAD,
    payload: { threadKey, progress },
  };
}

export interface AddNotificationType {
  type: constants.ADD_NOTIFICATION;
  payload: Notification;
}

export function addNotification(notification: Notification): AddNotificationType {
  return {
    type: constants.ADD_NOTIFICATION,
    payload: notification,
  };
}

export interface RemoveNotificationType {
  type: constants.REMOVE_NOTIFICATION;
  payload: string;
}

export function removeNotification(notifKey: string): RemoveNotificationType {
  return {
    type: constants.REMOVE_NOTIFICATION,
    payload: notifKey,
  };
}
