/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as _ from "lodash";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Col, Row, Tooltip, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
import "./WishDetailHeader.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry, WishPublic, WishWithBookings } from "bbb-api";
import { BBBRoutes } from "routes";
/****************** RENDERING (export) ******************/
type InputProps = {
  wish: WishPublic | WishWithBookings;
  birthRegistry?: BirthRegistry;
  manageThisList: boolean;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class WishDetailHeader extends React.Component<Props, State> {
  state: State = {};
  /* LifeCycle Methods */
  /* Handlers methods */

  /* Render methods */
  renderBackToList() {
    const { wish, birthRegistry, manageThisList } = this.props;
    if (birthRegistry) {
      if (manageThisList) {
        return (
          <HashLink
            to={BBBRoutes.BIRTHREGISTRY_DETAIL_BY_ID.computePath({
              id: birthRegistry.id,
              wishId: wish.id,
            })}
            smooth={true}
          >
            <BbbButton>
              <FormattedMessage id="wishDetail.backToList"></FormattedMessage>
            </BbbButton>
          </HashLink>
        );
      } else {
        return (
          <HashLink
            to={BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID.computePath({
              nano: _.first(birthRegistry.shortlinks)?.name,
              wishId: wish.id,
            })}
            smooth={true}
          >
            <BbbButton>
              <FormattedMessage id="wishDetail.backToList"></FormattedMessage>
            </BbbButton>
          </HashLink>
        );
      }
    } else {
      return undefined;
    }
  }

  renderEditButton() {
    const { wish, birthRegistry, manageThisList } = this.props;
    if (birthRegistry && manageThisList) {
      return (
        <Tooltip title={<FormattedMessage id="wishItem.edit" />}>
          <Link
            className="icon"
            to={BBBRoutes.WISH_EDIT.computePath({
              id: birthRegistry.id,
              wishId: wish.id,
            })}
          >
            <EditOutlined className="ml10" />
          </Link>
        </Tooltip>
      );
    } else {
      return undefined;
    }
  }

  render() {
    const { wish } = this.props;
    return (
      <React.Fragment>
        <Row className="wishDetailHeader" align="middle" justify="space-between" gutter={[15, 15]}>
          <Col>
            <Row align="top">
              <Col>{this.renderBackToList()}</Col>
              <Col>{this.renderEditButton()}</Col>
            </Row>
          </Col>

          {wish.link && (
            <Col>
              <FormattedMessage id="wishDetail.link"></FormattedMessage>
              <Typography.Text> : </Typography.Text>
              <a href={wish.affiliate_link ?? wish.link} target="_blank" rel="noreferrer">
                {_.size(wish.link) > 70 ? `${wish.link.substring(0, 70)}...` : wish.link}
              </a>
            </Col>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WishDetailHeader);
