/* eslint-disable @typescript-eslint/no-redeclare */
/** CONFIGURATION */
export const CHANGE_MODE = "CONFIGURATION/CHANGE_MODE";
export type CHANGE_MODE = typeof CHANGE_MODE;

export const CHANGE_LANG = "CONFIGURATION/CHANGE_LANG";
export type CHANGE_LANG = typeof CHANGE_LANG;

export const CHANGE_THEME = "CONFIGURATION/CHANGE_THEME";
export type CHANGE_THEME = typeof CHANGE_THEME;

export const ADD_THREAD = "CONFIGURATION/ADD_THREAD";
export type ADD_THREAD = typeof ADD_THREAD;

export const REMOVE_THREAD = "CONFIGURATION/REMOVE_THREAD";
export type REMOVE_THREAD = typeof REMOVE_THREAD;

export const UPDATE_THREAD = "CONFIGURATION/UPDATE_THREAD";
export type UPDATE_THREAD = typeof UPDATE_THREAD;

export const ADD_NOTIFICATION = "CONFIGURATION/ADD_NOTIFICATION";
export type ADD_NOTIFICATION = typeof ADD_NOTIFICATION;

export const REMOVE_NOTIFICATION = "CONFIGURATION/REMOVE_NOTIFICATION";
export type REMOVE_NOTIFICATION = typeof REMOVE_NOTIFICATION;

export const LOGIN = "USER/LOGIN";
export type LOGIN = typeof LOGIN;

export const SET_USER = "USER/SET_USER";
export type SET_USER = typeof SET_USER;

/** BIRTHREGISTRIES */
export const BIRTH_REGISTRY_ADD = "BIRTH_REGISTRY/ADD";
export type BIRTH_REGISTRY_ADD = typeof BIRTH_REGISTRY_ADD;

export const BIRTH_REGISTRY_UPDATE = "BIRTH_REGISTRY/UPDATE";
export type BIRTH_REGISTRY_UPDATE = typeof BIRTH_REGISTRY_UPDATE;

export const BIRTH_REGISTRY_DELETE = "BIRTH_REGISTRY/DELETE";
export type BIRTH_REGISTRY_DELETE = typeof BIRTH_REGISTRY_DELETE;

export const BIRTH_REGISTRY_ADMIN_DELETE = "BIRTH_REGISTRY_ADMIN/DELETE";
export type BIRTH_REGISTRY_ADMIN_DELETE = typeof BIRTH_REGISTRY_ADMIN_DELETE;

export const BIRTH_REGISTRY_SET = "BIRTH_REGISTRY/SET";
export type BIRTH_REGISTRY_SET = typeof BIRTH_REGISTRY_SET;

export const BIRTH_REGISTRY_ADMIN_SET = "BIRTH_REGISTRY_ADMIN/SET";
export type BIRTH_REGISTRY_ADMIN_SET = typeof BIRTH_REGISTRY_ADMIN_SET;

/** WISHES  */
export const WISH_ADD = "WISH/ADD";
export type WISH_ADD = typeof WISH_ADD;

export const WISH_UPDATE = "WISH/UPDATE";
export type WISH_UPDATE = typeof WISH_UPDATE;

export const WISH_DELETE = "WISH/DELETE";
export type WISH_DELETE = typeof WISH_DELETE;

export const SEARCHFIELDS_SET = "WISH/SET_SEARCHFIELDS";
export type SEARCHFIELDS_SET = typeof SEARCHFIELDS_SET;

/** CATEGORIES */
export const CATEGORY_SET = "CATEGORY/SET";
export type CATEGORY_SET = typeof CATEGORY_SET;

export const CATEGORY_ADD = "CATEGORY/ADD";
export type CATEGORY_ADD = typeof CATEGORY_ADD;

export const CATEGORY_DELETE = "CATEGORY/DELETE";
export type CATEGORY_DELETE = typeof CATEGORY_DELETE;

/** BASKET */
export const BASKET_ADD = "BASKET/ADD";
export type BASKET_ADD = typeof BASKET_ADD;

export const BASKET_REMOVE = "BASKET/REMOVE";
export type BASKET_REMOVE = typeof BASKET_REMOVE;

export const BASKET_CLEAN = "BASKET/CLEAN";
export type BASKET_CLEAN = typeof BASKET_CLEAN;
