/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Form, Upload } from "antd";
import { BbbButton } from "../buttons";
import { UploadOutlined } from "@ant-design/icons";
/****************** STYLING ******************/

/****************** DEFINITIONS ******************/
import { addThread, removeThread, addNotification } from "store/actions";
import { Store, Notification } from "store/reducers";
import { s3ApiFactory } from "config";
import { UploadRequestOption, UploadRequestError, RcFile } from "rc-upload/lib/interface";
import { AxiosResponse } from "axios";
import { UploadChangeParam, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { S3BucketName, S3Image } from "bbb-api/dist/models";

/****************** RENDERING (export) ******************/
type InputProps = Omit<UploadProps, "fileList" | "defaultFileList"> & {
  defaultFileList?: PartialUpload[];
  suggestedFileList?: UploadProps["fileList"];
  bucket: S3BucketName;
  includeForm: boolean;
  label?: string;
  button_add?: string;
  button_edit?: string;
  handleUploadChange(file?: RcFile, image_path?: string, thumbnail_path?: string): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };

type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

export type PartialUpload = {
  status: string;
  url: string;
  thumbUrl: string;
};
type State = { fileList: Array<UploadFile<any>>; countDoc: number; mode: "EDIT" | "ADD" };

class BbbUpload extends React.Component<Props, State> {
  state: State = { fileList: [], countDoc: 0, mode: "ADD" };
  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.defaultFileList && _.size(this.props.defaultFileList) > 0) {
      this.setState({
        countDoc: this.props.defaultFileList.length,
        mode: this.editCondition(this.props.defaultFileList.length) ? "EDIT" : "ADD",
        fileList: this.props.defaultFileList as any[],
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      this.props.suggestedFileList &&
      prevProps.suggestedFileList !== this.props.suggestedFileList
    ) {
      const { suggestedFileList } = this.props;
      this.setState((state) => ({ ...state, fileList: suggestedFileList }));
    }
  }

  /* Handlers methods */
  editCondition(countDoc: number): boolean {
    return Boolean(this.props.maxCount && countDoc >= this.props.maxCount);
  }

  handleUploadRequest = (options: UploadRequestOption<any>): void => {
    log.info("handleUploadRequest", options);
    const { onSuccess, onError, file, onProgress } = options;
    if (onSuccess && onError && onProgress) {
      const s3Api = s3ApiFactory();
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: ProgressEvent) => {
          log.trace(`progress: ${(event.loaded / event.total) * 100}`);
          onProgress({ percent: (event.loaded / event.total) * 100, ...event });
        },
      };
      this.props.addThread("uploadFileApiV1S3UploadBucketNamePost");
      s3Api
        .uploadFileApiV1S3UploadBucketNamePost(this.props.bucket, file, config)
        .then((response: AxiosResponse<S3Image>) => {
          onSuccess(response.data, new XMLHttpRequest());
          this.setState((state: State) => {
            const newCount = state.countDoc + 1;
            return {
              countDoc: newCount,
              mode: this.editCondition(newCount) ? "EDIT" : "ADD",
            };
          });
          this.props.handleUploadChange(
            file,
            response.data.image_path,
            response.data.thumbnail_path
          );
        })
        .catch((error) => {
          log.error(`Error uploading image`, error);
          const uploadRequestError: UploadRequestError = {
            name: error.code,
            message: error.message,
            status: error.response?.status || 400,
          };
          onError(uploadRequestError);
        })
        .finally(() => {
          this.props.removeThread("uploadFileApiV1S3UploadBucketNamePost");
        });
    }
  };

  handleRemoveFile: (file: UploadFile<any>) => boolean = (file) => {
    this.setState((state: State) => {
      const newCount = state.countDoc - 1;
      return {
        countDoc: newCount,
        mode: this.editCondition(newCount) ? "EDIT" : "ADD",
      };
    });

    this.props.handleUploadChange(undefined, "", "");
    return true;
  };

  handleChange: (change: UploadChangeParam<UploadFile<any>>) => void = (change) => {
    log.trace("handleChange", change);
    let newFileList = [...change.fileList];

    // 1. Limit the number of uploaded files
    // Only to show recent uploaded files, and old ones will be replaced by the new
    if (this.props.maxCount) {
      newFileList = newFileList.slice(-this.props.maxCount);
    }

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.setState((state) => ({ ...state, fileList: newFileList }));
  };

  /* Render methods */
  get label() {
    if (this.props.label) {
      return this.props.label;
    } else {
      return <FormattedMessage id="bbbUpload.label"></FormattedMessage>;
    }
  }

  buttonLabel() {
    if (this.editCondition(this.state.countDoc)) {
      return this.buttonEditLabel();
    } else {
      return this.buttonAddLabel();
    }
  }

  buttonAddLabel() {
    if (this.props.button_add) {
      return this.props.button_add;
    } else {
      return <FormattedMessage id="bbbUpload.add"></FormattedMessage>;
    }
  }

  buttonEditLabel() {
    if (this.props.button_edit) {
      return this.props.button_edit;
    } else {
      return <FormattedMessage id="bbbUpload.edit"></FormattedMessage>;
    }
  }
  renderUpload() {
    const {
      defaultFileList,
      bucket,
      includeForm,
      label,
      button_add,
      button_edit,
      handleUploadChange,
      ...others
    } = this.props;
    return (
      <Upload
        customRequest={this.handleUploadRequest}
        onRemove={this.handleRemoveFile}
        onChange={this.handleChange}
        fileList={this.state.fileList}
        {...others}
      >
        <BbbButton icon={<UploadOutlined />}>&nbsp;{this.buttonLabel()}</BbbButton>
      </Upload>
    );
  }

  render() {
    if (this.props.includeForm) {
      return (
        <Form.Item labelCol={{ span: 24 }} label={this.label} colon={false} valuePropName="file">
          {this.renderUpload()}
        </Form.Item>
      );
    } else {
      return this.renderUpload();
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BbbUpload);
