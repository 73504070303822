/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Row, Col, Image } from "antd";
/****************** STYLING ******************/
import "./HelpArticleStep.less";
import logo from "../../../assets/logo.svg";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { prepareUrl } from "config/constants";

/****************** RENDERING (export) ******************/
type InputProps = {
  stepNumber?: number;
  textContent?: string;
  s3BaseUrl?: string;
  imgPath?: string;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class HelpArticleStep extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */
  render() {
    return (
      <Row gutter={16} className="helpArticleStep">
        <Col span={1}>{this.props.stepNumber}</Col>
        <Col span={15}>{this.props.textContent}</Col>
        <Col span={8}>
          <Image
            src={
              this.props.imgPath && this.props.s3BaseUrl
                ? prepareUrl(this.props.s3BaseUrl.concat(this.props.imgPath))
                : logo
            }
            className="logo"
            alt="logo"
          />
        </Col>
      </Row>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HelpArticleStep);
