/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BbbButton } from "components/shared";
import { Col, Row } from "antd";
/****************** TYPES ******************/
import { Store } from "store/reducers";
import { VisitorType } from "./Help";
/****************** STYLING ******************/

/****************** RENDERING (export) ******************/

type InputProps = {
  setVisitorType: (visitorType: VisitorType) => void;
};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};

type State = {};

class HelpUnknown extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */
  switchVisitorType = (visitorType: VisitorType): void => {
    this.props.setVisitorType(visitorType);
  };

  /* Render methods */
  render() {
    return (
      <Row gutter={[20, 20]} wrap={true}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Row justify="center">
            <BbbButton type="primary" size="large" onClick={() => this.switchVisitorType("PARENT")}>
              <FormattedMessage id="help.parent"></FormattedMessage>
            </BbbButton>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Row justify="center">
            <BbbButton
              type="primary"
              size="large"
              onClick={(ee) => this.switchVisitorType("VISITOR")}
            >
              <FormattedMessage id="help.visitor"></FormattedMessage>
            </BbbButton>
          </Row>
        </Col>
      </Row>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(HelpUnknown);
