export type RouteName =
  | "HOME"
  | "CONTACT"
  | "HELP"
  | "WHO"
  | "LEGAL"
  | "LOGIN"
  | "FORGET"
  | "RESET_PASSWORD"
  | "REGISTER"
  | "ACTIVATE"
  | "ADMIN_BIRTHREGISTRIES"
  | "ADMIN_BABIES"
  | "BIRTHREGISTRY_NEW"
  | "BIRTHREGISTRY_EDIT"
  | "WISH_NEW"
  | "WISH_EDIT"
  | "WISH_DETAIL"
  | "WISH_DETAIL_BY_NANO_ID"
  | "BIRTHREGISTRY_DETAIL_BY_ID"
  | "BIRTHREGISTRY_DETAIL_BY_NANO_ID"
  | "BIRTHREGISTRY_DETAIL_BY_NANO_ID_FR"
  | "BIRTHREGISTRY_DETAIL_BY_NANO_ID_SHORT"
  | "USER_BIRTHREGISTRIES"
  | "BASKET"
  | "SUMMARY";

export type BBBRouteDefinition = {
  path: string;
  secured: boolean;
  computePath: (params: any) => string;
};

function withDefault(partial: Omit<BBBRouteDefinition, "computePath">): BBBRouteDefinition {
  return { ...partial, computePath: () => partial.path };
}

export const BBBRoutes: { [key in RouteName]: BBBRouteDefinition } = {
  HOME: withDefault({ path: "/", secured: false }),
  CONTACT: withDefault({ path: "/contact", secured: false }),
  HELP: withDefault({ path: "/help", secured: false }),
  WHO: withDefault({ path: "/who", secured: false }),
  LEGAL: withDefault({ path: "/legal", secured: false }),
  LOGIN: withDefault({ path: "/login", secured: false }),
  FORGET: withDefault({ path: "/forget", secured: false }),
  RESET_PASSWORD: withDefault({ path: "/reset-password/:token", secured: false }),
  REGISTER: withDefault({ path: "/register", secured: false }),
  ACTIVATE: withDefault({ path: "/activate/:userid/:token", secured: false }),
  ADMIN_BIRTHREGISTRIES: withDefault({ path: "/admin/list", secured: true }),
  ADMIN_BABIES: withDefault({ path: "/admin/babies", secured: true }),
  BIRTHREGISTRY_NEW: withDefault({ path: "/list/new", secured: true }),
  BIRTHREGISTRY_EDIT: {
    path: "/list/edit/:id",
    secured: true,
    computePath: (id: number) => `/list/edit/${id}`,
  },
  WISH_NEW: {
    path: "/list/:id/wish/new",
    secured: true,
    computePath: (id: number) => `/list/${id}/wish/new`,
  },
  WISH_EDIT: {
    path: "/list/:id/wish/edit/:wishId",
    secured: true,
    computePath: (param: { id: number; wishId: number }) =>
      `/list/${param.id}/wish/edit/${param.wishId}`,
  },
  WISH_DETAIL: {
    path: "/list/:id/wish/:wishId",
    secured: true,
    computePath: (param: { id: number; wishId: number }) =>
      `/list/${param.id}/wish/${param.wishId}`,
  },
  WISH_DETAIL_BY_NANO_ID: {
    path: "/my-list/:id/wish/:wishId",
    secured: false,
    computePath: (param: { id: string; wishId: number }) =>
      `/my-list/${param.id}/wish/${param.wishId}`,
  },
  BIRTHREGISTRY_DETAIL_BY_ID: {
    path: "/list/:id",
    secured: true,
    computePath: (param: { id: number; wishId?: number }) =>
      param.wishId ? `/list/${param.id}#wish${param.wishId}` : `/list/${param.id}`,
  },
  BIRTHREGISTRY_DETAIL_BY_NANO_ID: {
    path: "/my-list/:id",
    secured: false,
    computePath: (param: { nano: string; wishId: number }) =>
      param.wishId ? `/my-list/${param.nano}#wish${param.wishId}` : `/my-list/${param.nano}`,
  },
  BIRTHREGISTRY_DETAIL_BY_NANO_ID_FR: {
    path: "/ma-liste/:id",
    secured: false,
    computePath: (param: { nano: string; wishId: number }) =>
      param.wishId ? `/ma-liste/${param.nano}#wish${param.wishId}` : `/ma-liste/${param.nano}`,
  },
  BIRTHREGISTRY_DETAIL_BY_NANO_ID_SHORT: {
    path: "/l/:id",
    secured: false,
    computePath: (param: { nano: string; wishId: number }) =>
      param.wishId ? `/l/${param.nano}#wish${param.wishId}` : `/l/${param.nano}`,
  },
  USER_BIRTHREGISTRIES: withDefault({ path: "/list", secured: true }),
  BASKET: withDefault({
    path: "/cart",
    secured: false,
  }),
  SUMMARY: withDefault({
    path: "/summary",
    secured: false,
  }),
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BBBRoutes = keyof typeof BBBRoutes;
