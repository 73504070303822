/****************** DEPENDENCIES (import) ******************/

import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { compose } from "recompose";
import React, { KeyboardEvent } from "react";
import { connect } from "react-redux";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Avatar, Card, Col, Input, List, Row, Skeleton, Typography } from "antd";
import logo from "../../assets/logo.svg";
import GenderIcon from "components/shared/gender/GenderIcon";
import { SearchOutlined } from "@ant-design/icons";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { birthRegistryApiFactory } from "config";
import { addNotification, addThread, removeThread } from "store/actions";
import { BirthRegistryShort, BabySearched } from "bbb-api/dist/models";
import { prepareUrl } from "config/constants";
import { BBBRoutes } from "routes";
import { BbbButton } from "components/shared";
/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps &
  RouteComponentProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = { loading: boolean; resultList?: BirthRegistryShort[]; currentSearch?: string };

class SearchList extends React.Component<Props, State> {
  state: State = { loading: false, resultList: undefined, currentSearch: undefined };

  /* LifeCycle Methods */
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `home.search.${id}` }, param);
  };

  onSearchChange = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((state: State) => ({ ...state, currentSearch: changeEvent.target.value }));
  };

  onSearch = (): void => {
    log.info("onSearch");
    if (this.state.currentSearch && _.size(this.state.currentSearch) > 1) {
      this.setState((state: State) => ({ ...state, loading: true }));
      const birthRegistryApi = birthRegistryApiFactory();
      this.props.addThread("searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet");
      birthRegistryApi
        .searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet(this.state.currentSearch)
        .then((response) => {
          const list = response.data;
          log.info(
            `Successfully searching /birthRegistries/ with value ${this.state.currentSearch}`,
            list
          );
          this.setState((state: State) => ({ ...state, resultList: list }));
        })
        .catch((error) => {
          log.error(`Error searching /birthRegistries/ with value value`, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de réaliser cette recherche",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("searchBirthRegistryHomepageApiV1BirthregistrySearchQueryGet");
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  };

  /* Render methods */
  renderNames(babies: BabySearched[] | undefined) {
    if (babies) {
      return babies.map((baby, index) => (
        <Typography.Text key={index}>
          {baby.firstname}
          <GenderIcon gender={baby.gender} genderIsPrivate={false}></GenderIcon>
        </Typography.Text>
      ));
    }
  }

  renderResultList() {
    if (this.state.resultList !== undefined) {
      return (
        <List
          loading={this.state.loading}
          itemLayout="horizontal"
          locale={{ emptyText: this.getTraduction("noresult") }}
          dataSource={this.state.resultList}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Link
                  key={item.id}
                  to={BBBRoutes.BIRTHREGISTRY_DETAIL_BY_NANO_ID.computePath({
                    nano: _.first(item.shortlinks)?.name,
                  })}
                >
                  <FormattedMessage id="home.search.link"></FormattedMessage>
                </Link>,
              ]}
            >
              <Skeleton avatar title={false} loading={this.state.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar src={item.thumbnail_path ? prepareUrl(item.thumbnail_path) : logo} />
                  }
                  title={item.title}
                  description={item.address_owner}
                />
                <div>{this.renderNames(item.babies)}</div>
              </Skeleton>
            </List.Item>
          )}
        />
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  render() {
    return (
      <Card className="cardContent">
        <Row align="middle" justify="center">
          <Col span={24}>
            <Typography.Title className="title" level={2}>
              <FormattedMessage id="home.search.title"></FormattedMessage>
            </Typography.Title>
            <Input
              onChange={this.onSearchChange}
              placeholder={this.getTraduction("placeholder")}
              onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                e.key === "Enter" && this.onSearch();
              }}
              addonAfter={
                <BbbButton
                  type="primary"
                  disabled={_.size(this.state.currentSearch) <= 1}
                  loading={this.state.loading}
                  icon={<SearchOutlined />}
                  onClick={this.onSearch}
                />
              }
            ></Input>
          </Col>
        </Row>
        <Row className="searchResult" align="middle" justify="center">
          <Col span={24}>{this.renderResultList()}</Col>
        </Row>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}

export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchList);
