/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as log from "loglevel";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";
import { prepareUrl } from "config/constants";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Checkbox, Col, Form, Input, InputNumber, Row } from "antd";
import { FormCarousel } from "../form";
import { SelectCategories } from "../categories";
import { BbbButton, BbbUpload } from "components/shared";
import { SyncOutlined } from "@ant-design/icons";

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Notification, Store } from "store/reducers";
import { FieldData, Store as FormStore, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { s3ApiFactory, scraperApiFactory, wishApiFactory } from "config";
import {
  Category,
  ParsedItem,
  S3BucketName,
  S3Image,
  Wish,
  WishCreate,
  WishUpdate,
} from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
import { FormInstance } from "antd/lib/form";
import { RcFile } from "rc-upload/lib/interface";
import { AxiosResponse } from "axios";
import { UploadFileStatus } from "antd/lib/upload/interface";

/****************** RENDERING (export) ******************/
type InputProps = {
  addElement(wish: Wish): void;
  updateElement(wish: Wish): void;
  cancel(): void;
  birthRegistryId: number;
  categories: Category[];
  selectedWish?: Wish;
  changePage(subpage: "MAIN"): void;
};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {
  uploadImageFeature: boolean;
  scanWishUrlFeature: boolean;
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type WishFields = {
  link?: string;
  category_id?: number;
  brand?: string;
  title?: string;
  price?: number;
  quantity?: number;
  is_favorite?: boolean;
  description?: string;
};

type State = {
  loading: boolean;
  formValues?: WishFields;
  image?: {
    uploadFile?: RcFile;
    suggestedFile?: boolean;
    img_path?: string;
    thumbnail_path?: string;
  };
  formIsValid: boolean;
  mode: "CREATE" | "UPDATE";
  formRef: React.RefObject<FormInstance>;
  waitingImgUrl?: string;
};

class WishForm extends React.Component<Props, State> {
  state: State = {
    loading: false,
    formValues: {
      price: 0,
      quantity: 1,
      is_favorite: false,
    },
    formIsValid: false,
    mode: "CREATE",
    formRef: React.createRef(),
    waitingImgUrl: undefined,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.selectedWish) {
      this.fillForm();
    } else {
      this.state.formRef.current?.setFieldsValue({ ...this.state.formValues });
    }
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `wishForm.${id}` }, param);
  };

  fillForm = () => {
    const { selectedWish } = this.props;
    if (selectedWish) {
      const formValues: WishFields = {
        link: selectedWish?.link,
        category_id: selectedWish?.category_id,
        brand: selectedWish?.brand,
        title: selectedWish?.title,
        price: selectedWish?.price,
        quantity: selectedWish.quantity,
        is_favorite: selectedWish?.is_favorite,
        description: selectedWish?.description,
      };
      this.state.formRef.current?.setFieldsValue(formValues);
      this.setState((state: State) => ({
        ...state,
        formValues,
        image: {
          img_path: selectedWish.img_path,
          thumbnail_path: selectedWish.img_path,
        },
        mode: "UPDATE",
        formIsValid: true,
      }));
    }
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  onFinish = (values: FormStore) => {
    log.info("Submit wishForm", values);
    const { formValues, image } = this.state;
    if (formValues) {
      log.info(formValues);
      const restDataCreate: WishCreate = {
        order: undefined,
        link: formValues.link,
        category_id: formValues.category_id,
        brand: formValues.brand,
        title: formValues.title,
        price: formValues.price,
        quantity: formValues.quantity,
        is_favorite: formValues.is_favorite,
        description: formValues.description,
        img_path: image?.img_path,
      };

      const wishApi = wishApiFactory();
      if (this.state.mode === "CREATE" && !this.props.selectedWish) {
        this.props.addThread("createWishApiV1WishPost");
        wishApi
          .createWishApiV1WishPost(restDataCreate)
          .then((response) => {
            const wishCreate: Wish = response.data;
            log.info(`Successfully post /wish `, wishCreate);
            this.props.addElement(wishCreate);
          })
          .catch((error) => {
            log.error(`Error post /wish`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de créer le souhait",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("createWishApiV1WishPost");
          });
      } else if (this.state.mode === "UPDATE" && this.props.selectedWish) {
        const restDataUpdate: WishUpdate = {
          ...this.props.selectedWish,
          ...restDataCreate,
        };
        this.props.addThread("updateWishApiV1WishWishIdPut");
        wishApi
          .updateWishApiV1WishWishIdPut(this.props.selectedWish.id, restDataUpdate)
          .then((response) => {
            const wishUpdate: Wish = response.data;
            log.info(`Successfully put /wish `, wishUpdate);
            this.props.updateElement(wishUpdate);
          })
          .catch((error) => {
            log.error(`Error put /wish`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de modifier le souhait",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("updateWishApiV1WishWishIdPut");
          });
      }
    }
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.props.addNotification({
      type: "warning",
      title: "Formulaire non valide",
      description: `${_.size(errorInfo.errorFields)} erreur(s) restante(s)`,
    });
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.info("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as WishFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.info("onFieldsChange", changedFields);
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  selectCategory = (id: number): void => {
    log.info("selectCategory", id);
    const newFormValues = {
      ...this.state.formValues,
      category_id: id,
    };
    this.state.formRef.current?.setFieldsValue(newFormValues);
    this.setState((state: State) => ({
      ...state,
      formValues: newFormValues,
      formIsValid: true,
    }));
  };

  cancel = (): void => {
    this.setState((state: State) => ({ ...state, mode: "CREATE" }));
    this.state.formRef.current?.resetFields();
    this.props.cancel();
  };

  parseGiftUrl = (): void => {
    log.info("Start parseGiftUrl : ", this.state.formValues!.link);
    if (this.state.formValues?.link !== undefined) {
      this.setState((state: State) => ({ ...state, loading: true }));
      const scraperApi = scraperApiFactory();
      this.props.addThread("parseUrlApiV1ScraperParsePost");
      scraperApi
        .parseUrlApiV1ScraperParsePost(this.state.formValues.link)
        .then((response) => {
          log.trace(`Successfully parse Wish URL`, { response });
          const scrapperValues: ParsedItem = response.data;
          log.info("ScrapperValues :", scrapperValues);
          this.setState((state: State) => ({
            ...state,
            formValues: { ...state.formValues, ...scrapperValues, quantity: 1 },
          }));
          this.state.formRef.current?.setFieldsValue({ ...scrapperValues });

          // Propose to use the detected image as wish image
          if (scrapperValues.img) {
            const returnedWebsiteImgUrl = scrapperValues.img;
            this.setState((state: State) => ({
              ...state,
              waitingImgUrl: returnedWebsiteImgUrl,
            }));
          }

          // change toaster content about known shop
          if (scrapperValues.known_shop) {
            this.props.addNotification({
              type: "success",
              description: "Formulaire pré-rempli",
              title: "Synchronisation avec un site partenaire",
            });
          } else {
            this.props.addNotification({
              type: "success",
              description:
                "Nous ne connaissons pas ce site mais avons essayé de pré-remplir au mieux le formulaire",
              title: "Synchronisation avec un site inconnu",
            });
          }
        })
        .catch((error) => {
          log.error(`Error parsing Wish URL`, error);
          this.props.addNotification({
            type: "error",
            description: this.getTraduction(`error.${error.response.data.code}`),
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("parseUrlApiV1ScraperParsePost");
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    } else {
      log.error(`Field is empty`);
      this.props.addNotification({
        type: "error",
        description: "Veuillez entrer une URL de cadeau",
        title: "Erreur",
      });
    }
  };

  uploadImageFromShopWebsiteToLocal = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    log.info("Try to load  externalImageUrl:", this.state.waitingImgUrl);
    if (this.state.waitingImgUrl) {
      const s3Api = s3ApiFactory();
      this.props.addThread("uploadDistImageApiV1S3UploadDistBucketNamePost");
      s3Api
        .uploadDistImageApiV1S3UploadDistBucketNamePost(S3BucketName.Item, this.state.waitingImgUrl)
        .then((response: AxiosResponse<S3Image>) => {
          log.info("Response from download operation", response.data);
          this.setState((state: State) => ({
            ...state,
            image: {
              uploadFile: undefined,
              suggestedFile: true,
              img_path: response.data.image_path,
              thumbnail_path: response.data.thumbnail_path,
            },
            waitingImgUrl: undefined,
          }));
          this.props.addNotification({
            type: "success",
            description: "La photo du site a bien été importée automatiquement.",
            title: "Succès",
          });
        })
        .catch((error) => {
          log.error(`Error uploading image`, error);
        })
        .finally(() => {
          this.props.removeThread("uploadDistImageApiV1S3UploadDistBucketNamePost");
        });
    }
  };

  handleUploadChange = (file?: RcFile, img_path?: string, thumbnail_path?: string) => {
    log.info("handleUploadChange", { file, img_path, thumbnail_path });
    this.setState((state: State) => ({
      ...state,
      image: {
        uploadFile: file,
        suggestedFile: false,
        img_path: img_path,
        thumbnail_path: thumbnail_path,
      },
    }));
  };

  /* Render methods */
  renderScanWishIfFeatureActivated() {
    const isEmpty = !this.state.formValues?.link;
    return (
      <Form.Item
        name="link"
        label={this.getTraduction("label.link")}
        rules={[{ max: 500, message: this.getTraduction("max.link") }]}
      >
        <Input
          addonAfter={
            this.props.scanWishUrlFeature ? (
              <BbbButton
                type="primary"
                style={{ color: isEmpty ? "rgba(0, 0, 0, 0.25)" : "inherit" }}
                className="ml10"
                disabled={isEmpty}
                loading={this.state.loading}
                icon={<SyncOutlined />}
                onClick={this.parseGiftUrl}
              >
                <span className="ml10">
                  <FormattedMessage id="wishForm.sync" />
                </span>
              </BbbButton>
            ) : undefined
          }
        />
      </Form.Item>
    );
  }

  renderPage1() {
    return (
      <React.Fragment key="1">
        {this.renderScanWishIfFeatureActivated()}

        <Form.Item
          name="brand"
          label={this.getTraduction("label.brand")}
          rules={[{ max: 100, message: this.getTraduction("max.brand") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="title"
          label={this.getTraduction("label.title")}
          rules={[
            { required: true, message: this.getTraduction("mandatory.title") },
            { max: 255, message: this.getTraduction("max.title") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="price"
          label={this.getTraduction("label.price")}
          rules={[{ required: true, message: this.getTraduction("mandatory.price") }]}
        >
          <InputNumber
            formatter={(value) => `${value}€`}
            parser={(value) => (value ? value.replace("€", "") : "0")}
            step={0.1}
            min={0}
            max={10000}
            decimalSeparator={","}
          />
        </Form.Item>
        <Form.Item
          name="quantity"
          label={this.getTraduction("label.quantity")}
          initialValue={1}
          rules={[{ required: true, message: this.getTraduction("mandatory.quantity") }]}
        >
          <InputNumber step={1} min={1} max={20} decimalSeparator={","} />
        </Form.Item>
        <Form.Item
          name="is_favorite"
          labelCol={{ span: 8 }}
          className="checkbox"
          label={this.getTraduction("label.is_favorite")}
          colon={false}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item name="description" label={this.getTraduction("label.description")}>
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name="category_id"
          label={this.getTraduction("label.category_id")}
          rules={[{ required: true, message: this.getTraduction("mandatory.category_id") }]}
        >
          <SelectCategories
            birthRegistryId={this.props.birthRegistryId}
            initialValue={this.props.selectedWish?.category_id}
            categories={this.props.categories}
            selectCategory={this.selectCategory}
          ></SelectCategories>
        </Form.Item>
        {this.renderUploadedImageIfFeatureActivated()}
      </React.Fragment>
    );
  }

  renderUploadedImageIfFeatureActivated() {
    if (this.props.uploadImageFeature) {
      const defaultList =
        this.props.selectedWish && _.size(this.props.selectedWish.img_path) > 1
          ? [
              {
                status: "done",
                url: prepareUrl(this.props.selectedWish.img_path),
                thumbUrl: prepareUrl(this.props.selectedWish.img_path), // TODO ask for thumbnail_path image
              },
            ]
          : undefined;
      const suggestedFileList =
        this.state.image && this.state.image.suggestedFile
          ? [
              {
                uid: uuidv4(),
                size: 0,
                name: this.state.formValues?.title ?? "",
                type: "jpg",
                status: "done" as UploadFileStatus,
                url: prepareUrl(this.state.image.img_path),
                thumbUrl: prepareUrl(this.state.image.thumbnail_path),
              },
            ]
          : undefined;
      return (
        <React.Fragment>
          {this.state.waitingImgUrl && (
            <BbbButton
              type="primary"
              shape="round"
              onClick={(e) => this.uploadImageFromShopWebsiteToLocal(e)}
            >
              {this.getTraduction("label.img_import")}
            </BbbButton>
          )}
          <BbbUpload
            handleUploadChange={this.handleUploadChange}
            bucket={S3BucketName.Item}
            label={this.getTraduction("label.img_path")}
            button_add={this.getTraduction("label.img_add")}
            button_edit={this.getTraduction("label.img_edit")}
            maxCount={1}
            listType="picture"
            accept="image/*"
            includeForm={true}
            defaultFileList={defaultList}
            suggestedFileList={suggestedFileList}
          />
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <Row align="middle" justify="center">
        <Col md={15} lg={13} xl={11}>
          <Form
            layout="horizontal"
            ref={this.state.formRef}
            labelCol={{ span: 8 }}
            onValuesChange={this.valuesChange}
            onFieldsChange={this.onFieldsChange}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            scrollToFirstError={true}
          >
            <FormCarousel
              pages={["MAIN"]}
              changePage={this.props.changePage}
              cancelLabel={<FormattedMessage id="wishForm.cancel" />}
              cancel={() => this.cancel()}
              formIsValid={this.state.formIsValid}
              validLabel={
                this.state.mode === "CREATE" ? (
                  <FormattedMessage id="wishForm.add" />
                ) : (
                  <FormattedMessage id="wishForm.update" />
                )
              }
              valid={this.submit}
            >
              {[this.renderPage1()]}
            </FormCarousel>
          </Form>
        </Col>
      </Row>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    uploadImageFeature: state.config.features.uploadImageFeature,
    scanWishUrlFeature: state.config.features.scanWishUrlFeature,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(WishForm);
