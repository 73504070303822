/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as log from "loglevel";
import * as _ from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

/****************** DEPENDENCIES : COMPONENTS ******************/
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import { FormCarousel } from "../form";
import ImagePickerModal from "./ImagePickerModal";
import { BbbButton, BbbUpload } from "components/shared";
/****************** STYLING ******************/
import "./BirthRegistryForm.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Store as FormStore, ValidateErrorEntity, FieldData } from "rc-field-form/lib/interface";
import { birthRegistryApiFactory } from "config";
import {
  Baby,
  BirthRegistry,
  BirthRegistryCreate,
  BirthRegistryUpdate,
  Gender,
  ListTypeEnum,
  S3BucketName,
} from "bbb-api/dist/models";
import { addThread, removeThread, addNotification } from "store/actions";
import { FormInstance } from "antd/lib/form";
import { RcFile } from "rc-upload/lib/interface";
import { prepareUrl } from "config/constants";
import { UploadFile } from "antd/lib/upload/interface";

/****************** RENDERING (export) ******************/
type InputProps = {
  addElement(birthRegistry: BirthRegistry): void;
  updateElement(birthRegistry: BirthRegistry): void;
  cancel(): void;
  birthRegistry?: BirthRegistry;
  changePage(subpage: "NEW" | "ADDRESS" | "BABIES"): void;
};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {
  cashPoolFeature: boolean;
  uploadImageFeature: boolean;
  imageCatalogFeature: boolean;
  secondOwnerFeature: boolean;
  multiBabyFeature: boolean;
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type BirthRegistryFields = {
  title: string;
  type: ListTypeEnum;
  description?: string;
  public: boolean;
  cash_pool?: boolean;
  second_owner?: boolean;
  second_owner_mail?: string;
  address_owner: string;
  address_street: string;
  address_compl?: string;
  address_zip_code: string;
  address_city: string;
  address_region?: string;
  address_country: string;
  event_date: moment.Moment;
  baby_firstname?: string;
  baby_firstname_secret: boolean;
  baby_sex?: Gender;
  baby_sex_secret: boolean;
  // babies?: [{ firstname: string; firstname_secret: boolean; sex: string; sex_secret: boolean }];
};
export type FileContent = {
  newFile?: RcFile;
  image_path?: string;
  thumbnail_path?: string;
};

type State = {
  formValues: BirthRegistryFields;
  image?: FileContent;
  formIsValid: boolean;
  mode: "CREATE" | "UPDATE";
  formRef: React.RefObject<FormInstance>;
};

class BirthRegistryForm extends React.Component<Props, State> {
  state: State = {
    formValues: {
      title: "",
      type: ListTypeEnum.Birth,
      public: false,
      second_owner: false,
      cash_pool: true,
      address_owner: "",
      address_street: "",
      address_zip_code: "",
      address_city: "",
      address_country: "",
      event_date: moment().add(6, "M"),
      baby_firstname_secret: false,
      baby_sex: Gender.Na,
      baby_sex_secret: false,
    },
    formIsValid: false,
    mode: "CREATE",
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.birthRegistry) {
      this.fillForm();
    } else {
      this.state.formRef.current?.setFieldsValue({ ...this.state.formValues });
    }
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `birthRegistriesForm.${id}` }, param);
  };

  fillForm = () => {
    const { birthRegistry } = this.props;
    if (birthRegistry) {
      const firstBaby: Baby | undefined = _.first(birthRegistry.babies);
      const formValues: BirthRegistryFields = {
        title: birthRegistry.title || "",
        type: ListTypeEnum.Birth,
        description: birthRegistry.description,
        public: !birthRegistry.is_private,
        // cash_pool: boolean;
        // image => not in form
        // second_owner: boolean;
        // second_owner_mail?: string;
        address_owner: birthRegistry.address_owner ?? "",
        address_street: birthRegistry.address_first_line ?? "",
        address_compl: birthRegistry.address_second_line ?? "",
        address_zip_code: birthRegistry.address_zip_code ?? "",
        address_city: birthRegistry.address_city ?? "",
        address_region: birthRegistry.address_region,
        address_country: birthRegistry.address_country ?? "",
        event_date: moment(birthRegistry.event_date, "YYYY-MM-DD"),
        baby_firstname: firstBaby ? firstBaby.firstname : "",
        baby_firstname_secret: firstBaby && firstBaby.firstname_is_private ? true : false,
        baby_sex: firstBaby ? firstBaby.gender : Gender.Na,
        baby_sex_secret: firstBaby && firstBaby.gender_is_private ? true : false,
      };
      this.state.formRef.current?.setFieldsValue({ ...formValues });
      this.setState((state: State) => ({
        ...state,
        formValues,
        image: {
          image_path: birthRegistry.image_path,
          thumbnail_path: birthRegistry.thumbnail_path,
        },
        mode: "UPDATE",
        formIsValid: true,
      }));
    }
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  onFinish = (values: FormStore) => {
    log.info("Submit BirthRegistry", values);
    const { formValues, image } = this.state;
    if (formValues) {
      log.info(this.state.formValues);
      const restDataCreate: BirthRegistryCreate = {
        title: formValues.title,
        type: formValues.type,
        description: formValues.description,
        is_private: formValues.public === false,
        is_online: false, // hard coded value for now
        // cash_pool
        image_path: image?.image_path,
        thumbnail_path: image?.thumbnail_path,
        // second_owner
        // second_owner_mail
        address_owner: formValues.address_owner,
        address_first_line: formValues.address_street,
        address_second_line: formValues.address_compl,
        address_city: formValues.address_city,
        address_zip_code: formValues.address_zip_code,
        address_region: formValues.address_region,
        address_country: formValues.address_country,
        event_date: formValues.event_date.format("YYYY-MM-DD"),
        babies: [
          {
            firstname: formValues.baby_firstname,
            firstname_is_private: formValues.baby_firstname_secret,
            gender: formValues.baby_sex,
            gender_is_private: formValues.baby_sex_secret,
          },
        ],
      };
      const birthRegistryApi = birthRegistryApiFactory();
      if (this.state.mode === "CREATE" && !this.props.birthRegistry) {
        this.props.addThread("createBirthRegistryApiV1BirthregistryPost");
        birthRegistryApi
          .createBirthRegistryApiV1BirthregistryPost(restDataCreate)
          .then((response) => {
            const birthRegistry: BirthRegistry = response.data;
            log.info(`Successfully post /birthregistry `, birthRegistry);
            this.props.addElement(birthRegistry);
          })
          .catch((error) => {
            log.error(`Error post /birthregistry`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de créer la liste de naissance",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("createBirthRegistryApiV1BirthregistryPost");
          });
      } else if (this.state.mode === "UPDATE" && this.props.birthRegistry) {
        const firstBaby: Baby | undefined = _.first(this.props.birthRegistry.babies);
        const restDataUpdate: BirthRegistryUpdate = {
          ...restDataCreate,
          babies: firstBaby
            ? [
                {
                  ...firstBaby,
                  firstname: formValues.baby_firstname,
                  firstname_is_private: formValues.baby_firstname_secret,
                  gender: formValues.baby_sex,
                  gender_is_private: formValues.baby_sex_secret,
                },
              ]
            : [],
        };
        this.props.addThread("updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut");
        birthRegistryApi
          .updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut(
            this.props.birthRegistry.id,
            restDataUpdate
          )
          .then((response) => {
            const birthRegistry: BirthRegistry = response.data;
            log.info(`Successfully put /birthregistry `, birthRegistry);
            this.props.updateElement(birthRegistry);
          })
          .catch((error) => {
            log.error(`Error put /birthregistry`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de modifier la liste de naissance",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("updateBirthRegistryApiV1BirthregistryBirthRegistryIdPut");
          });
      }
    }
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as BirthRegistryFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange", changedFields);
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  cancel = (): void => {
    this.setState((state: State) => ({ ...state, mode: "CREATE" }));
    this.state.formRef.current?.resetFields();
    this.props.cancel();
  };

  handleUploadChange = (file?: RcFile, image_path?: string, thumbnail_path?: string) => {
    log.info("handleUploadChange", { file, image_path, thumbnail_path });
    this.setState((state: State) => ({
      ...state,
      image: {
        newFile: file,
        image_path: image_path,
        thumbnail_path: thumbnail_path,
      },
    }));
  };

  /* Render methods */
  renderPage1() {
    return (
      <React.Fragment key="1">
        <Form.Item
          name="title"
          label={this.getTraduction("label.title")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, message: this.getTraduction("mandatory.title") },
            { max: 255, message: this.getTraduction("max.title") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label={this.getTraduction("label.type")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select>
            <Select.Option value={ListTypeEnum.Birth}>
              {this.getTraduction("options.type.BIRTH")}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
          label={this.getTraduction("label.description")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ max: 5000, message: this.getTraduction("max.description") }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name="public"
          className="checkbox"
          label={this.getTraduction("label.public")}
          colon={false}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Typography.Paragraph>
          <FormattedMessage id="birthRegistriesForm.label.public_help"></FormattedMessage>
        </Typography.Paragraph>
        {this.renderCashPoolIfFeatureActivated()}
        <ImagePickerModal validate={this.handleUploadChange} />
        {this.renderUploadImageIfFeatureActivated()}
        {this.renderSecondOwnerIfFeatureActivated()}
      </React.Fragment>
    );
  }

  renderSecondOwnerIfFeatureActivated() {
    if (this.props.secondOwnerFeature) {
      return (
        <React.Fragment>
          <Form.Item
            name="second_owner"
            labelCol={{ xs: 20, lg: 12 }}
            label={this.getTraduction("label.second_owner")}
            colon={false}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {this.state.formValues?.second_owner === true ? (
            <Form.Item
              name="second_owner_mail"
              label={this.getTraduction("label.second_owner_mail")}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: this.state.formValues?.second_owner === true,
                  message: this.getTraduction("mandatory.second_owner_mail"),
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: this.getTraduction("mailNotValid"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : undefined}
        </React.Fragment>
      );
    } else {
      return undefined;
    }
  }

  renderCashPoolIfFeatureActivated() {
    if (this.props.cashPoolFeature) {
      return (
        <Form.Item
          name="cash_pool"
          labelCol={{ span: 12 }}
          label={this.getTraduction("label.cash_pool")}
          colon={false}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      );
    }
  }

  renderUploadImageIfFeatureActivated() {
    const { uploadImageFeature, imageCatalogFeature, birthRegistry } = this.props;
    const { image } = this.state;
    if (uploadImageFeature) {
      if (imageCatalogFeature) {
        const fileList: UploadFile[] =
          image && _.size(image?.image_path) > 1
            ? [
                {
                  uid: uuidv4(),
                  status: "done",
                  url: prepareUrl(image.image_path),
                  thumbUrl: prepareUrl(image.thumbnail_path),
                  size: 0,
                  name: "",
                  type: "image",
                },
              ]
            : [];
        log.info("renderUploadImageIfFeatureActivated", { imageCatalogFeature, fileList });
        // Just use bbbUpload to display thumbmail of image
        return (
          <Upload
            listType="picture"
            onRemove={() => this.handleUploadChange(undefined, "", "")}
            fileList={fileList}
          ></Upload>
        );
      } else {
        const defaultList =
          birthRegistry && _.size(birthRegistry.image_path) > 1
            ? [
                {
                  status: "done",
                  url: prepareUrl(birthRegistry.image_path),
                  thumbUrl: prepareUrl(birthRegistry.thumbnail_path),
                },
              ]
            : undefined;
        log.info("renderUploadImageIfFeatureActivated", { imageCatalogFeature, defaultList });
        return (
          <BbbUpload
            handleUploadChange={this.handleUploadChange}
            bucket={S3BucketName.ListHeader}
            label={this.getTraduction("label.image")}
            button_add={this.getTraduction("label.image_add")}
            button_edit={this.getTraduction("label.image_edit")}
            maxCount={1}
            listType="picture"
            accept="image/*"
            includeForm={true}
            defaultFileList={defaultList}
          />
        );
      }
    }
  }

  renderPage2() {
    return (
      <React.Fragment key="2">
        <Form.Item
          name="address_owner"
          label={this.getTraduction("label.address_owner")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, message: this.getTraduction("mandatory.address_owner") },
            { max: 255, message: this.getTraduction("max.address_owner") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address_street"
          label={this.getTraduction("label.address_street")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: this.getTraduction("mandatory.address_street"),
            },
            { max: 255, message: this.getTraduction("max.address_street") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address_compl"
          label={this.getTraduction("label.address_compl")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ max: 255, message: this.getTraduction("max.address_compl") }]}
        >
          <Input />
        </Form.Item>
        <Row className="full">
          <Col xs={24} lg={8}>
            <Form.Item
              name="address_zip_code"
              label={this.getTraduction("label.address_zip_code")}
              labelCol={{ span: 23 }}
              wrapperCol={{ span: 23 }}
              rules={[
                {
                  required: true,
                  message: this.getTraduction("mandatory.address_zip_code"),
                },
                { max: 20, message: this.getTraduction("max.address_zip_code") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={16}>
            <Form.Item
              name="address_city"
              label={this.getTraduction("label.address_city")}
              labelCol={{ span: 23 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: this.getTraduction("mandatory.address_city") }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="address_region"
          label={this.getTraduction("label.address_region")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ max: 255, message: this.getTraduction("max.address_region") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address_country"
          label={this.getTraduction("label.address_country")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: this.getTraduction("mandatory.address_country"),
            },
          ]}
        >
          <Select>
            <Select.Option value="FR">
              {this.getTraduction("options.address_country.FR")}
            </Select.Option>
            <Select.Option value="BE">
              {this.getTraduction("options.address_country.BE")}
            </Select.Option>
            <Select.Option value="LU">
              {this.getTraduction("options.address_country.LU")}
            </Select.Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  }

  renderPage3() {
    return (
      <React.Fragment key="3">
        <Form.Item
          name="event_date"
          label={this.getTraduction("label.event_date")}
          rules={[{ required: true, message: this.getTraduction("mandatory.event_date") }]}
        >
          <DatePicker placeholder={this.getTraduction("placeholder.event_date")}></DatePicker>
        </Form.Item>
        {this.renderBabiesPartIfFeatureActivated()}
      </React.Fragment>
    );
  }

  renderBabiesPartIfFeatureActivated() {
    if (this.props.multiBabyFeature) {
      return (
        <React.Fragment>
          <Typography>Feature not implemented for now</Typography>
          <BbbButton size="small">
            <FormattedMessage id="birthRegistriesForm.label.baby_add" />
          </BbbButton>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Form.Item
            name="baby_firstname"
            label={this.getTraduction("label.baby_firstname")}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ max: 255, message: this.getTraduction("max.baby_firstname") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="baby_firstname_secret"
            className="checkbox"
            label={this.getTraduction("label.baby_firstname_secret")}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item name="baby_sex" label={this.getTraduction("label.baby_sex")}>
            <Radio.Group>
              <Radio value={Gender.M}>
                <FormattedMessage id="birthRegistriesForm.options.baby.boy" />
              </Radio>
              <Radio value={Gender.F}>
                <FormattedMessage id="birthRegistriesForm.options.baby.girl" />
              </Radio>
              <Radio value={Gender.Na}>
                <FormattedMessage id="birthRegistriesForm.options.baby.xxx" />
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="baby_sex_secret"
            className="checkbox"
            label={this.getTraduction("label.baby_sex_secret")}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <Row align="middle" justify="center">
        <Col md={15} lg={13} xl={11}>
          <Form
            layout="horizontal"
            ref={this.state.formRef}
            labelCol={{ span: 8 }}
            onValuesChange={this.valuesChange}
            onFieldsChange={this.onFieldsChange}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            scrollToFirstError={true}
          >
            <FormCarousel
              pages={["NEW", "ADDRESS", "BABIES"]}
              changePage={this.props.changePage}
              cancelLabel={<FormattedMessage id="birthRegistriesForm.cancel" />}
              cancel={() => this.cancel()}
              formIsValid={this.state.formIsValid}
              validLabel={
                this.state.mode === "CREATE" ? (
                  <FormattedMessage id="birthRegistriesForm.valid" />
                ) : (
                  <FormattedMessage id="birthRegistriesForm.update" />
                )
              }
              valid={() => this.submit()}
            >
              {this.renderPage1()}
              {this.renderPage2()}
              {this.renderPage3()}
            </FormCarousel>
          </Form>
        </Col>
      </Row>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    cashPoolFeature: state.config.features.cashPoolFeature,
    uploadImageFeature: state.config.features.uploadImageFeature,
    imageCatalogFeature: state.config.features.imageCatalogFeature,
    secondOwnerFeature: state.config.features.secondOwnerFeature,
    multiBabyFeature: state.config.features.multiBabyFeature,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryForm);