/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BirthRegistryContent, BBBLayout, BirthRegistryDetailHeader } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { BirthRegistry, User } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
import { birthRegistryApiFactory } from "config";

/****************** RENDERING (export) ******************/
type InputProps = {};
type RouteParams = { id: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = { currentUser?: User };
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = {
  loading: boolean;
  birthRegistryId?: number;
  birthRegistry?: BirthRegistry;
};

class BirthRegistryDetail extends React.Component<Props, State> {
  state: State = {
    loading: true,
    birthRegistryId: undefined,
    birthRegistry: undefined,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      const birthRegistryApi = birthRegistryApiFactory();
      if (_.isInteger(Number(match.params.id))) {
        const birthRegistryId = Number(match.params.id);
        this.props.addThread("getBirthRegistryApiV1BirthregistryBirthRegistryIdGet");
        birthRegistryApi
          .getBirthRegistryApiV1BirthregistryBirthRegistryIdGet(birthRegistryId)
          .then((response) => {
            const birthRegistry: BirthRegistry = response.data;
            log.info(`Successfully fetch /birthRegistries/${birthRegistryId} `, birthRegistry);
            this.setState((state: State) => ({
              ...state,
              birthRegistry: birthRegistry,
              birthRegistryId: birthRegistryId,
            }));
          })
          .catch((error) => {
            log.error(`Error fetching /birthRegistries/${birthRegistryId}`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de récupérer la liste de naissance",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("getBirthRegistryApiV1BirthregistryBirthRegistryIdGet");
            this.setState((state: State) => ({ ...state, loading: false }));
          });
      } else {
        const nanoId = match.params.id;
        this.props.addThread("getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet");
        birthRegistryApi
          .getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet(nanoId)
          .then((response) => {
            const birthRegistry: BirthRegistry = response.data;
            log.info(`Successfully fetch /birthRegistries/${nanoId} `, birthRegistry);
            this.setState((state: State) => ({
              ...state,
              birthRegistry: birthRegistry,
              birthRegistryId: birthRegistry.id,
            }));
          })
          .catch((error) => {
            log.error(`Error fetching /birthRegistries/${nanoId}`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de récupérer la liste de naissance",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread(
              "getBirthRegistryByShortlinkApiV1BirthregistryShortlinkShortlinkGet"
            );
            this.setState((state: State) => ({ ...state, loading: false }));
          });
      }
    }
  }
  /* Handlers methods */

  /* Render methods */
  render() {
    return (
      <BBBLayout
        mode="FULL"
        title={
          this.state.birthRegistry ? (
            <React.Fragment>{this.state.birthRegistry.title}</React.Fragment>
          ) : undefined
        }
        titleSeo={
          this.state.birthRegistry ? this.state.birthRegistry.title : "birthRegistryContent.title"
        }
        loading={this.state.loading}
      >
        <BirthRegistryDetailHeader
          loading={this.state.loading}
          birthRegistry={this.state.birthRegistry}
        ></BirthRegistryDetailHeader>
        <BirthRegistryContent
          loading={this.state.loading}
          birthRegistry={this.state.birthRegistry}
        ></BirthRegistryContent>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    currentUser: state.user.currentUser,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryDetail);
