/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout, FormCarousel } from "components/shared";
import { Input, Checkbox, Typography, Space } from "antd";
/****************** TYPES ******************/
import { Store, Notification } from "store/reducers";
import { addNotification, addThread, cleanBasket, removeThread } from "store/actions";
import { SubBasketList } from "store/reducers/basket";
import Form, { FormInstance } from "antd/lib/form";
import { Store as FormStore, ValidateErrorEntity, FieldData } from "rc-field-form/lib/interface";
import { BBBRoutes } from "routes";
import { BookingMultipleArticleOnOneListCreate, User } from "bbb-api";
import { BbbError, bookingApiFactory } from "config";
/****************** STYLING ******************/

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {
  subList: SubBasketList[];
  authenticated: boolean;
  currentUser?: User;
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  cleanBasket: (payload: boolean) => void;
};

type SummaryFields = {
  firstname: string;
  lastname: string;
  email: string;
  messages: string[];
  anonymous: boolean;
};

type State = {
  loading: boolean;
  formValues: SummaryFields;
  formIsValid: boolean;
  formRef: React.RefObject<FormInstance>;
};
class BasketConfirm extends React.Component<Props, State> {
  state: State = {
    loading: false,
    formValues: {
      firstname: "",
      lastname: "",
      email: "",
      messages: [],
      anonymous: false,
    },
    formIsValid: false,
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.authenticated) {
      this.state.formRef.current?.setFieldsValue({
        firstname: this.props.currentUser?.first_name,
        lastname: this.props.currentUser?.last_name,
        email: this.props.currentUser?.email,
        messages: [],
        anonymous: false,
      });
    } else {
      this.state.formRef.current?.setFieldsValue({ ...this.state.formValues });
    }
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `summary.${id}` }, param);
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as SummaryFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange", changedFields);
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  onFinish = (values: FormStore) => {
    const { formValues } = this.state;
    log.info("Submit basketSummary", formValues);
    if (formValues) {
      const bookingApi = bookingApiFactory();
      let bookingCreateMultiList: BookingMultipleArticleOnOneListCreate[] = [];

      this.props.subList.forEach((subList, index) => {
        let bookingCreateMulti: BookingMultipleArticleOnOneListCreate = {
          first_name: formValues.firstname,
          last_name: formValues.lastname,
          email: formValues.email,
          anonymous: formValues.anonymous,
          message: formValues.messages[index] ? formValues.messages[index] : "",
          wishes_ids: subList.wishes.map((wish) => wish.id),
        };
        bookingCreateMultiList.push(bookingCreateMulti);
      });

      this.props.addThread("createMultiBookingApiV1BookingMultiPost");
      bookingApi
        .createMultiBookingApiV1BookingMultiPost(bookingCreateMultiList)
        .then((response) => {
          const bookings = response.data;
          log.debug(`Successfully post wish bookings `, bookings);
          this.props.cleanBasket(true);
          this.props.addNotification({
            type: "success",
            description: "Cadeaux réservés",
            title: "Succès",
          });
          this.props.history.push(BBBRoutes.HOME.path);
        })
        .catch((error) => {
          log.error(`Error calling createBookingApiV1BookingPost`, error);
          const formatedError = error.response.data as BbbError;
          const descriptionError = formatedError.code
            ? this.getTraduction(`error.${formatedError.code}`)
            : formatedError.detail;
          this.props.addNotification({
            type: "error",
            description: descriptionError,
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("createMultiBookingApiV1BookingMultiPost");
        });
    }
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  cancel = (): void => {
    this.props.history.push(BBBRoutes.BASKET.path);
  };

  /* Render methods */
  renderCarousel(subList: SubBasketList, index: number): React.ReactNode {
    return (
      <React.Fragment key={index}>
        <Typography.Paragraph>
          <Space>
            <Typography.Text>{this.getTraduction("label.messages")}</Typography.Text>
            <Typography.Text> : </Typography.Text>
            {subList.birthRegistryTitle ? (
              <Typography.Text>{subList.birthRegistryTitle}</Typography.Text>
            ) : (
              <Typography.Text>{index}</Typography.Text>
            )}
          </Space>
        </Typography.Paragraph>
        <Form.Item
          name={["messages", index]}
          key={index}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ max: 5000, message: this.getTraduction("max.messages") }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
      </React.Fragment>
    );
  }

  render() {
    return (
      <BBBLayout mode="CENTER" titleSeo="summary.title" loading={this.state.loading}>
        <Space direction="vertical" size="large" className="full-width">
          <Typography.Paragraph>{this.getTraduction("explanations")}</Typography.Paragraph>
          <Form
            layout="horizontal"
            ref={this.state.formRef}
            labelCol={{ xs: 24, lg: 10 }}
            onValuesChange={this.valuesChange}
            onFieldsChange={this.onFieldsChange}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            scrollToFirstError={true}
          >
            <Form.Item
              name="firstname"
              label={this.getTraduction("label.firstname")}
              rules={[
                { required: true, message: this.getTraduction("mandatory.firstname") },
                { max: 255, message: this.getTraduction("max.firstname") },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastname"
              label={this.getTraduction("label.lastname")}
              rules={[
                { required: true, message: this.getTraduction("mandatory.lastname") },
                { max: 255, message: this.getTraduction("max.lastname") },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label={this.getTraduction("label.email")}
              validateTrigger="onBlur"
              rules={[
                { required: true, message: this.getTraduction("mandatory.email") },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: this.getTraduction("mailNotValid"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="anonymous"
              label={this.getTraduction("label.anonymous")}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <FormCarousel cancel={this.cancel} valid={this.submit}>
              {this.props.subList.map((subList, index) => this.renderCarousel(subList, index))}
            </FormCarousel>
          </Form>
        </Space>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    subList: state.basket.subList,
    authenticated: state.user.authenticated,
    currentUser: state.user.currentUser,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    cleanBasket: (payload: boolean) => dispatch(cleanBasket(payload)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BasketConfirm);
