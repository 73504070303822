/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Row, Col, Typography } from "antd";
import { BbbButton, ShortLinkEditModal, ShortLinkShareModal } from "components/shared";
import { TwitterOutlined, FacebookOutlined, MailOutlined } from "@ant-design/icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
/****************** STYLING ******************/
import "./ShortLinkBar.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { ShortLink } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
import { healthcheckApiFactory } from "config";
import { ShareChannel } from "./ShortLinkShareModal";
/****************** RENDERING (export) ******************/
type InputProps = { birthRegistryId: number; title: string; existingShortLink?: ShortLink };
type RouteParams = { id: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = {
  lang: "en" | "fr" | "de";
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = {
  loading: boolean;
  displayShortLinkForm: boolean;
  displayShareModal: boolean;
  shareChannel: ShareChannel;
  frontendBaseUrl?: string;
  currentShortLink?: ShortLink;
};

class ShortLinkBar extends React.Component<Props, State> {
  state: State = {
    loading: false,
    displayShortLinkForm: false,
    displayShareModal: false,
    shareChannel: "MAIL",
    currentShortLink: undefined,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    this.setState((state: State) => ({ ...state, loading: true }));
    const healthcheckApi = healthcheckApiFactory();
    this.props.addThread("getFrontendBaseUrlFrontendUrlGet");
    healthcheckApi
      .getFrontendBaseUrlFrontendUrlGet()
      .then((response) => {
        let frontendBaseUrl = response.data;
        this.setState((state: State) => ({
          ...state,
          frontendBaseUrl: frontendBaseUrl,
          currentShortLink: this.props.existingShortLink,
        }));
        log.info(`Successfully fetch /frontendUrl `, frontendBaseUrl);
      })
      .catch((error) => {
        log.error(`Error fetching /frontendUrl `, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de récupérer l'adresse du frontend'",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("getFrontendBaseUrlFrontendUrlGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `${id}` }, param);
  };

  openEditModal = (): void => {
    this.setState((state: State) => ({ ...state, displayShortLinkForm: true }));
  };

  onCloseEditModal = (newLink?: ShortLink): void => {
    this.setState((state: State) => ({
      ...state,
      currentShortLink: newLink ?? state.currentShortLink,
      displayShortLinkForm: false,
    }));
  };

  shareMail = (): void => {
    log.debug("shareMail");
    this.setState((state: State) => ({
      ...state,
      shareChannel: "MAIL",
      displayShareModal: true,
    }));
  };

  onCloseShareModal = (): void => {
    this.setState((state: State) => ({ ...state, displayShareModal: false }));
  };

  shareFacebook = (): void => {
    log.debug("shareFacebook");
    this.setState((state: State) => ({
      ...state,
      shareChannel: "FACEBOOK",
    }));
  };

  shareTwitter = (): void => {
    log.debug("shareTwitter");
    this.setState((state: State) => ({
      ...state,
      shareChannel: "TWITTER",
    }));
  };

  shortLinkFullUrl = (): string | undefined => {
    if (this.state.currentShortLink && this.state.frontendBaseUrl) {
      let shortlinkName = this.state.currentShortLink.name;
      const customPath = this.props.lang === "fr" ? "/ma-liste/" : "/my-list/";
      return this.state.frontendBaseUrl.concat(customPath).concat(shortlinkName);
    }
  };

  copyShortlinkToClipboard = (url: string): void => {
    navigator.clipboard.writeText(url).then((r) =>
      this.props.addNotification({
        type: "success",
        description: "URL copiée dans le presse papier",
        title: "Succès",
      })
    );
  };

  /* Render methods */
  render() {
    const shortLinkFullUrl = this.shortLinkFullUrl();
    return (
      <>
        <Card className="detailShortlink">
          <Row align="middle" justify="space-between" gutter={[20, 20]}>
            <Col xs={12} lg={4}>
              <FormattedMessage id={`shortLink.share.title`} />
            </Col>
            <Col xs={3} lg={1}>
              {shortLinkFullUrl !== undefined && (
                <BbbButton type="ghost" onClick={this.shareMail} icon={<MailOutlined />} />
              )}
            </Col>
            <Col xs={3} lg={1}>
              {shortLinkFullUrl !== undefined && (
                <FacebookShareButton
                  className="ant-btn ant-btn-ghost ant-btn-icon-only"
                  url={shortLinkFullUrl}
                  onClick={this.shareFacebook}
                >
                  <FacebookOutlined />
                </FacebookShareButton>
              )}
            </Col>
            <Col xs={3} lg={1}>
              {shortLinkFullUrl !== undefined && (
                <TwitterShareButton
                  className="ant-btn ant-btn-ghost ant-btn-icon-only"
                  url={shortLinkFullUrl}
                  title={`${this.props.title} ${this.getTraduction("shortLink.share.onBBB")}`}
                  hashtags={[
                    this.getTraduction(`shortLink.share.hashtag1`),
                    this.getTraduction(`shortLink.share.hashtag2`),
                  ]}
                  onClick={this.shareTwitter}
                >
                  <TwitterOutlined />
                </TwitterShareButton>
              )}
            </Col>
            <Col xs={24} md={12} lg={6} style={{ fontSize: "12px" }}>
              <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={{ tooltip: true }}>
                {shortLinkFullUrl}
              </Typography.Paragraph>
            </Col>
            <Col xs={12} md={6} lg={3}>
              {shortLinkFullUrl !== undefined && (
                <BbbButton
                  type="primary"
                  shape="round"
                  onClick={() => this.copyShortlinkToClipboard(shortLinkFullUrl)}
                >
                  <FormattedMessage id={`shortLink.copy`} />
                </BbbButton>
              )}
            </Col>
            <Col xs={12} md={6} lg={3}>
              <BbbButton key="link" onClick={this.openEditModal}>
                <FormattedMessage id={`shortLink.edit`} />
              </BbbButton>
            </Col>
          </Row>
        </Card>
        {this.state.displayShortLinkForm && (
          <ShortLinkEditModal
            visible={this.state.displayShortLinkForm}
            birthRegistryId={this.props.birthRegistryId}
            currentShortLink={this.state.currentShortLink}
            onCloseEdit={this.onCloseEditModal}
          ></ShortLinkEditModal>
        )}
        {shortLinkFullUrl !== undefined && (
          <ShortLinkShareModal
            visible={this.state.displayShareModal}
            birthRegistryId={this.props.birthRegistryId}
            shareChannel={this.state.shareChannel}
            shortLinkFullUrl={shortLinkFullUrl}
            onCloseShare={this.onCloseShareModal}
          ></ShortLinkShareModal>
        )}
      </>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    lang: state.config.lang,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShortLinkBar);
