import { UserAction } from "store/actions";
import { LOGIN, SET_USER } from "store/constants";
import { User } from "bbb-api/dist/models";
import { RawRuleOf } from "@casl/ability";
import { AppAbility } from "config";

export interface StateUser {
  authenticated: boolean;
  currentUser?: User;
  rules: RawRuleOf<AppAbility>[];
}

export const initialStateUser: StateUser = {
  authenticated: false,
  currentUser: undefined,
  rules: [
    {
      action: ["read"],
      subject: "BirthRegistry",
    },
  ],
};

export function userReducer(state: StateUser = initialStateUser, action: UserAction): StateUser {
  switch (action.type) {
    case LOGIN: {
      if (action.payload === true) {
        return { ...state, authenticated: action.payload, rules: [] };
      } else {
        return initialStateUser;
      }
    }
    case SET_USER: {
      let newRules: RawRuleOf<AppAbility>[] = [];
      if (action.payload.is_superuser) {
        newRules = [
          {
            action: ["manage"],
            subject: ["all"],
          },
        ];
      } else {
        newRules = [
          ...initialStateUser.rules,
          {
            action: ["manage"],
            subject: "BirthRegistry",
            conditions: { user_id: action.payload.id },
          },
        ];
      }
      return { ...state, currentUser: action.payload, rules: newRules };
    }
    default:
      return state;
  }
}
