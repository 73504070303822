"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Bienvenue Bébé
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordResetApi = exports.PasswordResetApiFactory = exports.PasswordResetApiFp = exports.PasswordResetApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * PasswordResetApi - axios parameter creator
 * @export
 */
exports.PasswordResetApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
         * @summary Request Password Reset
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetApiV1PasswordResetRequestResetPost: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    common_1.assertParamExists('requestPasswordResetApiV1PasswordResetRequestResetPost', 'email', email);
                    localVarPath = "/api/v1/password-reset/request-reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
         * @summary Request Password Reset
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetApiV1PasswordResetRequestResetPost_1: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    common_1.assertParamExists('requestPasswordResetApiV1PasswordResetRequestResetPost_1', 'email', email);
                    localVarPath = "/api/v1/password-reset/request-reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
         * @summary Set New Password
         * @param {string} token
         * @param {string} newPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPasswordApiV1PasswordResetSetNewPost: function (token, newPassword, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('setNewPasswordApiV1PasswordResetSetNewPost', 'token', token);
                    // verify required parameter 'newPassword' is not null or undefined
                    common_1.assertParamExists('setNewPasswordApiV1PasswordResetSetNewPost', 'newPassword', newPassword);
                    localVarPath = "/api/v1/password-reset/set-new/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (token !== undefined) {
                        localVarQueryParameter['token'] = token;
                    }
                    if (newPassword !== undefined) {
                        localVarQueryParameter['new_password'] = newPassword;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
         * @summary Set New Password
         * @param {string} token
         * @param {string} newPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPasswordApiV1PasswordResetSetNewPost_2: function (token, newPassword, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('setNewPasswordApiV1PasswordResetSetNewPost_2', 'token', token);
                    // verify required parameter 'newPassword' is not null or undefined
                    common_1.assertParamExists('setNewPasswordApiV1PasswordResetSetNewPost_2', 'newPassword', newPassword);
                    localVarPath = "/api/v1/password-reset/set-new/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (token !== undefined) {
                        localVarQueryParameter['token'] = token;
                    }
                    if (newPassword !== undefined) {
                        localVarQueryParameter['new_password'] = newPassword;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Quick check if the token is valid
         * @summary Verify Token Validity
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost: function (token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost', 'token', token);
                    localVarPath = "/api/v1/password-reset/verify-token-validity";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (token !== undefined) {
                        localVarQueryParameter['token'] = token;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Quick check if the token is valid
         * @summary Verify Token Validity
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3: function (token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3', 'token', token);
                    localVarPath = "/api/v1/password-reset/verify-token-validity";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (token !== undefined) {
                        localVarQueryParameter['token'] = token;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PasswordResetApi - functional programming interface
 * @export
 */
exports.PasswordResetApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PasswordResetApiAxiosParamCreator(configuration);
    return {
        /**
         * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
         * @summary Request Password Reset
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetApiV1PasswordResetRequestResetPost: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestPasswordResetApiV1PasswordResetRequestResetPost(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
         * @summary Request Password Reset
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetApiV1PasswordResetRequestResetPost_1: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.requestPasswordResetApiV1PasswordResetRequestResetPost_1(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
         * @summary Set New Password
         * @param {string} token
         * @param {string} newPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPasswordApiV1PasswordResetSetNewPost: function (token, newPassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setNewPasswordApiV1PasswordResetSetNewPost(token, newPassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
         * @summary Set New Password
         * @param {string} token
         * @param {string} newPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPasswordApiV1PasswordResetSetNewPost_2: function (token, newPassword, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.setNewPasswordApiV1PasswordResetSetNewPost_2(token, newPassword, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Quick check if the token is valid
         * @summary Verify Token Validity
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost: function (token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost(token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Quick check if the token is valid
         * @summary Verify Token Validity
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3: function (token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3(token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PasswordResetApi - factory interface
 * @export
 */
exports.PasswordResetApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PasswordResetApiFp(configuration);
    return {
        /**
         * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
         * @summary Request Password Reset
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetApiV1PasswordResetRequestResetPost: function (email, options) {
            return localVarFp.requestPasswordResetApiV1PasswordResetRequestResetPost(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
         * @summary Request Password Reset
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetApiV1PasswordResetRequestResetPost_1: function (email, options) {
            return localVarFp.requestPasswordResetApiV1PasswordResetRequestResetPost_1(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
         * @summary Set New Password
         * @param {string} token
         * @param {string} newPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPasswordApiV1PasswordResetSetNewPost: function (token, newPassword, options) {
            return localVarFp.setNewPasswordApiV1PasswordResetSetNewPost(token, newPassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
         * @summary Set New Password
         * @param {string} token
         * @param {string} newPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPasswordApiV1PasswordResetSetNewPost_2: function (token, newPassword, options) {
            return localVarFp.setNewPasswordApiV1PasswordResetSetNewPost_2(token, newPassword, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Quick check if the token is valid
         * @summary Verify Token Validity
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost: function (token, options) {
            return localVarFp.verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost(token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Quick check if the token is valid
         * @summary Verify Token Validity
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3: function (token, options) {
            return localVarFp.verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3(token, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PasswordResetApi - object-oriented interface
 * @export
 * @class PasswordResetApi
 * @extends {BaseAPI}
 */
var PasswordResetApi = /** @class */ (function (_super) {
    __extends(PasswordResetApi, _super);
    function PasswordResetApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
     * @summary Request Password Reset
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    PasswordResetApi.prototype.requestPasswordResetApiV1PasswordResetRequestResetPost = function (email, options) {
        var _this = this;
        return exports.PasswordResetApiFp(this.configuration).requestPasswordResetApiV1PasswordResetRequestResetPost(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Request a password reset Called by frontend;     sends an email to the user with a link to reset the password     if the user is not found, does not send any email, but still returns a success message to the frontend
     * @summary Request Password Reset
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    PasswordResetApi.prototype.requestPasswordResetApiV1PasswordResetRequestResetPost_1 = function (email, options) {
        var _this = this;
        return exports.PasswordResetApiFp(this.configuration).requestPasswordResetApiV1PasswordResetRequestResetPost_1(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
     * @summary Set New Password
     * @param {string} token
     * @param {string} newPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    PasswordResetApi.prototype.setNewPasswordApiV1PasswordResetSetNewPost = function (token, newPassword, options) {
        var _this = this;
        return exports.PasswordResetApiFp(this.configuration).setNewPasswordApiV1PasswordResetSetNewPost(token, newPassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set a new password This is called by frontend after clicking on the link in the email We assume that frontend has already checked the token and that it is valid (will double-check) We assume that frontend has already checked that the new password is not too weak We assume that frontend has already checked that the new password has been confirmed (2 inputs)
     * @summary Set New Password
     * @param {string} token
     * @param {string} newPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    PasswordResetApi.prototype.setNewPasswordApiV1PasswordResetSetNewPost_2 = function (token, newPassword, options) {
        var _this = this;
        return exports.PasswordResetApiFp(this.configuration).setNewPasswordApiV1PasswordResetSetNewPost_2(token, newPassword, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Quick check if the token is valid
     * @summary Verify Token Validity
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    PasswordResetApi.prototype.verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost = function (token, options) {
        var _this = this;
        return exports.PasswordResetApiFp(this.configuration).verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost(token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Quick check if the token is valid
     * @summary Verify Token Validity
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    PasswordResetApi.prototype.verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3 = function (token, options) {
        var _this = this;
        return exports.PasswordResetApiFp(this.configuration).verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost_3(token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PasswordResetApi;
}(base_1.BaseAPI));
exports.PasswordResetApi = PasswordResetApi;
