/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Col, Modal, Row, Spin, Typography, Image } from "antd";
import { BbbButton, BbbUpload } from "components/shared";
/****************** STYLING ******************/
import "./ImagePickerModal.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { S3BucketName, S3Image } from "bbb-api/dist/models";
import { addThread, removeThread, addNotification } from "store/actions";
import { RcFile } from "rc-upload/lib/interface";
import { prepareUrl } from "config/constants";
import { s3ApiFactory } from "config";
import * as _ from "lodash";

/****************** RENDERING (export) ******************/
type InputProps = {
  validate(file?: RcFile, image_path?: string, thumbnail_path?: string): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;

type MapStateToProps = {
  imageCatalogFeature: boolean;
};

type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type State = {
  visible: boolean;
  loading: boolean;
  images: S3Image[];
  selection?: number;
};

class ImagePickerModal extends React.Component<Props, State> {
  state: State = {
    visible: false,
    loading: true,
    images: [],
    selection: undefined,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    this.refreshImages();
    this.setState((state: State) => ({ ...state, selection: undefined }));
  }
  /* Handlers methods */
  refreshImages() {
    this.setState({ loading: true });
    const s3Api = s3ApiFactory();
    this.props.addThread("listHeaderDefaultImagesApiV1S3DefaultImagesHeaderGet");
    s3Api
      .listHeaderDefaultImagesApiV1S3DefaultImagesHeaderGet()
      .then((response) => {
        const images: S3Image[] = response.data;
        this.setState({ images });
      })
      .catch((error) => {
        log.error(`Error fetching listHeaderDefaultImagesApiV1S3DefaultImagesHeaderGet `, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de récupérer la liste des images  de la bibliothèque",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("listHeaderDefaultImagesApiV1S3DefaultImagesHeaderGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }

  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `imagePickerModal.${id}` }, param);
  };

  closeModal = () => {
    this.setState((state: State) => ({ ...state, visible: false }));
  };

  openModal = () => {
    this.setState((state: State) => ({ ...state, visible: true }));
  };

  validateLib = () => {
    log.info("Validate", this.state.selection);
    if (!_.isUndefined(this.state.selection)) {
      const image: S3Image = this.state.images[this.state.selection];
      this.props.validate(undefined, image.image_path, image.thumbnail_path);
      this.closeModal();
    }
  };

  validateManual = (file?: RcFile, image_path?: string, thumbnail_path?: string) => {
    log.info("Validate", this.state.selection);
    this.setState((state: State) => ({ ...state, selection: undefined }));
    this.props.validate(file, image_path, thumbnail_path);
    this.closeModal();
  };

  /* Render methods */
  render() {
    const { imageCatalogFeature } = this.props;
    const { visible, loading, images, selection } = this.state;
    if (imageCatalogFeature) {
      return (
        <React.Fragment>
          <Modal
            title={<Typography.Text strong={true}>{this.getTraduction(`title`)}</Typography.Text>}
            visible={visible}
            onCancel={this.closeModal}
            width={800}
            destroyOnClose={true}
            footer={[
              <Row key={1} justify="space-around" wrap={false}>
                <BbbUpload
                  handleUploadChange={this.validateManual}
                  className="modalUpload multi-line"
                  bucket={S3BucketName.ListHeader}
                  button_add={this.getTraduction("manual")}
                  button_edit={this.getTraduction("manual")}
                  maxCount={1}
                  showUploadList={false}
                  includeForm={false}
                  accept="image/*"
                />

                <BbbButton
                  className="bbb-btn-light-primary multi-line"
                  key="primary"
                  type="primary"
                  disabled={_.isUndefined(this.state.selection)}
                  onClick={this.validateLib}
                  loading={loading}
                >
                  <FormattedMessage id="imagePickerModal.valid" />
                </BbbButton>
              </Row>,
            ]}
          >
            <div className="bbbCarousel">
              <Row justify="space-around" align="middle">
                <Col span={24}>
                  {loading ? (
                    <Row justify="center">
                      <Spin />
                    </Row>
                  ) : (
                    <div className="grid">
                      <Image.PreviewGroup>
                        {images.map((image, index) => (
                          <Image
                            key={index}
                            className={index === selection ? "selected" : ""}
                            onClick={() =>
                              this.setState((state: State) => ({ ...state, selection: index }))
                            }
                            preview={false}
                            src={prepareUrl(image.thumbnail_path)}
                            placeholder={
                              <Row className="placeholder" justify="center" align="middle">
                                <Spin />
                              </Row>
                            }
                          />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Modal>
          <BbbButton key="primary" type="primary" shape="round" onClick={this.openModal}>
            <FormattedMessage id="imagePickerModal.open" />
          </BbbButton>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    imageCatalogFeature: state.config.features.imageCatalogFeature,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ImagePickerModal);
