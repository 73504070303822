/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";

/****************** DEPENDENCIES : COMPONENTS ******************/

/****************** STYLING ******************/
import "./FormCarouselPage.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
/****************** RENDERING (export) ******************/
type InputProps = {
  onEnter: () => void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;

type MapStateToProps = {};
type MapDispatchToProps = {};

type State = {};

class FormCarouselPage extends React.Component<Props, State> {
  state: State = {};
  /* LifeCycle Methods */

  /* Handlers methods */
  onKeyUp = (event: any) => {
    if (event.key === "Enter" && event.target?.tagName !== "TEXTAREA") {
      this.props.onEnter();
    }
  };

  /* Render methods */
  render() {
    return (
      <div className="page" onKeyUp={(event) => this.onKeyUp(event)}>
        {this.props.children}
      </div>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(FormCarouselPage);
