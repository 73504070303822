/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BabiesList, BBBLayout } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { babyApiFactory } from "config";
import { Baby, ObjectDeleted } from "bbb-api/dist/models";
import { addThread, removeThread, addNotification } from "store/actions";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = { babies: Baby[]; loading: boolean };

class BabiesAdmin extends React.Component<Props, State> {
  state: State = { loading: true, babies: [] };

  /* LifeCycle Methods */
  componentDidMount() {
    const babyApi = babyApiFactory();
    this.props.addThread("getAllBabiesApiV1BabyGet");
    babyApi
      .getAllBabiesApiV1BabyGet()
      .then((response) => {
        const babies: Baby[] = response.data;
        log.info(`Successfully fetch /babies `, babies);
        this.setState((state: State) => ({ ...state, babies: babies }));
      })
      .catch((error) => {
        log.error(`Error fetching /babies`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de récupérer la liste des bébés",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("getAllBabiesApiV1BabyGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }
  /* Handlers methods */
  addElement = (baby: Baby): void => {
    this.setState((state: State) => ({
      ...state,
      babies: [...state.babies, baby],
    }));
  };

  updateElement = (babyForm: Baby): void => {
    const babiesUpdated = _.map(this.state.babies, (baby) => {
      if (babyForm.id === baby.id) {
        return babyForm;
      } else {
        return baby;
      }
    });
    this.setState((state: State) => ({
      ...state,
      babies: babiesUpdated,
    }));
  };

  deleteElement = (babyDelete: ObjectDeleted): void => {
    const babiesUpdated: Baby[] = _.filter(this.state.babies, (baby) => babyDelete.id !== baby.id);
    this.setState((state: State) => ({
      ...state,
      babies: babiesUpdated,
    }));
  };

  /* Render methods */
  render() {
    if (this.state.loading) {
      return (
        <BBBLayout
          mode="CENTER"
          titleSeo="babiesList.title.ADMIN"
          loading={this.state.loading}
        ></BBBLayout>
      );
    } else {
      return (
        <BBBLayout mode="FULL" titleSeo="babiesList.title.ADMIN" loading={this.state.loading}>
          <BabiesList
            mode="ADMIN"
            babies={this.state.babies}
            addElement={this.addElement}
            updateElement={this.updateElement}
            deleteElement={this.deleteElement}
          ></BabiesList>
        </BBBLayout>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BabiesAdmin);
