/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BirthRegistryForm } from "components/shared";

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";
import { addElement, updateElement, deleteElement } from "store/actions";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {
  selectedElement?: BirthRegistry;
  changePage(subpage: "NEW" | "ADDRESS" | "BABIES"): void;
};
type Props = InputProps & MapStateToProps & MapDispatchToProps & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {
  addElement(birthRegistry: BirthRegistry): void;
  updateElement(birthRegistry: BirthRegistry): void;
  deleteElement(birthRegistry: BirthRegistry): void;
};

type State = { loading: boolean };

class BirthRegistryFormContainer extends React.Component<Props, State> {
  state: State = { loading: true };

  /* LifeCycle Methods */

  /* Handlers methods */
  addElement = (birthRegistry: BirthRegistry): void => {
    this.props.addElement(birthRegistry);
    this.backToList();
  };

  updateElement = (birthRegistryForm: BirthRegistry): void => {
    this.props.updateElement(birthRegistryForm);
    this.backToList();
  };

  deleteElement = (birthRegistryDelete: BirthRegistry): void => {
    this.props.deleteElement(birthRegistryDelete);
  };

  backToList = (): void => {
    this.props.history.push(BBBRoutes.USER_BIRTHREGISTRIES.path);
  };

  /* Render methods */
  render() {
    return (
      <BirthRegistryForm
        addElement={this.addElement}
        updateElement={this.updateElement}
        cancel={this.backToList}
        birthRegistry={this.props.selectedElement}
        changePage={this.props.changePage}
      ></BirthRegistryForm>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addElement: (birthRegistry: BirthRegistry) => dispatch(addElement(birthRegistry)),
    updateElement: (birthRegistry: BirthRegistry) => dispatch(updateElement(birthRegistry)),
    deleteElement: (birthRegistry: BirthRegistry) => dispatch(deleteElement(birthRegistry)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryFormContainer);
