import * as constants from "store/constants";
import { User } from "bbb-api/dist/models";

export type UserAction = LoginType | SetCurrentUserType;

export interface LoginType {
  type: constants.LOGIN;
  payload: boolean;
}

export function authenticate(auth: boolean): LoginType {
  return {
    type: constants.LOGIN,
    payload: auth,
  };
}

export interface SetCurrentUserType {
  type: constants.SET_USER;
  payload: User;
}

export function setCurrentUser(user: User): SetCurrentUserType {
  return {
    type: constants.SET_USER,
    payload: user,
  };
}
