/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Table, Row, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";
import { TableRowSelection, ColumnsType } from "antd/lib/table/interface";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistries: BirthRegistry[];
  deleteElement(birthRegistry: BirthRegistry): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = { selectedElement?: BirthRegistry };

class BirthRegistryTable extends React.Component<Props, State> {
  state: State = { selectedElement: undefined };

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `birthRegistryTable.${id}` }, param);
  };

  deleteBirthRegistry = (record: BirthRegistry): void => {
    log.info(`deleteBirthRegistry`, record);
    this.setState((state: State) => ({ ...state, selectedElement: record }));
    this.props.deleteElement(record);
  };

  /* Render methods */
  render() {
    const columns: ColumnsType<BirthRegistry> = [
      {
        title: this.getTraduction("columns.title"),
        dataIndex: "title",
        sorter: true,
      },
      {
        title: this.getTraduction("columns.event_date"),
        dataIndex: "event_date",
        sorter: true,
      },
      {
        title: this.getTraduction("columns.address_owner"),
        dataIndex: "address_owner",
        sorter: true,
      },
      {
        title: this.getTraduction("columns.is_online"),
        dataIndex: "is_online",
        sorter: true,
        render: (value: any, record: BirthRegistry, index: number) => {
          return <React.Fragment>{record.is_online?.toString()}</React.Fragment>;
        },
      },
      {
        title: "Privée",
        dataIndex: "is_private",
        sorter: true,
        render: (value: any, record: BirthRegistry, index: number) => {
          return <React.Fragment>{record.is_private?.toString()}</React.Fragment>;
        },
      },
      {
        title: this.getTraduction("columns.actions"),
        dataIndex: "actions",
        render: (value: any, record: BirthRegistry, index: number) => {
          return (
            <Row>
              <BbbButton className="ml10">
                <Link to={BBBRoutes.BIRTHREGISTRY_DETAIL_BY_ID.computePath({ id: record.id })}>
                  <FormattedMessage id="birthRegistryTable.editContent" />
                </Link>
              </BbbButton>
              <Popconfirm
                icon={<DeleteOutlined className="red" />}
                title={<FormattedMessage id="birthRegistryTable.delete.confirm" />}
                okText={<FormattedMessage id="birthRegistryTable.delete.ok" />}
                okType="danger"
                cancelText={<FormattedMessage id="birthRegistryTable.delete.cancel" />}
                onConfirm={(e) => this.deleteBirthRegistry(record)}
              >
                <BbbButton type="dashed" danger className="ml10">
                  <FormattedMessage id="birthRegistryTable.delete.action" />
                </BbbButton>
              </Popconfirm>
            </Row>
          );
        },
      },
    ];
    const rowSelection: TableRowSelection<BirthRegistry> = {
      selectedRowKeys: this.state.selectedElement ? [this.state.selectedElement.id] : undefined,
      hideSelectAll: true,
      renderCell: () => {
        return null;
      },
    };
    return (
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={this.props.birthRegistries}
        columns={columns}
      />
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryTable);
