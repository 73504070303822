/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Col, List, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import BirthRegistryCard from "./BirthRegistryCard";
import BirthRegistryTable from "./BirthRegistryTable";
/****************** STYLING ******************/
import "./BirthRegistriesList.less";
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";

import { birthRegistryApiFactory } from "config";
import { addNotification, addThread, removeThread } from "store/actions";
import { BBBRoutes } from "routes";
import { BbbButton } from "components/shared/buttons";

/****************** RENDERING (export) ******************/
type InputProps = {
  mode: "MY" | "ADMIN";
  birthRegistries: BirthRegistry[];
  deleteElement(birthRegistry: BirthRegistry): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = { loading: boolean };

class BirthRegistriesList extends React.Component<Props, State> {
  static defaultProps: Pick<Props, "mode"> = {
    mode: "MY",
  };

  state: State = { loading: false };

  /* LifeCycle Methods */

  /* Handlers methods */
  createNewList = (): void => {
    this.props.history.push(BBBRoutes.BIRTHREGISTRY_NEW.path);
  };

  deleteElement = (record: BirthRegistry): void => {
    const birthRegistryApi = birthRegistryApiFactory();
    this.props.addThread("deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete");
    birthRegistryApi
      .deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete(record.id)
      .then((response) => {
        const operationCode = response.data;
        log.info(
          `Successfully delete /birthRegistries/${record.id} with code ${operationCode.id}`,
          record
        );
        this.props.deleteElement(record);
      })
      .catch((error) => {
        log.error(`Error deleting /birthRegistries/${record.id}`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de supprimer la lite de naissance",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("deleteBirthRegistryApiV1BirthregistryBirthRegistryIdDelete");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
    this.setState((state: State) => ({ ...state, displayForm: false }));
  };

  /* Render methods */
  render() {
    if (this.props.mode === "ADMIN") {
      return (
        <BirthRegistryTable
          birthRegistries={this.props.birthRegistries}
          deleteElement={this.deleteElement}
        ></BirthRegistryTable>
      );
    } else {
      return (
        <List itemLayout="horizontal" grid={{ gutter: 16 }}>
          {_.map(this.props.birthRegistries, (birthRegistry) => (
            <List.Item key={birthRegistry.id}>
              <BirthRegistryCard
                birthRegistry={birthRegistry}
                deleteElement={this.deleteElement}
              ></BirthRegistryCard>
            </List.Item>
          ))}
          <List.Item>
            <Row justify="center">
              <BbbButton
                icon={<PlusOutlined />}
                size="large"
                key="primary"
                type="primary"
                onClick={this.createNewList}
              >
                <span className="ml10">
                  <FormattedMessage id="birthRegistriesList.add" />
                </span>
              </BbbButton>
            </Row>
          </List.Item>
          {_.size(this.props.birthRegistries) === 0 && (
            <Row justify="center">
              <Col span={12}>
                <Card className="mt10">
                  <Card.Meta
                    title={<FormattedMessage id="birthRegistriesList.soon" />}
                    description={<FormattedMessage id="birthRegistriesList.invite" />}
                  />
                </Card>
              </Col>
            </Row>
          )}
        </List>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistriesList);
