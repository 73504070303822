/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BbbButton, BBBLayout } from "components/shared";
import { ShipmentInfo } from "components/shared/shipment";
import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import logo from "../../assets/logo.svg";

/****************** TYPES ******************/
import { Store, Notification } from "store/reducers";
import { addNotification, addThread, removeBasket, removeThread } from "store/actions";
import { BasketItem, SubBasketList } from "store/reducers/basket";
import { birthRegistryApiFactory } from "config";
import { BirthRegistryAddress, Wish } from "bbb-api";
import { prepareUrl } from "../../config/constants";
import { ColumnsType } from "antd/lib/table";
import { BBBRoutes } from "routes";
/****************** STYLING ******************/
import "./BasketRecap.less";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {
  subList: SubBasketList[];
  basketSize: number;
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  removeBasket: (basketItem: BasketItem) => void;
};
type State = {
  loading: boolean;
  adresses: BirthRegistryAddress[];
};

class BasketRecap extends React.Component<Props, State> {
  state: State = {
    loading: false,
    adresses: [],
  };

  /* LifeCycle Methods */
  componentDidMount() {
    _.map(this.props.subList, (subList, index) => {
      const birthRegistryApi = birthRegistryApiFactory();
      this.props.addThread("getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet");
      birthRegistryApi
        .getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet(subList.birthRegistryId)
        .then((response) => {
          const newAdress: BirthRegistryAddress = response.data;
          log.info(`Successfully fetch /birthRegistries/${subList.birthRegistryId} `, newAdress);

          this.setState((state: State) => {
            let copyUpdated = _.cloneDeep(state.adresses);
            copyUpdated.splice(index, 0, newAdress);
            return {
              ...state,
              adresses: copyUpdated,
            };
          });
        })
        .catch((error) => {
          log.error(`Error fetching /birthRegistries/${subList.birthRegistryId}`, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de récupérer la liste de naissance",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread(
            "getBirthRegistryAddressApiV1BirthregistryPublicBirthRegistryIdGet"
          );
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    });
  }
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `basket.${id}` }, param);
  };

  computeSubTotal(wish: Wish) {
    let subTotal = 0;
    if (wish?.price && wish.quantity) {
      subTotal = wish.price * wish.quantity;
    }
    return subTotal;
  }

  deleteWishFromBasket(birthRegistryId: number, wish: Wish) {
    this.props.removeBasket({ birthRegistryId, wish });
  }

  confirmBasket = () => {
    this.props.history.push(BBBRoutes.SUMMARY.path);
  };

  /* Render methods */
  renderOneSubListInTab(subList: SubBasketList, indexList: number) {
    const data: Wish[] = subList.wishes;
    const columns: ColumnsType<Wish> = [
      {
        title: this.getTraduction("table.wish"),
        render: (value: any, wish: Wish, indexRow: number) => (
          <Row gutter={[16, 16]} align="middle" key={indexRow}>
            <Col span={2}>
              <DeleteOutlined
                onClick={(e) => this.deleteWishFromBasket(subList.birthRegistryId, wish)}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <div className="cover">
                <img src={wish?.img_path ? prepareUrl(wish.img_path) : logo} alt="logo" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              <Link
                to={BBBRoutes.WISH_DETAIL.computePath({
                  id: subList.birthRegistryId,
                  wishId: wish.id,
                })}
              >
                {wish.title}
              </Link>
            </Col>
          </Row>
        ),
      },
      {
        title: this.getTraduction("table.price"),
        width: "10%",
        dataIndex: "price",
        render: (value: any, wish: Wish, indexRow: number) => <>{wish.price}&nbsp;€</>,
      },
      { title: this.getTraduction("table.quantity"), width: "10%", dataIndex: "quantity" },
      {
        title: this.getTraduction("table.subtotal"),
        width: "10%",
        render: (value: any, wish: Wish, indexRow: number) => (
          <>{this.computeSubTotal(wish)}&nbsp;€</>
        ),
      },
    ];
    return (
      <React.Fragment key={indexList}>
        <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
          <ShipmentInfo adress={this.state.adresses[indexList]}></ShipmentInfo>
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
          <Table dataSource={data} columns={columns}></Table>
        </Col>
      </React.Fragment>
    );
  }

  render() {
    if (this.props.basketSize !== 0) {
      return (
        <BBBLayout mode="FULL" titleSeo="basket.title" loading={this.state.loading}>
          <Row gutter={[16, 16]} align="stretch" justify="space-between" className="basket">
            {_.map(this.props.subList, (subList, index) => {
              return this.renderOneSubListInTab(subList, index);
            })}
          </Row>
          <Row justify="center">
            <BbbButton type="primary" size="large" onClick={this.confirmBasket}>
              <FormattedMessage id="basket.confirm"></FormattedMessage>
            </BbbButton>
          </Row>
        </BBBLayout>
      );
    } else {
      return (
        <BBBLayout mode="FULL" titleSeo="basket.title" loading={false}>
          <Row justify={"center"}>
            <FormattedMessage id="basket.empty"></FormattedMessage>
          </Row>
        </BBBLayout>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    subList: state.basket.subList,
    basketSize: state.basket.basketSize,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    removeBasket: (basketItem: BasketItem) => dispatch(removeBasket(basketItem)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BasketRecap);
