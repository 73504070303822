import * as _ from "lodash";
import {
  Configuration,
  LoginApi,
  UserApi,
  BabyApi,
  BirthregistryApi,
  WishApi,
  ShortlinkApi,
  CategoryApi,
  S3Api,
  BookingApi,
  PasswordResetApi,
  ScraperApi,
  EmailApi,
  HealthcheckApi,
} from "bbb-api";
import { REACT_APP_BBB_API_URL } from "./constants";

export const configurationFactory = (): Configuration => {
  const jwt = localStorage.getItem("bbb");
  const configuration = new Configuration({
    basePath: REACT_APP_BBB_API_URL,
    accessToken: !_.isNil(jwt) ? jwt : undefined,
  });
  return configuration;
};

export const loginApiFactory = (defaultConfig?: Configuration): LoginApi => {
  if (defaultConfig) {
    return new LoginApi(defaultConfig);
  } else {
    return new LoginApi(configurationFactory());
  }
};

export const passwordApiFactory = (defaultConfig?: Configuration): PasswordResetApi => {
  if (defaultConfig) {
    return new PasswordResetApi(defaultConfig);
  } else {
    return new PasswordResetApi(configurationFactory());
  }
};

export const userApiFactory = (defaultConfig?: Configuration): UserApi => {
  if (defaultConfig) {
    return new UserApi(defaultConfig);
  } else {
    return new UserApi(configurationFactory());
  }
};

export const babyApiFactory = (defaultConfig?: Configuration): BabyApi => {
  if (defaultConfig) {
    return new BabyApi(defaultConfig);
  } else {
    return new BabyApi(configurationFactory());
  }
};

export const birthRegistryApiFactory = (defaultConfig?: Configuration): BirthregistryApi => {
  if (defaultConfig) {
    return new BirthregistryApi(defaultConfig);
  } else {
    return new BirthregistryApi(configurationFactory());
  }
};

export const wishApiFactory = (defaultConfig?: Configuration): WishApi => {
  if (defaultConfig) {
    return new WishApi(defaultConfig);
  } else {
    return new WishApi(configurationFactory());
  }
};

export const categoryApiFactory = (defaultConfig?: Configuration): CategoryApi => {
  if (defaultConfig) {
    return new CategoryApi(defaultConfig);
  } else {
    return new CategoryApi(configurationFactory());
  }
};

export const shortLinkApiFactory = (defaultConfig?: Configuration): ShortlinkApi => {
  if (defaultConfig) {
    return new ShortlinkApi(defaultConfig);
  } else {
    return new ShortlinkApi(configurationFactory());
  }
};

export const bookingApiFactory = (defaultConfig?: Configuration): BookingApi => {
  if (defaultConfig) {
    return new BookingApi(defaultConfig);
  } else {
    return new BookingApi(configurationFactory());
  }
};

export const s3ApiFactory = (defaultConfig?: Configuration): S3Api => {
  if (defaultConfig) {
    return new S3Api(defaultConfig);
  } else {
    return new S3Api(configurationFactory());
  }
};

export const scraperApiFactory = (defaultConfig?: Configuration): ScraperApi => {
  if (defaultConfig) {
    return new ScraperApi(defaultConfig);
  } else {
    return new ScraperApi(configurationFactory());
  }
};

export const emailApiFactory = (defaultConfig?: Configuration): EmailApi => {
  if (defaultConfig) {
    return new EmailApi(defaultConfig);
  } else {
    return new EmailApi(configurationFactory());
  }
};

export const healthcheckApiFactory = (defaultConfig?: Configuration): HealthcheckApi => {
  if (defaultConfig) {
    return new HealthcheckApi(defaultConfig);
  } else {
    return new HealthcheckApi(configurationFactory());
  }
};
