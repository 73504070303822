import * as constants from "store/constants";
import { BasketItem } from "store/reducers/basket";

export type BasketAction = AddBasketType | RemoveBasketType | CleanBasketType;

export interface AddBasketType {
  type: constants.BASKET_ADD;
  payload: BasketItem;
}

export function addBasket(basketItem: BasketItem): AddBasketType {
  return {
    type: constants.BASKET_ADD,
    payload: basketItem,
  };
}

export interface RemoveBasketType {
  type: constants.BASKET_REMOVE;
  payload: BasketItem;
}

export function removeBasket(basketItem: BasketItem): RemoveBasketType {
  return {
    type: constants.BASKET_REMOVE,
    payload: basketItem,
  };
}

export interface CleanBasketType {
  type: constants.BASKET_CLEAN;
  payload: boolean;
}

export function cleanBasket(payload: boolean): CleanBasketType {
  return {
    type: constants.BASKET_CLEAN,
    payload: payload,
  };
}
