/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Row, Col, Typography, Spin, Form, Input } from "antd";
/****************** TYPES ******************/
import { Store, Notification } from "store/reducers";
import { BbbError, passwordApiFactory } from "config";
import { addNotification, addThread, removeThread } from "store/actions";
/****************** STYLING ******************/
import { BbbButton, BBBLayout } from "components/shared";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/

type InputProps = {};
type RouteParams = { token: string };
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps<RouteParams>;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type State = {
  loading: boolean;
  token: string;
  success?: boolean;
  newPassword: string;
  newPasswordSecondInput?: string;
  valid: boolean;
};

class ResetPassword extends React.Component<Props, State> {
  state: State = {
    loading: true,
    token: "",
    success: undefined,
    newPassword: "",
    newPasswordSecondInput: undefined,
    valid: false,
  };

  /* LifeCycle Methods */
  componentDidMount() {
    const { match } = this.props;
    const token = match.params.token;
    const passwordResetApi = passwordApiFactory();
    this.props.addThread("verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost");
    passwordResetApi
      .verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost(token)
      .then(() => {
        log.info(`Successfully fetch /verify-token-validity/${token}`);
        this.setState((state: State) => ({
          ...state,
          success: true,
          token: token,
        }));
      })
      .catch((error) => {
        log.error(`Error fetching /verify-token-validity/${token}`, error);
        const formatedError = error.response.data as BbbError;
        const descriptionError = formatedError.code
          ? this.getTraduction(`error.${formatedError.code}`)
          : formatedError.detail;
        this.props.addNotification({
          type: "error",
          description: descriptionError,
          title: "Erreur",
        });
        this.setState((state: State) => ({
          ...state,
          success: false,
        }));
      })
      .finally(() => {
        this.props.removeThread("verifyTokenValidityApiV1PasswordResetVerifyTokenValidityPost");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `reset-password.${id}` }, param);
  };

  handleChange = (field: string, event: any) => {
    log.info("handleChange", { field, event });
    const value = event.target.value;
    this.setState((state: State) => {
      const newState = { ...state, [field]: value };
      return {
        ...newState,
        valid:
          !_.isEmpty(newState.newPassword) &&
          !_.isEmpty(newState.newPasswordSecondInput) &&
          newState.newPassword === newState.newPasswordSecondInput,
      };
    });
  };

  submitNewPassword = (token: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    log.info("Start setNewPassword", { token, event });
    event.preventDefault();
    this.setState((state: State) => ({ ...state, loading: true }));
    const passwordApiFactory1 = passwordApiFactory();
    this.props.addThread("setNewPasswordApiV1PasswordResetSetNewPost");
    passwordApiFactory1
      .setNewPasswordApiV1PasswordResetSetNewPost(token, this.state.newPassword)
      .then((response) => {
        log.info(`Successfully changed password for user ${response.data}`);
        this.props.addNotification({
          type: "success",
          description: "Mot de passe modifié avec succès. Vous pouvez vous connecter.",
          title: "Succès",
        });
        this.props.history.push(BBBRoutes.LOGIN.path);
      })
      .catch((error) => {
        log.error(`Error changing password`, error);
        this.props.addNotification({
          type: "error",
          description: "Erreur lors du changement de mot de passe",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("setNewPasswordApiV1PasswordResetSetNewPost");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  };

  /* Render methods */
  spinnerOrMessage() {
    if (this.state.loading) {
      return <Spin size="large" delay={300} className="progress" spinning={true}></Spin>;
    } else if (this.state.success) {
      return (
        <React.Fragment>
          <Form layout="vertical" labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} name="basic">
            <div className="page">
              <Form.Item
                label={this.getTraduction("password")}
                rules={[
                  {
                    required: true,
                    min: 8,
                    max: 64,
                    message: this.getTraduction("error.PASSWORD_LENGTH"),
                  }, // 8 et 64 caractères
                  {
                    pattern: /(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])/,
                    message: this.getTraduction("error.PASSWORD_SYMBOL_MISSING"),
                  }, // 1 symbol
                  {
                    pattern: /(?=.*[A-Z])/,
                    message: this.getTraduction("error.PASSWORD_CAPITAL_MISSING"),
                  }, // 1 maj
                ]}
              >
                <Input.Password
                  disabled={this.state.loading}
                  value={this.state.newPassword}
                  onChange={(e) => this.handleChange("newPassword", e)}
                />
              </Form.Item>
              <Form.Item label={this.getTraduction("password-second-input")} required={true}>
                <Input.Password
                  disabled={this.state.loading}
                  value={this.state.newPasswordSecondInput}
                  onChange={(e) => this.handleChange("newPasswordSecondInput", e)}
                />
              </Form.Item>
              <Row justify="space-around">
                <Col>
                  <BbbButton
                    disabled={!this.state.valid}
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                    onClick={(e) => this.submitNewPassword(this.state.token, e)}
                  >
                    <FormattedMessage id="reset-password.send-form" />
                  </BbbButton>
                </Col>
              </Row>
            </div>
          </Form>
        </React.Fragment>
      );
    } else {
      return (
        <Row justify="center">
          <Col>
            <Typography.Text>
              <FormattedMessage id="reset-password.error.title" />
            </Typography.Text>
          </Col>
        </Row>
      );
    }
  }
  render() {
    return (
      <BBBLayout mode="CENTER" titleSeo="reset-password.title" loading={this.state.loading}>
        {this.spinnerOrMessage()}
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
