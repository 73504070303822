/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ****************
/****************** STYLING ******************/

/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { BirthRegistry, Wish, WishPublic } from "bbb-api/dist/models";
import { BbbButton } from "components/shared";
import { addBasket, addNotification, addThread, removeBasket, removeThread } from "store/actions";
import { BasketItem, SubBasketList } from "store/reducers/basket";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry: BirthRegistry;
  wishPublic?: WishPublic;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {
  subList: SubBasketList[];
};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  addBasket: (basketItem: BasketItem) => void;
  removeBasket: (basketItem: BasketItem) => void;
};
type State = {
  goodSubList?: Wish[];
};

class BookWish extends React.Component<Props, State> {
  state: State = { goodSubList: [] };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.subList) {
      this.refreshSubList();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.subList !== this.props.subList) {
      this.refreshSubList();
    }
  }

  refreshSubList = () => {
    this.setState((state: State) => {
      const goodSubList = _.find(
        this.props.subList,
        (subList) => subList.birthRegistryId === this.props.birthRegistry.id
      );
      return {
        ...state,
        goodSubList: goodSubList ? goodSubList.wishes : [],
      };
    });
  };
  /* Handlers methods */
  book = (wish: WishPublic): void => {
    log.info("book", wish);
    this.props.addBasket({
      birthRegistryId: this.props.birthRegistry.id,
      birthRegistryTitle: this.props.birthRegistry.title,
      wish,
    });
  };

  unbook = (wish: WishPublic): void => {
    log.info("unbook", wish);
    this.props.removeBasket({ birthRegistryId: this.props.birthRegistry.id, wish });
  };

  /* Render methods */
  renderBookme(wish: WishPublic) {
    return (
      <BbbButton type="primary" size="large" onClick={() => this.book(wish)}>
        <FormattedMessage id="bookWish.book"></FormattedMessage>
      </BbbButton>
    );
  }

  renderAlreadyBooked(wish: WishPublic) {
    return (
      <BbbButton type="primary" danger={true} size="large" onClick={() => this.unbook(wish)}>
        <FormattedMessage id="bookWish.unbook"></FormattedMessage>
      </BbbButton>
    );
  }

  render() {
    const { wishPublic } = this.props;
    const { goodSubList } = this.state;
    if (wishPublic && _.findIndex(goodSubList, { id: wishPublic.id }) === -1) {
      return this.renderBookme(wishPublic);
    } else if (wishPublic) {
      return this.renderAlreadyBooked(wishPublic);
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    subList: state.basket.subList,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    addBasket: (basketItem: BasketItem) => dispatch(addBasket(basketItem)),
    removeBasket: (basketItem: BasketItem) => dispatch(removeBasket(basketItem)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BookWish);
