/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Checkbox, Col, Input, InputNumber, Row, Select, Slider, Typography } from "antd";
import { Can } from "components/shared";
import { SearchOutlined } from "@ant-design/icons";
/****************** STYLING ******************/
import "./BirthRegistryDetailSearchBar.less";
/****************** DEFINITIONS ******************/
import { Category, BirthRegistry } from "bbb-api/dist/models";
import { Store } from "store/reducers";
import { Store as FormStore, FieldData } from "rc-field-form/lib/interface";
import Form, { FormInstance } from "antd/lib/form";
import { setSearchFields } from "store/actions";
import {
  DEFAULT_FILTER_PRICE_MAX,
  DEFAULT_FILTER_PRICE_MIN,
  DEFAULT_PRICE_STEP,
  SearchFields,
} from "store/reducers/categories";
import { subject } from "@casl/ability";

/****************** RENDERING (export) ******************/
type InputProps = { birthRegistry: BirthRegistry };

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {
  categories: Category[];
  filters?: SearchFields;
};
type MapDispatchToProps = {
  setFilter: (filter: SearchFields) => void;
};

type State = {
  formValues?: SearchFields;
  formIsValid: boolean;
  formRef: React.RefObject<FormInstance>;
};

class BirthRegistryDetailSearchBar extends React.Component<Props, State> {
  state: State = {
    formValues: undefined,
    formIsValid: true,
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */
  componentDidMount() {
    this.state.formRef.current?.setFieldsValue({ ...this.props.filters });
    this.setState({ formValues: this.props.filters });
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `birthRegistryDetailSearchBar.${id}` }, param);
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => {
      const newValues = { ...state.formValues, ...values };
      this.props.setFilter(newValues);
      return {
        ...state,
        formValues: newValues,
      };
    });
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange", changedFields);
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  /* Render methods */
  renderSelect() {
    return (
      <Select placeholder={this.getTraduction("label.category")}>
        <Select.Option key={0} value={0}>
          {this.getTraduction("options.category.ALL")}
        </Select.Option>
        {this.props.categories.map((category) => {
          return (
            <Select.Option key={category.id} value={category.id}>
              {category.label}
            </Select.Option>
          );
        })}
      </Select>
    );
  }

  render() {
    return (
      <Card className="searchBar">
        <Form
          layout="inline"
          onValuesChange={this.valuesChange}
          onFieldsChange={this.onFieldsChange}
          ref={this.state.formRef}
        >
          <Row align="middle" gutter={[20, 20]} justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
              <Form.Item name="fullText">
                <Input
                  placeholder={this.getTraduction("label.fullText")}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={9} xl={10} xxl={6}>
              <Row align="middle">
                <Col>
                  <Typography.Text>{this.getTraduction("label.priceRange")}</Typography.Text>
                </Col>
                <Col offset={1} flex="auto">
                  <Row align="middle" justify="space-around">
                    <Col>
                      <InputNumber
                        step={DEFAULT_PRICE_STEP}
                        min={DEFAULT_FILTER_PRICE_MIN}
                        max={
                          this.state.formValues?.priceMax ||
                          DEFAULT_FILTER_PRICE_MAX - DEFAULT_PRICE_STEP
                        }
                        value={this.state.formValues?.priceMin}
                        onChange={(newVal) =>
                          this.setState((state: State) => {
                            const newValues = { ...state.formValues, priceMin: newVal as number };
                            this.props.setFilter(newValues);
                            return {
                              ...state,
                              formValues: newValues,
                            };
                          })
                        }
                      />
                    </Col>
                    <Slider
                      range={true}
                      style={{ position: "absolute", width: "90%", top: "27px" }}
                      tooltipPlacement="bottom"
                      tipFormatter={(value) => {
                        return value !== DEFAULT_FILTER_PRICE_MAX
                          ? `${value}€`
                          : `+ de ${DEFAULT_FILTER_PRICE_MAX}€`;
                      }}
                      step={DEFAULT_PRICE_STEP}
                      min={DEFAULT_FILTER_PRICE_MIN}
                      max={DEFAULT_FILTER_PRICE_MAX}
                      value={[
                        this.state.formValues?.priceMin || DEFAULT_FILTER_PRICE_MIN,
                        this.state.formValues?.priceMax || DEFAULT_FILTER_PRICE_MAX,
                      ]}
                      onChange={(values) =>
                        this.setState((state: State) => {
                          const newValues = {
                            ...state.formValues,
                            priceMin: values[0],
                            priceMax: values[1],
                          };
                          this.props.setFilter(newValues);
                          return {
                            ...state,
                            formValues: newValues,
                          };
                        })
                      }
                    />
                    <Col>
                      <Typography.Text>{this.getTraduction("label.priceRangeAnd")}</Typography.Text>
                    </Col>
                    <Col>
                      <InputNumber
                        step={DEFAULT_PRICE_STEP}
                        min={
                          this.state.formValues?.priceMin ||
                          DEFAULT_FILTER_PRICE_MIN + DEFAULT_PRICE_STEP
                        }
                        max={DEFAULT_FILTER_PRICE_MAX}
                        value={this.state.formValues?.priceMax}
                        onChange={(newVal) =>
                          this.setState((state: State) => {
                            const newValues = { ...state.formValues, priceMax: newVal as number };
                            this.props.setFilter(newValues);
                            return {
                              ...state,
                              formValues: newValues,
                            };
                          })
                        }
                        formatter={(value) => {
                          // Needed because value can be number or string
                          // eslint-disable-next-line eqeqeq
                          return value != DEFAULT_FILTER_PRICE_MAX
                            ? `${value}`
                            : `+ de ${DEFAULT_FILTER_PRICE_MAX}`;
                        }}
                        parser={(value) => value!.replaceAll("+ de", "")}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Can I="manage" this={subject("BirthRegistry", this.props.birthRegistry)}>
              <Col xs={24} sm={14} md={10} lg={7} xl={6} xxl={5}>
                <Form.Item
                  className="check"
                  name="onlyBooked"
                  label={this.getTraduction("label.booked")}
                  labelCol={{ xs: 20 }}
                  colon={false}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Can>
            <Can not I="manage" this={subject("BirthRegistry", this.props.birthRegistry)}>
              <Col xs={24} sm={14} md={10} lg={7} xl={6} xxl={5}>
                <Form.Item
                  name="onlyAvailable"
                  className="check"
                  label={this.getTraduction("label.available")}
                  labelCol={{ xs: 20 }}
                  colon={false}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Can>
            <Col xs={24} sm={10} md={24} lg={8} xl={8} xxl={5}>
              <Form.Item name="category">{this.renderSelect()}</Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    categories: state.categories.list,
    filters: state.categories.filters,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    setFilter: (filter: SearchFields) => dispatch(setSearchFields(filter)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryDetailSearchBar);
