/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage, FormattedDate } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Popconfirm, Typography, Row, Col, Image } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { BbbButton } from "components/shared";
import logo from "../../../../assets/logo.svg";
/****************** STYLING ******************/
import "./BirthRegistryCard.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";
import { prepareUrl } from "config/constants";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry: BirthRegistry;
  deleteElement(birthRegistry: BirthRegistry): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class BirthRegistryCard extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */
  deleteBirthRegistry = (): void => {
    log.info(`deleteBirthRegistry`, this.props.birthRegistry);
    this.props.deleteElement(this.props.birthRegistry);
  };

  /* Render methods */
  render() {
    const { birthRegistry } = this.props;
    return (
      <Card className="birthRegistryListItem">
        <div
          className="flex-content"
          onClick={() => {
            this.props.history.push(
              BBBRoutes.BIRTHREGISTRY_DETAIL_BY_ID.computePath({ id: birthRegistry.id })
            );
          }}
        >
          <div className="flex-head">{birthRegistry.title}</div>
          <div className="flex-body">
            <Row align="top">
              <Col xs={5} md={8} lg={10} xl={6} xxl={5}>
                <Image
                  src={
                    this.props.birthRegistry.thumbnail_path
                      ? prepareUrl(this.props.birthRegistry.thumbnail_path)
                      : logo
                  }
                  className="logo"
                  preview={false}
                  alt="logo"
                />
              </Col>
              <Col offset={1} xs={18} md={14} lg={10} xl={15} xxl={16}>
                <div className="description">
                  {birthRegistry.description ? (
                    <Typography.Paragraph ellipsis={{ rows: 3 }}>
                      <Typography.Text strong>
                        <FormattedMessage id="birthRegistryCard.description" />
                        <span> : </span>
                      </Typography.Text>
                      {birthRegistry.description}
                    </Typography.Paragraph>
                  ) : (
                    <Typography.Paragraph>
                      <Typography.Text strong>
                        <FormattedMessage id="birthRegistryDetailHeader.date" />
                        <span> : </span>
                      </Typography.Text>
                      {birthRegistry.event_date}
                    </Typography.Paragraph>
                  )}
                  <Typography.Paragraph>
                    <Typography.Text className="italic">
                      <Typography.Text strong>
                        <FormattedMessage id="birthRegistryCard.creation_date" />
                        <span>&nbsp;</span>
                      </Typography.Text>
                      <FormattedDate value={birthRegistry.creation_date}></FormattedDate>
                    </Typography.Text>
                  </Typography.Paragraph>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="actions">
          <BbbButton type="default" className="ml10">
            <Link to={BBBRoutes.BIRTHREGISTRY_EDIT.computePath(birthRegistry.id)}>
              <FormattedMessage id="birthRegistryCard.edit" />
            </Link>
          </BbbButton>
          <Popconfirm
            icon={<DeleteOutlined className="red" />}
            title={<FormattedMessage id="birthRegistryCard.delete.confirm" />}
            okText={<FormattedMessage id="birthRegistryCard.delete.ok" />}
            okType="danger"
            cancelText={<FormattedMessage id="birthRegistryCard.delete.cancel" />}
            onConfirm={this.deleteBirthRegistry}
          >
            <BbbButton type="dashed" danger className="ml10">
              <FormattedMessage id="birthRegistryCard.delete.action" />
            </BbbButton>
          </Popconfirm>
        </div>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryCard);
