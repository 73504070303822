/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Menu } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
import "./Me.less";
/****************** DEFINITIONS ******************/
import { Store, Theme } from "store/reducers";
import { authenticate, addThread, removeThread, setCurrentUser } from "store/actions";
import { User } from "bbb-api/dist/models";
import { userApiFactory } from "config/bbbApiFactory";
import SubMenu from "antd/lib/menu/SubMenu";
import { BBBRoutes } from "routes";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = { authenticated: boolean; theme: Theme; currentUser?: User };
type MapDispatchToProps = {
  authenticate: (auth: boolean) => void;
  setCurrentUser: (user: User) => void;
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
};
type State = {};

class Me extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  componentDidUpdate(prevProps: Props) {
    if (!prevProps.authenticated && this.props.authenticated) {
      log.info("User just authenticate, fetch /me");
      const userApi = userApiFactory();
      this.props.addThread("readUserMeApiV1UserMeGet");
      userApi
        .readUserMeApiV1UserMeGet()
        .then((response) => {
          const user = response.data;
          log.info(`Successfully fetch /me `, user);
          this.props.setCurrentUser(user);
        })
        .catch((error) => {
          log.error(`Error fetching /me`, error);
          if (!error.response || error.response.status === 403) {
            this.logout();
          }
        })
        .finally(() => {
          this.props.removeThread("readUserMeApiV1UserMeGet");
        });
    }
  }
  /* Handlers methods */
  logout = () => {
    log.info("Logout");
    this.props.authenticate(false);
    localStorage.removeItem("bbb");
  };

  /* Render methods */
  render() {
    if (!this.props.authenticated) {
      return (
        <BbbButton type="primary" size="large">
          <Link to={BBBRoutes.LOGIN.path}>
            <FormattedMessage id="header.login" />
          </Link>
        </BbbButton>
      );
    } else {
      return (
        <Menu className="me" theme={this.props.theme} mode="horizontal">
          <SubMenu title={this.props.currentUser?.first_name}>
            <Menu.Item key="logout">
              <Link to={BBBRoutes.HOME.path} onClick={this.logout}>
                <FormattedMessage id="header.logout" />
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    authenticated: state.user.authenticated,
    currentUser: state.user.currentUser,
    theme: state.config.theme,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    authenticate: (auth: boolean) => dispatch(authenticate(auth)),
    setCurrentUser: (user: User) => dispatch(setCurrentUser(user)),
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Me);
