/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BirthRegistryDetailHeaderQuote, BirthRegistryDetailHeaderMain } from "components/shared";
import { Card, Col, ColProps, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
/****************** STYLING ******************/
import "./BirthRegistryDetailHeader.less";

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";
import { BBBRoutes } from "routes";
import { AbilityContext } from "config";

/****************** RENDERING (export) ******************/
type InputProps = {
  loading: boolean;
  birthRegistry?: BirthRegistry;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = { manageThisList: boolean };

class BirthRegistryDetailHeader extends React.Component<Props, State> {
  state: State = { manageThisList: false };
  static contextType = AbilityContext;
  context!: React.ContextType<typeof AbilityContext>;

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */
  componentDidMount() {
    if (this.props.birthRegistry) {
      const manageThisList = this.context.can("manage", this.props.birthRegistry);
      log.trace(this.props.birthRegistry, { manageThisList });
      this.setState({ manageThisList });
    }
  }

  renderAddButton() {
    if (this.state.manageThisList) {
      return (
        <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={4}>
          <Card className="add">
            <div
              className="bigAddButton"
              onClick={() => {
                this.props.history.push(
                  BBBRoutes.WISH_NEW.computePath(this.props.birthRegistry?.id)
                );
              }}
            >
              <PlusOutlined style={{ fontSize: "100px" }} />
              <FormattedMessage id="birthRegistryDetailHeader.add" />
            </div>
          </Card>
        </Col>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
  render() {
    const quoteColProps: ColProps = this.state.manageThisList
      ? { xs: 24, sm: 24, md: 12, lg: 8, xl: 9, xxl: 10 }
      : { xs: 24, sm: 24, md: 12, lg: 14, xl: 14, xxl: 14 };
    return (
      <React.Fragment>
        <Row gutter={[16, 16]} align="stretch" justify="space-between">
          <Col xs={24} sm={24} md={12} lg={10} xl={10} xxl={10}>
            <BirthRegistryDetailHeaderMain
              loading={this.props.loading}
              birthRegistry={this.props.birthRegistry}
            ></BirthRegistryDetailHeaderMain>
          </Col>
          <Col {...quoteColProps}>
            <BirthRegistryDetailHeaderQuote
              loading={this.props.loading}
              birthRegistry={this.props.birthRegistry}
            ></BirthRegistryDetailHeaderQuote>
          </Col>
          {this.renderAddButton()}
        </Row>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryDetailHeader);
