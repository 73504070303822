/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Col, Row, Typography } from "antd";
/****************** TYPES ******************/
import { Store } from "store/reducers";
import { BirthRegistryAddress } from "bbb-api";
import logo from "../../../assets/logo.svg";
import { prepareUrl } from "config/constants";
/****************** STYLING ******************/

/****************** RENDERING (export) ******************/

type InputProps = {
  adress?: BirthRegistryAddress;
};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class ShipmentInfo extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */

  /* Render methods */

  render() {
    const { adress } = this.props;
    return (
      <Card className="main-desc" loading={!adress}>
        <Row gutter={[16, 16]} justify="space-around">
          <Typography.Text>{adress?.title}</Typography.Text>
        </Row>
        <Row gutter={[16, 16]} justify="space-around">
          <Col span={4}>
            <Typography.Paragraph>
              <FormattedMessage id="shipment.address"></FormattedMessage>
            </Typography.Paragraph>
          </Col>
          <Col span={8}>
            <Typography.Paragraph>
              <Typography.Text>{adress?.address_owner}</Typography.Text>
              <br />
              <Typography.Text>{adress?.address_first_line}</Typography.Text>
              <br />
              <Typography.Text>{adress?.address_zip_code}</Typography.Text>
              <br />
              <Typography.Text>{adress?.address_city}</Typography.Text>
              <br />
              <Typography.Text>{adress?.address_country}</Typography.Text>
            </Typography.Paragraph>
          </Col>
          <Col span={12}>
            <div className="cover">
              <img
                src={adress?.thumbnail_path ? prepareUrl(adress.thumbnail_path) : logo}
                className="logo"
                alt="logo"
              />
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ShipmentInfo);
