/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Switch } from "antd";
import DynamicAntdTheme from "dynamic-antd-theme";

/****************** STYLING ******************/
import "./ThemeSelector.less";

/****************** DEFINITIONS ******************/
import { Store, Theme } from "store/reducers";
import { changeTheme } from "store/actions";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = { theme: Theme };
type MapDispatchToProps = { changeTheme: (theme: Theme) => void };
type State = {};

class ThemeSelector extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `themeSelector.${id}` }, param);
  };

  changeTheme = (value: boolean) => {
    this.props.changeTheme(value ? "dark" : "light");
  };

  /* Render methods */
  render() {
    return (
      <div className="themeSelector">
        <DynamicAntdTheme primaryColor="#6EC1E4"></DynamicAntdTheme>
        <Switch
          checked={this.props.theme === "dark"}
          onChange={this.changeTheme}
          checkedChildren={this.getTraduction("dark")}
          unCheckedChildren={this.getTraduction("light")}
        />
      </div>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    theme: state.config.theme,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    changeTheme: (theme: Theme) => dispatch(changeTheme(theme)),
  };
}

export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ThemeSelector);
