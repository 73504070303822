/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl } from "react-intl";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Col, Divider, Input, Row, Select } from "antd";
import { BbbButton } from "components/shared";

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Category, CategoryCreate } from "bbb-api/dist/models";
import { addThread, removeThread, addNotification, addCategory } from "store/actions";
import { categoryApiFactory } from "config";
import { PlusOutlined } from "@ant-design/icons";

/****************** RENDERING (export) ******************/

type InputProps = {
  initialValue?: number;
  birthRegistryId: number;
  categories: Category[];
  selectCategory: (id: number) => void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  addCategory: (category: Category) => void;
};

type Option = { label: string; value: number };
type State = {
  loading: boolean;
  currentValue?: Option;
  freeValue: string;
  valueCanBeCreated: boolean;
  options: Option[];
};

class SelectCategories extends React.Component<Props, State> {
  state: State = {
    loading: false,
    currentValue: undefined,
    freeValue: "",
    valueCanBeCreated: false,
    options: [],
  };
  /* LifeCycle Methods */
  componentDidMount() {
    this.initFromCategories(true);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.categories !== prevProps.categories) {
      if (_.isEmpty(prevProps.categories)) {
        this.initFromCategories(true);
      } else {
        this.initFromCategories(false);
      }
    }
  }
  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `selectCategories.${id}` }, param);
  };

  initFromCategories(setValue: boolean) {
    log.trace("initFromCategories - setValue :", setValue);
    const options: Option[] = _.map(this.props.categories, (category) => {
      return { label: category.label || "", value: category.id };
    });
    let currentValueUpdated = this.state.currentValue;
    if (setValue) {
      currentValueUpdated = _.head(
        _.filter(options, (option) => option.value === this.props.initialValue)
      );
    }
    this.setState((state: State) => ({
      ...state,
      currentValue: currentValueUpdated,
      options: options,
    }));
  }

  onChange = (currentValue: Option): void => {
    log.info("SelectCategories onChange", currentValue);

    this.props.selectCategory(currentValue.value);
    this.setState((state: State) => ({
      ...state,
      currentValue: currentValue,
    }));
  };

  onCustomValueChange = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
    const freeValue = changeEvent.target.value;
    log.info("SelectCategories onCustomValueChange", freeValue);
    const valueInOptions = _.head(
      _.filter(this.state.options, (optionItem) => optionItem.label === freeValue)
    );

    this.setState((state: State) => ({
      ...state,
      freeValue: freeValue,
      valueCanBeCreated:
        !_.chain(freeValue).trim().isEmpty().value() && _.isUndefined(valueInOptions),
    }));
  };

  createCategory = (): void => {
    this.setState({ loading: true });
    const categoryApi = categoryApiFactory();
    const id = this.props.birthRegistryId;
    const categoryCreate: CategoryCreate = {
      label: this.state.freeValue,
      order: this.state.options.length,
      birthregistry_id: id,
    };
    this.props.addThread("createCategoryApiV1CategoryPost");
    categoryApi
      .createCategoryApiV1CategoryPost(categoryCreate)
      .then((response) => {
        const category: Category = response.data;
        log.info(`Successfully post /categories/ `, category);
        const currentNewOption: Option = { label: category.label || "", value: category.id };
        this.setState((state: State) => ({
          ...state,
          currentValue: currentNewOption,
          freeValue: "",
          options: [...state.options, currentNewOption],
        }));
        this.props.selectCategory(currentNewOption.value);
        this.props.addCategory(category);
      })
      .catch((error) => {
        log.error(`Error post /categories/ `, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de créer la nouvelle catégorie",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("createCategoryApiV1CategoryPost");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  };
  /* Render methods */
  dropdownRender(options: React.ReactElement) {
    return (
      <>
        {options}
        <Divider style={{ margin: "8px 0" }} />
        <Row justify="space-between" style={{ padding: "0 8px 4px" }}>
          <Col span={14}>
            <Input
              placeholder={this.getTraduction("placeholder")}
              value={this.state.freeValue}
              onChange={this.onCustomValueChange}
            />
          </Col>
          <Col>
            {this.state.valueCanBeCreated ? (
              <BbbButton
                loading={this.state.loading}
                type="dashed"
                icon={<PlusOutlined />}
                onClick={this.createCategory}
              >
                {this.getTraduction("button")}
              </BbbButton>
            ) : null}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <Row>
        <Col span={24}>
          <Select
            labelInValue
            value={this.state.currentValue}
            onChange={this.onChange}
            dropdownRender={(options) => this.dropdownRender(options)}
            options={this.state.options.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
          />
        </Col>
      </Row>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    addCategory: (category: Category) => dispatch(addCategory(category)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(SelectCategories);
