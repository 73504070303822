/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout } from "components/shared";
import BirthRegistryFormContainer from "./BirthRegistryFormContainer";

/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";
import { addNotification, addThread, removeThread } from "store/actions";
import { birthRegistryApiFactory } from "config";

/****************** RENDERING (export) ******************/
type InputProps = {};
type RouteParams = { id: string };
type Props = InputProps & MapStateToProps & MapDispatchToProps & RouteComponentProps<RouteParams>;
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = {
  loading: boolean;
  selectedBirthRegistry?: BirthRegistry;
  subpage: "NEW" | "ADDRESS" | "BABIES";
};

class EditBirthRegistry extends React.Component<Props, State> {
  state: State = {
    loading: true,
    subpage: "NEW",
  };

  /* LifeCycle Methods */
  componentDidMount() {
    const { match } = this.props;
    if (match.params.id && _.isInteger(Number(match.params.id))) {
      const birthRegistryId = Number(match.params.id);
      const birthRegistryApi = birthRegistryApiFactory();
      this.props.addThread("getBirthRegistryApiV1BirthregistryBirthRegistryIdGet");
      birthRegistryApi
        .getBirthRegistryApiV1BirthregistryBirthRegistryIdGet(birthRegistryId)
        .then((response) => {
          const birthRegistry: BirthRegistry = response.data;
          log.info(`Successfully loaded /birthRegistries/${birthRegistryId} `, birthRegistry);
          this.setState((state: State) => ({ ...state, selectedBirthRegistry: birthRegistry }));
        })
        .catch((error) => {
          log.error(`Error loading /birthRegistries/${birthRegistryId}`, error);
          this.props.addNotification({
            type: "error",
            description: "Impossible de charger la liste de naissance",
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("getBirthRegistryApiV1BirthregistryBirthRegistryIdGet");
          this.setState((state: State) => ({ ...state, loading: false }));
        });
    }
  }

  /* Handlers methods */
  changePage = (subpage: State["subpage"]) => {
    this.setState((state: State) => ({ subpage: subpage }));
  };

  /* Render methods */
  render() {
    return (
      <BBBLayout
        mode="FULL"
        onlyBottom={true}
        titleSeo={`editBirthRegistry.title.${this.state.subpage}`}
        loading={this.state.loading}
      >
        <BirthRegistryFormContainer
          selectedElement={this.state.selectedBirthRegistry}
          changePage={this.changePage}
        ></BirthRegistryFormContainer>
      </BBBLayout>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditBirthRegistry);
