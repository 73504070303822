/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import * as log from "loglevel";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { BBBLayout, BirthRegistriesList } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { birthRegistryApiFactory } from "config";
import { BirthRegistry } from "bbb-api/dist/models";
import {
  addThread,
  removeThread,
  addNotification,
  setAdminList,
  deleteAdminElement,
} from "store/actions";

/****************** RENDERING (export) ******************/
type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps;
type MapStateToProps = { adminBirthRegistries: BirthRegistry[] };
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
  setAdminList(birthRegistries: BirthRegistry[]): void;
  deleteAdminElement(birthRegistry: BirthRegistry): void;
};
type State = { loading: boolean };

class BirthRegistriesAdmin extends React.Component<Props, State> {
  state: State = { loading: true };

  /* LifeCycle Methods */
  componentDidMount() {
    const birthRegistryApi = birthRegistryApiFactory();
    this.props.addThread("getAllBirthRegistryApiV1BirthregistryGet");
    birthRegistryApi
      .getAllBirthRegistryApiV1BirthregistryGet()
      .then((response) => {
        const birthRegistries: BirthRegistry[] = response.data;
        log.info(`Successfully fetch /admin/birthRegistries `, birthRegistries);
        this.props.setAdminList(birthRegistries);
      })
      .catch((error) => {
        log.error(`Error fetching /admin/birthRegistries`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de récupérer les listes de naissance",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("getAllBirthRegistryApiV1BirthregistryGet");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  }
  /* Handlers methods */
  deleteElement = (birthRegistryDelete: BirthRegistry): void => {
    this.props.deleteAdminElement(birthRegistryDelete);
  };

  /* Render methods */
  render() {
    if (this.state.loading) {
      return (
        <BBBLayout
          mode="FULL"
          titleSeo="birthRegistriesAdmin.title"
          loading={this.state.loading}
        ></BBBLayout>
      );
    } else {
      return (
        <BBBLayout mode="FULL" titleSeo="birthRegistriesAdmin.title" loading={this.state.loading}>
          <BirthRegistriesList
            mode="ADMIN"
            birthRegistries={this.props.adminBirthRegistries}
            deleteElement={this.deleteElement}
          ></BirthRegistriesList>
        </BBBLayout>
      );
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    adminBirthRegistries: state.birthRegistries.adminList,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
    setAdminList: (birthRegistries: BirthRegistry[]) => dispatch(setAdminList(birthRegistries)),
    deleteAdminElement: (birthRegistry: BirthRegistry) =>
      dispatch(deleteAdminElement(birthRegistry)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BirthRegistriesAdmin);
