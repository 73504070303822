/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Carousel, Col, Row } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/

/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import FormCarouselPage from "./FormCarouselPage";

/****************** RENDERING (export) ******************/
type InputProps = {
  pages?: string[];
  changePage?(subpage: string): void;
  formIsValid?: boolean;
  children?: React.ReactNode[];
  validLabel?: string | JSX.Element;
  valid?: () => void;
  cancelLabel?: string | JSX.Element;
  cancel?: () => void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;

type MapStateToProps = {};
type MapDispatchToProps = {};

type State = { currentPage: number; loading: boolean };

class FormCarousel extends React.Component<Props, State> {
  state: State = { currentPage: 0, loading: false };
  carouselRef = React.createRef<any>();

  static defaultProps: Pick<Props, "formIsValid"> = {
    formIsValid: true,
  };
  /* LifeCycle Methods */

  /* Handlers methods */
  valid = (event?: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    const { valid, formIsValid } = this.props;
    if (valid && formIsValid) {
      this.setState({ loading: true }, () => {
        valid();
        this.setState({ loading: false });
      });
    }
    event?.preventDefault();
  };

  cancel = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (this.props.cancel) {
      this.props.cancel();
    }
    event.preventDefault();
  };

  next = (): void => {
    const nextPage = this.state.currentPage + 1;
    this.setState((state: State) => ({
      currentPage: nextPage,
    }));
    this.carouselRef.current.next();
  };

  previous = (): void => {
    const previousPage = this.state.currentPage - 1;
    this.setState((state: State) => ({
      currentPage: previousPage,
    }));
    this.carouselRef.current.prev();
  };

  onEnter = (): void => {
    if (this.isLastPage()) {
      this.valid();
    }
  };

  afterChange = (currentSlide: number): void => {
    this.setState((state: State) => ({
      currentPage: currentSlide,
      loading: false,
    }));
    if (this.props.pages && this.props.changePage) {
      this.props.changePage(this.props.pages[currentSlide]);
    }
  };

  beforeChange = (currentSlide: number, nextSlide: number): void => {
    this.setState((state: State) => ({
      loading: true,
    }));
  };

  isFirstPage = (): boolean => {
    return this.state.currentPage === 0;
  };

  isLastPage = (): boolean => {
    return _.size(this.props.children) === this.state.currentPage + 1;
  };

  /* Render methods */
  renderPrevious() {
    if (!this.isFirstPage()) {
      return (
        <Col xs={12} md={8}>
          <BbbButton
            loading={this.state.loading}
            type="ghost"
            size="large"
            onClick={() => this.previous()}
          >
            <FormattedMessage id="formCarouselPage.previous" />
          </BbbButton>
        </Col>
      );
    }
  }

  renderCancel() {
    const { cancelLabel, cancel } = this.props;
    if (cancel) {
      return (
        <Col xs={12} md={8}>
          <BbbButton type="text" size="large" onClick={(e) => this.cancel(e)}>
            {cancelLabel ?? <FormattedMessage id="formCarouselPage.cancel" />}
          </BbbButton>
        </Col>
      );
    }
  }

  renderNextOrSubmit() {
    const { validLabel, formIsValid } = this.props;

    if (this.isLastPage()) {
      return (
        <BbbButton
          loading={this.state.loading}
          type="primary"
          size="large"
          disabled={!formIsValid}
          onClick={(e) => this.valid(e)}
        >
          {validLabel ?? <FormattedMessage id="formCarouselPage.valid" />}
        </BbbButton>
      );
    } else {
      return (
        <BbbButton
          loading={this.state.loading}
          type="primary"
          size="large"
          onClick={() => this.next()}
        >
          <FormattedMessage id="formCarouselPage.next" />
        </BbbButton>
      );
    }
  }

  render() {
    return (
      <div className="bbbCarousel">
        <Carousel
          dotPosition="top"
          dots={true}
          ref={this.carouselRef}
          afterChange={this.afterChange}
          beforeChange={this.beforeChange}
          infinite={false}
        >
          {_.map(this.props.children, (child, index) => (
            <FormCarouselPage key={index} onEnter={this.onEnter}>
              {child}
            </FormCarouselPage>
          ))}
        </Carousel>
        <Row className="actions" justify="center" gutter={[16, 16]} wrap={true}>
          {this.renderCancel()}
          {this.renderPrevious()}
          <Col xs={12} md={8}>
            {this.renderNextOrSubmit()}
          </Col>
        </Row>
      </div>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(FormCarousel);
