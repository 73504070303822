/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";
import moment from "moment";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Form, Input, Modal } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Store as FormStore, ValidateErrorEntity, FieldData } from "rc-field-form/lib/interface";
import { BbbError, shortLinkApiFactory } from "config";
import { ShortLink, ShortLinkCreate, ShortLinkUpdate } from "bbb-api/dist/models";
import { addThread, removeThread, addNotification } from "store/actions";
import { FormInstance } from "antd/lib/form";

/****************** RENDERING (export) ******************/
type InputProps = {
  visible: boolean;
  birthRegistryId: number;
  currentShortLink?: ShortLink;
  onCloseEdit(newLink?: ShortLink): void;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type ShortLinkFields = {
  link: string;
};

type State = {
  formValues?: ShortLinkFields;
  formIsValid: boolean;
  mode: "CREATE" | "UPDATE";
  formRef: React.RefObject<FormInstance>;
  newShortLink?: ShortLink;
};

class ShortLinkEditModal extends React.Component<Props, State> {
  state: State = {
    formValues: undefined,
    formIsValid: false,
    mode: "CREATE",
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.currentShortLink) {
      this.fillForm();
    } else {
      this.state.formRef.current?.setFieldsValue({ ...this.state.formValues });
    }
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `shortLinkForm.${id}` }, param);
  };

  fillForm = () => {
    const { currentShortLink } = this.props;
    this.state.formRef.current?.setFieldsValue({
      link: currentShortLink?.name,
    });
    this.setState((state: State) => ({ ...state, mode: "UPDATE", formIsValid: true }));
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  onFinish = (values: FormStore) => {
    log.info("Submit shortLinkForm", values);
    if (this.props.currentShortLink !== undefined && this.state.formValues) {
      log.info(this.state.formValues);
      const formValues: ShortLinkFields = this.state.formValues;
      const restDataCreate: ShortLinkCreate = {
        name: formValues.link,
        is_active: true,
        expiration_date: moment().format("YYYY-MM-DD"), //TODO should not be mandatory from creation form
        birthregistry_id: this.props.birthRegistryId,
      };
      const shortlinkApi = shortLinkApiFactory();
      if (this.state.mode === "CREATE" && !this.props.currentShortLink?.id) {
        this.props.addThread("createShortlinkApiV1ShortlinkPost");
        shortlinkApi
          .createShortlinkApiV1ShortlinkPost(restDataCreate)
          .then((response) => {
            const shortlink: ShortLink = response.data;
            log.info(`Successfully post new /shortlink `, shortlink);
            this.setState((state: State) => ({ ...state, newShortLink: shortlink }));
            this.props.addNotification({
              type: "success",
              description: "Lien personnalisé créé",
              title: "OK",
            });
          })
          .catch((error) => {
            log.error(`Error posrt /shortlink`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de créer le lien",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("createShortlinkApiV1ShortlinkPost");
            this.props.onCloseEdit(this.state.newShortLink);
          });
      } else if (
        this.state.mode === "UPDATE" &&
        this.props.currentShortLink &&
        this.props.currentShortLink.id
      ) {
        this.props.addThread("updateShortlinkApiV1ShortlinkShortlinkIdPut");
        const restDataUpdate: ShortLinkUpdate = {
          id: this.props.currentShortLink.id,
          ...restDataCreate,
        };
        shortlinkApi
          .updateShortlinkApiV1ShortlinkShortlinkIdPut(
            this.props.currentShortLink.id,
            restDataUpdate
          )
          .then((response) => {
            const shortlink: ShortLink = response.data;
            log.info(`Successfully update existing /shortlink `, shortlink);
            this.setState((state: State) => ({ ...state, newShortLink: shortlink }));
            this.props.addNotification({
              type: "success",
              description: "Lien personnalisé mis à jour",
              title: "OK",
            });
          })
          .catch((error) => {
            log.error(`Error put /shortlink`, error);
            const formattedError = error.response.data as BbbError;
            const descriptionError = formattedError.code
              ? this.getTraduction(`error.${formattedError.code}`)
              : formattedError.detail;
            this.props.addNotification({
              type: "error",
              description: descriptionError,
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("updateShortlinkApiV1ShortlinkShortlinkIdPut");
            this.props.onCloseEdit(this.state.newShortLink);
          });
      }
    }
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as ShortLinkFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange");
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  cancel = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    this.setState((state: State) => ({ ...state, mode: "CREATE" }));
    this.state.formRef.current?.resetFields();
    this.props.onCloseEdit();
  };

  /* Render methods */
  render() {
    return (
      <Modal
        title={this.getTraduction(`title.${this.state.mode}`)}
        visible={this.props.visible}
        onCancel={this.cancel}
        width={800}
        destroyOnClose={true}
        footer={[
          <BbbButton key="cancel" type="dashed" onClick={this.cancel}>
            <FormattedMessage id="shortLinkForm.cancel" />
          </BbbButton>,
          <BbbButton
            className="bbb-btn-light-primary"
            key="primary"
            type="primary"
            onClick={this.submit}
            disabled={!this.state.formIsValid}
          >
            {this.state.mode === "CREATE" ? (
              <FormattedMessage id="shortLinkForm.add" />
            ) : (
              <FormattedMessage id="shortLinkForm.update" />
            )}
          </BbbButton>,
        ]}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          ref={this.state.formRef}
          onValuesChange={this.valuesChange}
          onFieldsChange={this.onFieldsChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          scrollToFirstError={true}
        >
          <Form.Item
            name="link"
            label={this.getTraduction("label.link")}
            rules={[
              { required: true, message: this.getTraduction("mandatory.link") },
              { max: 255, message: this.getTraduction("max.link") },
              {
                pattern: /^[a-zA-Z0-9_-]+$/,
                message: this.getTraduction("regexp.link"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ShortLinkEditModal);
