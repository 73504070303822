/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as _ from "lodash";
import * as log from "loglevel";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { notification } from "antd";
/****************** STYLING ******************/
import "./Notifier.less";

/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { removeNotification } from "store/actions";

/****************** RENDERING (export) ******************/

type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps;
type MapStateToProps = { notifications: Notification[] };
type MapDispatchToProps = { removeNotification: (key: string) => void };
type State = {};

class Notifier extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  componentDidUpdate(prevProps: Props) {
    log.trace(this.props);
    if (_.size(this.props.notifications) > _.size(prevProps.notifications)) {
      const lastNotif = _.last(this.props.notifications);
      if (lastNotif) {
        notification[lastNotif?.type]({
          message: lastNotif.title,
          description: lastNotif.description,
          onClose: this.onClose(lastNotif.key),
          duration: !_.isUndefined(lastNotif.duration) ? lastNotif.duration : 4.5,
        });
      }
    }
  }

  /* Handlers methods */
  onClose(key: string | undefined): (() => void) | undefined {
    return () => {
      log.info("Closing notification", {
        key: key,
        remain: _.filter(this.props.notifications, (notif) => notif.key !== key),
      });
      if (key) {
        this.props.removeNotification(key);
      }
    };
  }

  /* Render methods */
  render() {
    return null;
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    notifications: state.config.notifications,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    removeNotification: (key: string) => dispatch(removeNotification(key)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Notifier);
