/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";
import * as log from "loglevel";
import * as _ from "lodash";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Form, Modal, Input, Row, Col, Switch, Radio } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import { Store as FormStore, ValidateErrorEntity, FieldData } from "rc-field-form/lib/interface";
import { babyApiFactory } from "config";
import { Baby, BabyCreate, BabyUpdate, Gender } from "bbb-api/dist/models";
import { addThread, removeThread, addNotification } from "store/actions";
import { FormInstance } from "antd/lib/form";

/****************** RENDERING (export) ******************/
type InputProps = {
  visible: boolean;
  birthRegistryId: number;
  addElement(baby: Baby): void;
  updateElement(baby: Baby): void;
  cancel(): void;
  selectedElement?: Baby;
};
type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};

type BabyFields = {
  firstname?: string;
  firstname_is_private?: boolean;
  gender?: Gender;
  gender_is_private?: boolean;
};

type State = {
  formValues?: BabyFields;
  formIsValid: boolean;
  mode: "CREATE" | "UPDATE";
  formRef: React.RefObject<FormInstance>;
};

class BabyFormModal extends React.Component<Props, State> {
  state: State = {
    formValues: undefined,
    formIsValid: true,
    mode: "CREATE",
    formRef: React.createRef(),
  };

  /* LifeCycle Methods */
  componentDidMount() {
    if (this.props.selectedElement) {
      this.fillForm();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedElement &&
      (!prevProps.selectedElement ||
        (prevProps.selectedElement && prevProps.selectedElement !== this.props.selectedElement))
    ) {
      this.setState((state: State) => ({ ...state, mode: "UPDATE" }));
    } else if (
      this.state.mode === "UPDATE" &&
      this.state.formRef.current &&
      !this.state.formRef.current.isFieldsTouched()
    ) {
      this.fillForm();
    }
  }

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `babyForm.${id}` }, param);
  };

  fillForm = () => {
    const { selectedElement } = this.props;
    if (selectedElement) {
      this.state.formRef.current?.setFieldsValue({
        firstname: selectedElement.firstname,
        firstname_is_private: selectedElement.firstname_is_private,
        gender: selectedElement.gender,
        gender_is_private: selectedElement.gender_is_private,
      });
      this.setState((state: State) => ({ ...state, formIsValid: true }));
    }
  };

  submit = () => {
    this.state.formRef.current
      ?.validateFields()
      .then((values) => {
        this.onFinish(values);
      })
      .catch((error) => {
        this.onFinishFailed(error);
      });
  };

  onFinish = (values: FormStore) => {
    log.info("Submit BirthRegistry", values);
    if (this.state.formValues) {
      log.info(this.state.formValues);
      const formValues: BabyFields = this.state.formValues;
      const restDataCreate: BabyCreate = {
        birthregistry_id: this.props.birthRegistryId,
        firstname: formValues.firstname,
        firstname_is_private: formValues.firstname_is_private,
        gender: formValues.gender,
        gender_is_private: formValues.gender_is_private,
      };
      const babyApi = babyApiFactory();
      if (this.state.mode === "CREATE" && !this.props.selectedElement) {
        this.props.addThread("createBabyApiV1BabyPost");
        babyApi
          .createBabyApiV1BabyPost(restDataCreate)
          .then((response) => {
            const baby: Baby = response.data;
            log.info(`Successfully post /baby `, baby);
            this.props.addElement(baby);
          })
          .catch((error) => {
            log.error(`Error post /baby`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de créer le bébé",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("createBabyApiV1BabyPost");
          });
      } else if (this.state.mode === "UPDATE" && this.props.selectedElement) {
        this.props.addThread("updateBabyApiV1BabyBabyIdPut");
        const restDataUpdate: BabyUpdate = {
          id: this.props.selectedElement.id,
          ...restDataCreate,
        };
        babyApi
          .updateBabyApiV1BabyBabyIdPut(this.props.selectedElement.id, restDataUpdate)
          .then((response) => {
            const baby: Baby = response.data;
            log.info(`Successfully put /baby `, baby);
            this.props.updateElement(baby);
          })
          .catch((error) => {
            log.error(`Error put /baby`, error);
            this.props.addNotification({
              type: "error",
              description: "Impossible de modifier le bébé",
              title: "Erreur",
            });
          })
          .finally(() => {
            this.props.removeThread("updateBabyApiV1BabyBabyIdPut");
          });
      }
    }
  };

  onFinishFailed = (errorInfo: ValidateErrorEntity): void => {
    log.error("onFinishFailed", errorInfo);
    this.setState((state: State) => ({
      ...state,
      formIsValid: false,
    }));
  };

  valuesChange = (changedValues: FormStore, values: FormStore): void => {
    log.trace("valuesChange", values);
    this.setState((state: State) => ({
      ...state,
      formValues: values as BabyFields,
    }));
  };

  onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]): void => {
    log.trace("onFieldsChange");
    const fieldsErrors = this.state.formRef.current?.getFieldsError();
    const allErrors: string[] = _.flatMap(
      fieldsErrors,
      (fieldError) => fieldError.errors || fieldError
    );
    this.setState((state: State) => ({
      ...state,
      formIsValid: _.isEmpty(allErrors),
    }));
  };

  cancel = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    this.setState((state: State) => ({ ...state, mode: "CREATE" }));
    this.state.formRef.current?.resetFields();
    this.props.cancel();
  };

  /* Render methods */
  render() {
    return (
      <Modal
        title={this.getTraduction(`title.${this.state.mode}`)}
        visible={this.props.visible}
        onCancel={this.cancel}
        width={800}
        destroyOnClose={true}
        footer={[
          <BbbButton
            key="primary"
            type="primary"
            onClick={this.submit}
            disabled={!this.state.formIsValid}
          >
            {this.state.mode === "CREATE" ? (
              <FormattedMessage id="babyForm.add" />
            ) : (
              <FormattedMessage id="babyForm.update" />
            )}
          </BbbButton>,
          <BbbButton key="cancel" type="dashed" onClick={this.cancel}>
            <FormattedMessage id="babyForm.cancel" />
          </BbbButton>,
        ]}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          ref={this.state.formRef}
          onValuesChange={this.valuesChange}
          onFieldsChange={this.onFieldsChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          scrollToFirstError={true}
        >
          <Row>
            <Col span="12">
              <Form.Item
                name="firstname"
                label={this.getTraduction("label.firstname")}
                rules={[{ max: 255, message: this.getTraduction("max.firstname") }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 6 }}
                initialValue={false}
                name="firstname_is_private"
                label={this.getTraduction("label.firstname_is_private")}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={this.getTraduction("options.yes")}
                  unCheckedChildren={this.getTraduction("options.no")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="12">
              <Form.Item name="gender" label={this.getTraduction("label.gender")}>
                <Radio.Group>
                  <Radio value={Gender.M}>{this.getTraduction("options.gender.M")}</Radio>
                  <Radio value={Gender.F}>{this.getTraduction("options.gender.F")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 6 }}
                initialValue={false}
                name="gender_is_private"
                label={this.getTraduction("label.gender_is_private")}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={this.getTraduction("options.yes")}
                  unCheckedChildren={this.getTraduction("options.no")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(BabyFormModal);
