import { createStore, applyMiddleware, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as log from "loglevel";

import rootReducer from "./reducers";

const persistConfig = {
  key: "bbb",
  storage,
  whitelist: ["basket"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares: Middleware[] = [thunk];
if (process.env.NODE_ENV !== "production") {
  log.info(`Add reduxLogger for ${process.env.NODE_ENV} environment`);
  const reduxLogger = createLogger({ collapsed: true });
  middlewares = [...middlewares, reduxLogger];
}

export default function configureStore() {
  return createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(...middlewares)
      // other store enhancers if any
    )
  );
}
