"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Bienvenue Bébé
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("../common");
// @ts-ignore
var base_1 = require("../base");
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Activate a new account thanks to the token sent by email :param user_id: :param token: :param db: :param user_service: :return:
         * @summary Activate New User
         * @param {number} userId
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateNewUserApiV1UserUserIdActivateTokenGet: function (userId, token, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    common_1.assertParamExists('activateNewUserApiV1UserUserIdActivateTokenGet', 'userId', userId);
                    // verify required parameter 'token' is not null or undefined
                    common_1.assertParamExists('activateNewUserApiV1UserUserIdActivateTokenGet', 'token', token);
                    localVarPath = "/api/v1/user/{user_id}/activate/{token}"
                        .replace("{" + "user_id" + "}", encodeURIComponent(String(userId)))
                        .replace("{" + "token" + "}", encodeURIComponent(String(token)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UserPost: function (userCreate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userCreate' is not null or undefined
                            common_1.assertParamExists('createUserApiV1UserPost', 'userCreate', userCreate);
                            localVarPath = "/api/v1/user/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Create new user without the need to be logged in.
         * @summary Create User Open
         * @param {UserCreate} userCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserOpenApiV1UserOpenPost: function (userCreate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userCreate' is not null or undefined
                    common_1.assertParamExists('createUserOpenApiV1UserOpenPost', 'userCreate', userCreate);
                    localVarPath = "/api/v1/user/open";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get a specific user by id.
         * @summary Read User By Id
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdApiV1UserUserIdGet: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('readUserByIdApiV1UserUserIdGet', 'userId', userId);
                            localVarPath = "/api/v1/user/{user_id}"
                                .replace("{" + "user_id" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMeApiV1UserMeGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/v1/user/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersApiV1UserGet: function (skip, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/v1/user/";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            if (skip !== undefined) {
                                localVarQueryParameter['skip'] = skip;
                            }
                            if (limit !== undefined) {
                                localVarQueryParameter['limit'] = limit;
                            }
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update a user.
         * @summary Update User
         * @param {number} userId
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UserUserIdPut: function (userId, userUpdate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            common_1.assertParamExists('updateUserApiV1UserUserIdPut', 'userId', userId);
                            // verify required parameter 'userUpdate' is not null or undefined
                            common_1.assertParamExists('updateUserApiV1UserUserIdPut', 'userUpdate', userUpdate);
                            localVarPath = "/api/v1/user/{user_id}"
                                .replace("{" + "user_id" + "}", encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {BodyUpdateUserMeApiV1UserMePut} [bodyUpdateUserMeApiV1UserMePut]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UserMePut: function (bodyUpdateUserMeApiV1UserMePut, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/api/v1/user/me";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            return [4 /*yield*/, common_1.setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)];
                        case 1:
                            // authentication OAuth2PasswordBearer required
                            // oauth required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = common_1.serializeDataIfNeeded(bodyUpdateUserMeApiV1UserMePut, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: common_1.toPathString(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         * Activate a new account thanks to the token sent by email :param user_id: :param token: :param db: :param user_service: :return:
         * @summary Activate New User
         * @param {number} userId
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateNewUserApiV1UserUserIdActivateTokenGet: function (userId, token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.activateNewUserApiV1UserUserIdActivateTokenGet(userId, token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UserPost: function (userCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUserApiV1UserPost(userCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Create new user without the need to be logged in.
         * @summary Create User Open
         * @param {UserCreate} userCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserOpenApiV1UserOpenPost: function (userCreate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUserOpenApiV1UserOpenPost(userCreate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get a specific user by id.
         * @summary Read User By Id
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdApiV1UserUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.readUserByIdApiV1UserUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMeApiV1UserMeGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.readUserMeApiV1UserMeGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersApiV1UserGet: function (skip, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.readUsersApiV1UserGet(skip, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update a user.
         * @summary Update User
         * @param {number} userId
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UserUserIdPut: function (userId, userUpdate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserApiV1UserUserIdPut(userId, userUpdate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {BodyUpdateUserMeApiV1UserMePut} [bodyUpdateUserMeApiV1UserMePut]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UserMePut: function (bodyUpdateUserMeApiV1UserMePut, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUserMeApiV1UserMePut(bodyUpdateUserMeApiV1UserMePut, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         * Activate a new account thanks to the token sent by email :param user_id: :param token: :param db: :param user_service: :return:
         * @summary Activate New User
         * @param {number} userId
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateNewUserApiV1UserUserIdActivateTokenGet: function (userId, token, options) {
            return localVarFp.activateNewUserApiV1UserUserIdActivateTokenGet(userId, token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new user.
         * @summary Create User
         * @param {UserCreate} userCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApiV1UserPost: function (userCreate, options) {
            return localVarFp.createUserApiV1UserPost(userCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Create new user without the need to be logged in.
         * @summary Create User Open
         * @param {UserCreate} userCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserOpenApiV1UserOpenPost: function (userCreate, options) {
            return localVarFp.createUserOpenApiV1UserOpenPost(userCreate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get a specific user by id.
         * @summary Read User By Id
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdApiV1UserUserIdGet: function (userId, options) {
            return localVarFp.readUserByIdApiV1UserUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserMeApiV1UserMeGet: function (options) {
            return localVarFp.readUserMeApiV1UserMeGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersApiV1UserGet: function (skip, limit, options) {
            return localVarFp.readUsersApiV1UserGet(skip, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update a user.
         * @summary Update User
         * @param {number} userId
         * @param {UserUpdate} userUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserApiV1UserUserIdPut: function (userId, userUpdate, options) {
            return localVarFp.updateUserApiV1UserUserIdPut(userId, userUpdate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {BodyUpdateUserMeApiV1UserMePut} [bodyUpdateUserMeApiV1UserMePut]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserMeApiV1UserMePut: function (bodyUpdateUserMeApiV1UserMePut, options) {
            return localVarFp.updateUserMeApiV1UserMePut(bodyUpdateUserMeApiV1UserMePut, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Activate a new account thanks to the token sent by email :param user_id: :param token: :param db: :param user_service: :return:
     * @summary Activate New User
     * @param {number} userId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.activateNewUserApiV1UserUserIdActivateTokenGet = function (userId, token, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).activateNewUserApiV1UserUserIdActivateTokenGet(userId, token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new user.
     * @summary Create User
     * @param {UserCreate} userCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.createUserApiV1UserPost = function (userCreate, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).createUserApiV1UserPost(userCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Create new user without the need to be logged in.
     * @summary Create User Open
     * @param {UserCreate} userCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.createUserOpenApiV1UserOpenPost = function (userCreate, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).createUserOpenApiV1UserOpenPost(userCreate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get a specific user by id.
     * @summary Read User By Id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.readUserByIdApiV1UserUserIdGet = function (userId, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).readUserByIdApiV1UserUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get current user.
     * @summary Read User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.readUserMeApiV1UserMeGet = function (options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).readUserMeApiV1UserMeGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Retrieve users.
     * @summary Read Users
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.readUsersApiV1UserGet = function (skip, limit, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).readUsersApiV1UserGet(skip, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update a user.
     * @summary Update User
     * @param {number} userId
     * @param {UserUpdate} userUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateUserApiV1UserUserIdPut = function (userId, userUpdate, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).updateUserApiV1UserUserIdPut(userId, userUpdate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Update own user.
     * @summary Update User Me
     * @param {BodyUpdateUserMeApiV1UserMePut} [bodyUpdateUserMeApiV1UserMePut]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateUserMeApiV1UserMePut = function (bodyUpdateUserMeApiV1UserMePut, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).updateUserMeApiV1UserMePut(bodyUpdateUserMeApiV1UserMePut, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
