/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Col, Row, Typography } from "antd";
/****************** STYLING ******************/
import "./BirthRegistryCashCard.less";
/****************** DEFINITIONS ******************/
import { Store } from "store/reducers";
import { BirthRegistry } from "bbb-api/dist/models";

/****************** RENDERING (export) ******************/
type InputProps = {
  loading: boolean;
  birthRegistry?: BirthRegistry;
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  };
type MapStateToProps = {};
type MapDispatchToProps = {};
type State = {};

class BirthRegistryCashCard extends React.Component<Props, State> {
  state: State = {};

  /* LifeCycle Methods */
  /* Handlers methods */
  /* Render methods */
  renderAmount() {
    if (false) {
      return (
        <React.Fragment>
          <Row justify="center">
            <FormattedMessage id="birthRegistryCashCard.some"></FormattedMessage>
          </Row>
          <Row justify="center">
            <FormattedMessage id="birthRegistryCashCard.link"></FormattedMessage>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <Row justify="center">
          <FormattedMessage id="birthRegistryCashCard.empty"></FormattedMessage>
        </Row>
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card className="cashCard">
          <Row justify="center" align="middle">
            <Col span={5} className="left">
              <Row justify="center">
                <Typography.Title>
                  <FormattedMessage id="birthRegistryCashCard.title"></FormattedMessage>
                </Typography.Title>
              </Row>
            </Col>
            <Col span={19}>{this.renderAmount()}</Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BirthRegistryCashCard);
