/****************** DEPENDENCIES (import) ******************/
import React from "react";
import * as log from "loglevel";
import { detect } from "detect-browser";
import { connect } from "react-redux";
import { RawRuleOf } from "@casl/ability";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classNames from "classnames";
import * as _ from "lodash";

// intl deps
import { IntlProvider } from "react-intl";

/****************** DEPENDENCIES : COMPONENTS ******************/
import { Layout, Row, Spin } from "antd";
import { Header } from "components/header";
import { Footer } from "components/footer";
import Router from "Router";
/****************** TYPES ******************/
import { authenticate, changeMode } from "./store/actions";
import { Store, RunningMode, Theme } from "./store/reducers";
import { AbilityContext, AppAbility, createAbility, DEV_URL } from "./config";

import messages from "./i18n";

/****************** STYLING ******************/
import "./App.less";

type InputProps = {};
type Props = InputProps & MapStateToProps & MapDispatchToProps & RouteComponentProps;
type State = { loading: boolean };
type MapStateToProps = {
  authenticated: boolean;
  rules: RawRuleOf<AppAbility>[];
  mode: RunningMode;
  theme: Theme;
  lang: "en" | "fr" | "de";
};
type MapDispatchToProps = {
  authenticate: (auth: boolean) => void;
  changeMode: (value: RunningMode) => void;
};

export class AppComponent extends React.Component<Props, State> {
  state: State = { loading: true };
  public readonly ability = createAbility();

  /* LifeCycle Methods */
  componentDidMount() {
    const browser = detect();
    if (browser && browser.type === "browser" && window.location.host === DEV_URL) {
      if (this.props.mode !== "DEV") {
        this.props.changeMode("DEV");
      }
      log.setLevel("debug");
      log.debug("Application is working in mode :", this.props.mode);
    } else {
      log.setLevel("error");
    }
    const jwt = localStorage.getItem("bbb");
    if (!_.isNil(jwt)) {
      this.props.authenticate(true);
    }
    this.setState((state: State) => ({ ...state, loading: false }));
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.rules !== this.props.rules) {
      log.info("Update abilities", this.props.rules);
      this.ability.update(this.props.rules);
    }
  }

  spinnerOrSwitch() {
    if (this.state.loading) {
      return (
        <Row justify="center">
          <Spin size="large" delay={300} className="progress" spinning={true}></Spin>
        </Row>
      );
    } else {
      return Router(this.props.authenticated);
    }
  }
  render() {
    const themeClass: string = classNames({
      App: true,
      "app-dark ant-menu-dark": this.props.theme === "dark",
      "app-light ant-menu-light": this.props.theme === "light",
    });
    return (
      <IntlProvider
        defaultLocale={"fr"}
        locale="fr"
        key={this.props.lang}
        messages={messages[this.props.lang]}
        onError={(error) => {
          return process.env.NODE_ENV !== "test" ? log.error(error) : null;
        }}
      >
        <AbilityContext.Provider value={this.ability}>
          <Layout className={themeClass}>
            <Header></Header>
            <Layout.Content className="content">{this.spinnerOrSwitch()}</Layout.Content>
            <Layout.Footer className="footer">
              <Footer></Footer>
            </Layout.Footer>
          </Layout>
        </AbilityContext.Provider>
      </IntlProvider>
    );
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    authenticated: state.user.authenticated,
    rules: state.user.rules,
    mode: state.config.mode,
    lang: state.config.lang,
    theme: state.config.theme,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    authenticate: (auth: boolean) => dispatch(authenticate(auth)),
    changeMode: (value: RunningMode) => dispatch(changeMode(value)),
  };
}

export default compose<Props, InputProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AppComponent);
