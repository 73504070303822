/****************** DEPENDENCIES (import) ******************/
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, injectIntl, FormattedMessage, FormattedDate } from "react-intl";
import * as _ from "lodash";
/****************** DEPENDENCIES : COMPONENTS ******************/
import { Card, Typography, Row, Col, Space, Modal, Input, Popconfirm } from "antd";
import { BbbButton } from "components/shared";
/****************** STYLING ******************/
import "./BookedInfo.less";
import bear from "assets/bear.svg";
import { DeleteOutlined } from "@ant-design/icons";

/****************** DEFINITIONS ******************/
import { Store, Notification } from "store/reducers";
import {
  Booking,
  BookingAcknowledgment,
  BirthRegistry,
  BookingMinimal,
  WishPublic,
  WishWithBookings,
  ObjectDeleted,
} from "bbb-api";
import { bookingApiFactory } from "config/bbbApiFactory";
import { addNotification, addThread, removeThread } from "store/actions";
import * as log from "loglevel";
import { BbbError } from "config";

/****************** RENDERING (export) ******************/
type InputProps = {
  birthRegistry?: BirthRegistry;
  wish: WishPublic | WishWithBookings;
  bookings?: Booking[];
  bookingsMinimal?: BookingMinimal[];
};

type Props = InputProps &
  MapStateToProps &
  MapDispatchToProps & {
    intl: IntlShape;
  } & RouteComponentProps;
type MapStateToProps = { thanksFeature: boolean };
type MapDispatchToProps = {
  addThread: (key: string) => void;
  removeThread: (key: string) => void;
  addNotification: (notification: Notification) => void;
};
type State = {
  loading: boolean;
  thanksModalVisible: boolean;
  thanksModalBookingId?: number;
  thanksModalMessage?: string;
};

class BookedInfo extends React.Component<Props, State> {
  state: State = { loading: false, thanksModalVisible: false };

  /* Handlers methods */
  getTraduction = (id: string, param = {}): string => {
    return this.props.intl.formatMessage({ id: `bookedInfo.${id}` }, param);
  };

  closeThanksModal = () => {
    this.setState((state: State) => ({
      ...state,
      thanksModalVisible: false,
      thanksModalBookingId: undefined,
    }));
  };

  displayThanksModal = (bookingId: number) => {
    this.setState((state: State) => ({
      ...state,
      thanksModalVisible: true,
      thanksModalBookingId: bookingId,
    }));
  };

  confirmReceived = (bookingId: number) => {
    const bookingApi = bookingApiFactory();
    this.props.addThread("markAsReceivedApiV1BookingMarkAsReceivedBookingIdPost");
    bookingApi
      .markAsReceivedApiV1BookingMarkAsReceivedBookingIdPost(bookingId)
      .then((response) => {
        const booking = response.data;
        log.debug(`Successfully post received booking confirmation `, booking);

        this.props.addNotification({
          type: "success",
          description: "Réception confirmée",
          title: "Succès",
        });
        this.props.history.goBack();
      })
      .catch((error) => {
        log.error(`Error calling markAsReceivedApiV1BookingMarkAsReceivedBookingIdPost`, error);
        const formatedError = error.response.data as BbbError;
        const descriptionError = formatedError.code
          ? this.getTraduction(`error.${formatedError.code}`)
          : formatedError.detail;
        this.props.addNotification({
          type: "error",
          description: descriptionError,
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("markAsReceivedApiV1BookingMarkAsReceivedBookingIdPost");
      });
  };

  sendThanks = () => {
    const bookingApi = bookingApiFactory();
    const { thanksModalBookingId, thanksModalMessage } = this.state;
    if (thanksModalBookingId && thanksModalMessage) {
      const bookingAcknowledgment: BookingAcknowledgment = {
        acknowledgment_msg: thanksModalMessage,
      };
      this.props.addThread("thankVisitorApiV1BookingThankBookingIdPost");
      bookingApi
        .thankVisitorApiV1BookingThankBookingIdPost(thanksModalBookingId, bookingAcknowledgment)
        .then((response) => {
          const booking = response.data;
          log.debug(`Successfully post received thanks data`, booking);

          this.props.addNotification({
            type: "success",
            description: "Rermerciement envoyé",
            title: "Succès",
          });
          this.props.history.goBack();
        })
        .catch((error) => {
          log.error(`Error calling thankVisitorApiV1BookingThankBookingIdPost`, error);
          const formatedError = error.response.data as BbbError;
          const descriptionError = formatedError.code
            ? this.getTraduction(`error.${formatedError.code}`)
            : formatedError.detail;
          this.props.addNotification({
            type: "error",
            description: descriptionError,
            title: "Erreur",
          });
        })
        .finally(() => {
          this.props.removeThread("thankVisitorApiV1BookingThankBookingIdPost");
        });
    }
  };

  deleteElement = (bookingId: number): void => {
    this.setState({ loading: true });
    const bookingApi = bookingApiFactory();
    this.props.addThread("deleteBookingApiV1BookingBookingIdDelete");
    bookingApi
      .deleteBookingApiV1BookingBookingIdDelete(bookingId)
      .then((response) => {
        const bookingDeleted: ObjectDeleted = response.data;
        log.info(`Successfully delete /booking `, bookingDeleted);
        this.props.addNotification({
          type: "success",
          description: "Réservation supprimée",
          title: "Succès",
        });
        this.props.history.goBack();
      })
      .catch((error) => {
        log.error(`Error delete /booking`, error);
        this.props.addNotification({
          type: "error",
          description: "Impossible de supprimer la réservation",
          title: "Erreur",
        });
      })
      .finally(() => {
        this.props.removeThread("deleteBookingApiV1BookingBookingIdDelete");
        this.setState((state: State) => ({ ...state, loading: false }));
      });
  };

  handleChangeMessageUpdate = (event: any) => {
    log.info("handleChangeMessageUpdate", { event });
    const value = event.target.value;
    this.setState((state: State) => ({ ...state, thanksModalMessage: value }));
  };

  /* Render methods */
  renderBooking(booking: Booking) {
    const { birthRegistry, wish } = this.props;
    const { loading } = this.state;
    return (
      <Card
        className="bookingCard"
        key={booking.id}
        title={<FormattedMessage id="bookedInfo.title" />}
        loading={loading}
        extra={
          birthRegistry && (
            <Popconfirm
              icon={<DeleteOutlined className="red" />}
              title={<FormattedMessage id="bookedInfo.delete.confirm" />}
              okText={<FormattedMessage id="bookedInfo.delete.ok" />}
              okType="danger"
              cancelText={<FormattedMessage id="bookedInfo.delete.cancel" />}
              onConfirm={() => this.deleteElement(booking.id)}
            >
              <BbbButton type="dashed" danger className="ml10">
                <FormattedMessage id="bookedInfo.delete.action" />
              </BbbButton>
            </Popconfirm>
          )
        }
      >
        <Row>
          <Typography.Paragraph>
            <Typography.Text>
              <FormattedMessage id="bookedInfo.theGift" />{" "}
            </Typography.Text>
            <Typography.Text>{wish.title} </Typography.Text>
            <Typography.Text>
              <FormattedMessage id="bookedInfo.bookedBy" />{" "}
            </Typography.Text>
            <Typography.Text
              strong
            >{` ${booking.first_name} ${booking.last_name} `}</Typography.Text>
            <FormattedMessage id="bookedInfo.when"></FormattedMessage>{" "}
            <FormattedDate value={booking.booking_date} />
            {"."}
          </Typography.Paragraph>
        </Row>
        <Space direction="vertical" align="center" className="full-width">
          {birthRegistry && (
            <>
              <Row justify="center" className="image-row">
                <img src={bear} className="bear-image" alt="thanks" />
                {booking.message && <div className="bubble">{booking.message}</div>}
              </Row>
              {this.props.thanksFeature && (
                <React.Fragment>
                  {!booking.thanked ? (
                    <Row justify="center" align="middle">
                      <BbbButton type="primary" onClick={() => this.displayThanksModal(booking.id)}>
                        <FormattedMessage id="bookedInfo.thanked.action" />
                        <React.Fragment>{` ${booking.first_name} ${booking.last_name} `}</React.Fragment>
                      </BbbButton>
                      <Modal
                        title={<FormattedMessage id="bookedInfo.thanksMessage" />}
                        visible={this.state.thanksModalVisible}
                        onCancel={this.closeThanksModal}
                        width={800}
                        destroyOnClose={true}
                        footer={[
                          <BbbButton
                            key="primary"
                            type="primary"
                            disabled={!this.state.thanksModalMessage}
                            onClick={() => this.sendThanks()}
                          >
                            <FormattedMessage id="bookedInfo.thanked.action" />
                            <React.Fragment>{` ${booking.first_name} ${booking.last_name} `}</React.Fragment>
                          </BbbButton>,
                        ]}
                      >
                        <Input.TextArea
                          rows={5}
                          maxLength={500}
                          value={this.state.thanksModalMessage}
                          onChange={(event) => this.handleChangeMessageUpdate(event)}
                        />
                      </Modal>
                    </Row>
                  ) : (
                    <Row justify="center" align="middle">
                      <Typography.Paragraph>
                        <FormattedMessage id="bookedInfo.thanked.done" />
                        {" : "}
                        <FormattedDate value={booking.thanked_date}></FormattedDate>
                      </Typography.Paragraph>
                    </Row>
                  )}
                  {!booking.received ? (
                    <Row justify="center" align="middle">
                      <BbbButton
                        type="link"
                        className="signal"
                        onClick={() => this.confirmReceived(booking.id)}
                      >
                        <Typography.Text underline>
                          <FormattedMessage id="bookedInfo.received.action" />
                        </Typography.Text>
                      </BbbButton>
                    </Row>
                  ) : (
                    <Row justify="center" align="middle">
                      <Typography.Paragraph>
                        <FormattedMessage id="bookedInfo.received.done" />
                        {" : "}
                        <FormattedDate value={booking.received_date}></FormattedDate>
                      </Typography.Paragraph>
                    </Row>
                  )}
                </React.Fragment>
              )}
            </>
          )}
        </Space>
      </Card>
    );
  }

  renderBookingMinimal(bookingMinimal: BookingMinimal, index: number) {
    return (
      <Card className="bookingCard" key={index} title={<FormattedMessage id="bookedInfo.title" />}>
        <Row justify="center" align="middle">
          <Col>
            <Typography.Paragraph>
              {bookingMinimal.anonymous ? (
                <>
                  <FormattedMessage id="bookedInfo.anonymous"></FormattedMessage>{" "}
                </>
              ) : (
                <>
                  <FormattedMessage id="bookedInfo.bookedBy"></FormattedMessage>
                  <Typography.Text>{` ${bookingMinimal.first_name} ${bookingMinimal.last_name} `}</Typography.Text>
                </>
              )}
              <FormattedMessage id="bookedInfo.when"></FormattedMessage>{" "}
              <FormattedDate value={bookingMinimal.booking_date}></FormattedDate>
            </Typography.Paragraph>
          </Col>
        </Row>
      </Card>
    );
  }

  render() {
    const { bookings, bookingsMinimal } = this.props;
    if (_.size(bookings) > 0) {
      return <>{_.map(bookings, (booking) => this.renderBooking(booking))}</>;
    } else if (_.size(bookingsMinimal) > 0) {
      return (
        <>
          {_.map(bookingsMinimal, (bookingMinimal, index) =>
            this.renderBookingMinimal(bookingMinimal, index)
          )}
        </>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

export function mapStateToProps(state: Store): MapStateToProps {
  return {
    thanksFeature: state.config.features.thanksFeature,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    addThread: (key: string) => dispatch(addThread(key)),
    removeThread: (key: string) => dispatch(removeThread(key)),
    addNotification: (notif: Notification) => dispatch(addNotification(notif)),
  };
}
export default compose<Props, InputProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BookedInfo);
